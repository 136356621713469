export const setBrand = (brand: any) => {
  return {
    type: "SET_BRAND",
    brand,
  };
};

export const setCompetitor = (brand: any) => {
  return {
    type: "SET_COMPETITOR",
    brand,
  };
};

export const setSteps = (step: any) => {
  return {
    type: "SET_STEPS",
    step,
  };
};

export const setVersion = (apiVersion: string) => {
  return {
    type: "SET_API_VERSION",
    apiVersion,
  };
};

export const setUser = (user: any) => {
  return {
    type: "SET_USER",
    user,
  };
};

export const setUserInput = (input: string) => {
  return {
    type: "SET_USER_INPUT",
    input,
  };
};

export const setReviewBrands = (reviewBrands: any) => {
  return {
    type: "SET_REVIEW_BRANDS",
    reviewBrands,
  };
};

export const setIndustry = (industry: any) => {
  return {
    type: "SET_INDUSTRY",
    industry,
  };
};

export const setIndustryBrands = (industryBrands: any) => {
  return {
    type: "SET_INDUSTRY_BRANDS",
    industryBrands,
  };
};
