export class Location {
  name: string;

  fullLocation: string;

  id: string;

  constructor(sel: any) {
    this.name = sel.name;
    this.fullLocation = sel.fullLocation;
    this.id = sel.id;
  }

  hasInput(value: string) {
    return this.name.toLowerCase().includes(value.trim().toLowerCase());
  }

  startWithInput(value: string) {
    return this.name.toLowerCase().startsWith(value.trim().toLowerCase());
  }
}
