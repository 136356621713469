import React from "react";

interface ProgressBarProps {
  percentage: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = (props: ProgressBarProps) => {
  const { percentage } = props;
  let barStyleClass = "progressBar__bar";

  if (Number(percentage) < 25) {
    barStyleClass = "progressBar__bar progressBar--red";
  } else if (Number(percentage) > 65) {
    barStyleClass = "progressBar__bar progressBar__bar--green";
  }

  return (
    <div className="progressBar">
      <h2 className="progressBar__title">Web score</h2>
      <div className="progressBar__wrapper">
        <div className="progressBar__mask">
          <div className={barStyleClass} style={{ width: `${percentage}%` }} />
        </div>
        <span className="progressBar__value">{percentage}</span>
      </div>
    </div>
  );
};
