const config = {
  maxFields: {
    locations: 3,
    industries: 3,
    products: 10,
    services: 10,
    competitors: 8,
  },
};

export interface Product {
  name: string;
  parentId: string;
  subIndustryId: string;
  type: string;
  id: string;
}

export interface Suggested {
  id: string;
  name: string;
  count?: number;
  selected?: boolean;
}

export interface Service {
  name: string;
  parentId: string;
  subIndustryId: string;
  type: string;
  id: string;
}

export default config;
