import React from "react";

import { SearchCompetitor } from "../Search/SearchCompetitor";

export const SelectCompetitor: React.FC = () => {
  return (
    <div className="brand">
      <h2>Brand comparisons</h2>
      <p className="compareText">
        Search for a competitor to compare ESG and Digital Marketing analytics
      </p>
      <SearchCompetitor />
    </div>
  );
};
