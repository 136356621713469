import React from "react";
import DataTable from "react-data-table-component";
import { ReactComponent as SortIcon } from "../../assets/images/icons/filter-icon-tables.svg";

interface TableProps {
  columns: any;
  data: any;
  config: {
    pagination?: boolean;
    defaultSortField?: string;
    defaultSortAsc?: boolean;
    conditionalRowStyles?: any[];
    customStyles?: any;
    noDataComponent: string;
    disabled?: boolean;
    fixedHeader?: boolean;
    onSort?: any;
    selectableRows?: any;
    onSelectedRowsChange?: any;
  };
}

export const Table: React.FC<TableProps> = (props: TableProps) => {
  const { columns, data, config } = props;

  return (
    <div className="table">
      <div className="w-layout-grid grid">
        <DataTable
          sortIcon={<SortIcon />}
          noHeader
          highlightOnHover
          columns={columns}
          data={data}
          pagination={config.pagination}
          fixedHeader={config.fixedHeader}
          disabled={config.disabled}
          customStyles={config.customStyles}
          conditionalRowStyles={config.conditionalRowStyles}
          defaultSortField={config.defaultSortField}
          defaultSortAsc={config.defaultSortAsc}
          noDataComponent={config.noDataComponent}
          onSort={config.onSort}
          selectableRows={config.selectableRows}
          onSelectedRowsChange={config.onSelectedRowsChange}
        />
      </div>
    </div>
  );
};
