import React from "react";

interface CategoryDropdownProps {
  setActiveTab: any;
}

export const CategoryDropdown: React.FC<CategoryDropdownProps> = ({
  setActiveTab,
}: CategoryDropdownProps) => {
  return (
    <nav className="category-dropdown_list w-dropdown-list">
      <a
        href="#"
        onClick={() => setActiveTab("general")}
        className="category-dropdown_link w-dropdown-link"
      >
        General
      </a>
      <a
        href="#"
        onClick={() => setActiveTab("website")}
        className="category-dropdown_link w-dropdown-link"
      >
        Website
      </a>
      <a
        href="#"
        onClick={() => setActiveTab("organic")}
        className="category-dropdown_link w-dropdown-link"
      >
        Organic
      </a>
      <a
        href="#"
        onClick={() => setActiveTab("paid")}
        className="category-dropdown_link w-dropdown-link"
      >
        Paid
      </a>
      <a
        href="#"
        onClick={() => setActiveTab("social")}
        className="category-dropdown_link w-dropdown-link"
      >
        Social
      </a>
      <a
        href="#"
        onClick={() => setActiveTab("products")}
        className="category-dropdown_link w-dropdown-link"
      >
        Products/Services
      </a>
      <a
        href="#"
        onClick={() => setActiveTab("locations")}
        className="category-dropdown_link w-dropdown-link"
      >
        Locations
      </a>
    </nav>
  );
};
