import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Input, Button, message } from "antd";

import { authLogin } from "../../API/api";
import { setUser } from "../../actions/actions";
import { Footer } from "../../components/Footer/Footer";

import { ReactComponent as Logo } from "../../webflow/images/Small_Giants_logo_orange.svg";

const Login: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = (values: any) => {
    authLogin(values)
      .then((result: any) => {
        if (result.error) {
          return setErrorMessage(result.error);
        }
        return result;
      })
      .then((json: any) => {
        if (typeof json === "object") {
          setErrorMessage("");
          localStorage.setItem("user", JSON.stringify(json));
          dispatch(setUser(json));
          navigate("../search", { replace: true });
        }
      })
      .catch((error) => message.error(error, 2));
  };

  return (
    <div className="section welcome wf-section">
      <div className="search-box_container" style={{ height: "574px" }}>
        <Logo className="smallgiants-logo_small" />
        <h1 className="search-box_heading">BRAND INDEX</h1>
        <div className="form-block w-form" style={{ margin: "3em auto 0" }}>
          <Form name="login-form" className="form" onFinish={onFinish}>
            <div className="form_field-label" style={{ marginTop: "10px" }}>
              Username
            </div>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please enter your username",
                },
              ]}
              className="no-margin-bottom"
            >
              <Input
                className="form_field text-dim-italic w-input"
                style={{ borderLeftColor: "#ec8d31" }}
              />
            </Form.Item>
            <div className="form_field-label" style={{ marginTop: "10px" }}>
              Password
            </div>
            <Form.Item
              name="password"
              style={{ marginBottom: 20 }}
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
              ]}
            >
              <Input.Password
                visibilityToggle={false}
                className="form_field text-dim-italic w-input"
                style={{ borderLeftColor: "#ec8d31" }}
              />
            </Form.Item>
            {errorMessage && (
              <div className="form_error-text" style={{ marginBottom: "20px" }}>
                <span className="error-icon"> </span>
                {errorMessage}
              </div>
            )}
            <Button
              className="btn orange w-button"
              type="primary"
              htmlType="submit"
              style={{ margin: "0 auto" }}
            >
              Login
            </Button>
          </Form>
        </div>

        <a
          href="https://www.smallgiants.agency/"
          target="_blank"
          rel="noreferrer"
          className="link-sg-website"
        >
          www.smallgiants.agency
        </a>
      </div>
      <Footer />
    </div>
  );
};
export default Login;
