import React from "react";
import { Form, Input, Button } from "antd";

export const ContactUsForm: React.FC = () => {
  return (
    <Form id="email-form" name="email-form" className="form">
      <h2 className="form-heading">
        Use this form to send us questions or feedback about SmallGiants Brand Index.
      </h2>
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: "Please enter your full name",
          },
        ]}
        className="no-margin-bottom"
      >
        <Input className="form_field w-input" placeholder="Full name" />
      </Form.Item>

      <Form.Item
        name="company"
        className="no-margin-bottom"
        rules={[
          {
            required: true,
            message: "Please enter your company",
          },
        ]}
      >
        <Input className="form_field w-input" placeholder="Company" />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            message: "Please enter a valid email address",
          },
          {
            required: true,
            message: "Please enter your email address",
          },
        ]}
        className="no-margin-bottom"
      >
        <Input className="form_field w-input" placeholder="Email address" />
      </Form.Item>
      <Form.Item
        name="message"
        rules={[
          {
            required: true,
            message: "Please enter your message",
          },
        ]}
        className="no-margin-bottom"
      >
        <Input.TextArea className="form_field w-input" placeholder="Message" />
      </Form.Item>
      <Button className="btn orange top-mgn-20 w-button no-margin-top" htmlType="submit">
        Send message
      </Button>
    </Form>
  );
};
