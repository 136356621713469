import React, { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Form, Input } from "antd";

import { CompetitorsList } from "./CompetitorsList";
import HelpTipSection from "../../../HelpTipSection";

interface CompetitorsProps {
  data: any;
  setData: any;
  changeStep: any;
}

export const Competitors: React.FC<CompetitorsProps> = ({
  data,
  setData,
  changeStep,
}: CompetitorsProps) => {
  const [form] = Form.useForm();
  const user = useSelector((state: RootStateOrAny) => state.user);
  const isUserAdmin = user.roles.indexOf("ADMIN") > -1;

  const handleBack = () => {
    changeStep(2);
  };

  const onCheck = () => {
    const values = form.validateFields();

    values.then(() => changeStep(4)).catch(() => changeStep(3));
  };

  useEffect(() => {
    form.setFieldsValue({
      competitors: data.competitorIds,
    });
  }, []);

  return (
    <div className="add-brand_slide w-slide">
      <h3 className="form-slide-heading">Add competitors</h3>
      <div className="add-brand_tab" style={{ paddingBottom: 80 }}>
        <div style={{ minHeight: "440px" }}>
          <Form form={form}>
            <Form.List name="competitors">
              {(fields, { add, remove }, { errors }) => {
                if (!fields.length) {
                  add();
                }

                return (
                  <CompetitorsList
                    fields={fields}
                    errors={errors}
                    data={data}
                    setData={setData}
                    add={add}
                    remove={remove}
                  />
                );
              }}
            </Form.List>
            <Form.Item
              name="competitorsList"
              className="hiddenField"
              hidden
              rules={[
                {
                  message: (
                    <div className="form_error-text">
                      <span className="error-icon" /> Please select at least one competitor
                    </div>
                  ),
                },
              ]}
            >
              <Input value={data?.competitorIds?.join(",")} />
            </Form.Item>
          </Form>
        </div>
        <HelpTipSection
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in
          eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum
          nulla."
          title="Some help text for Competitors"
        />
      </div>
      <div className="steps-container">
        <div className="next-prev-container">
          {isUserAdmin && (
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => handleBack()}
              onClick={() => handleBack()}
              className="prev-btn w-slider-arrow-left btnEnabled"
            >
              <div>Previous</div>
            </div>
          )}
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => onCheck()}
            onClick={() => onCheck()}
            className="next-btn w-slider-arrow-right"
          >
            <div>Next</div>
          </div>
        </div>
      </div>
    </div>
  );
};
