import React from "react";
import { ReactComponent as Elephant } from "../../webflow/images/smallgiants_elephant.svg";

export const Footer: React.FC = () => {
  return (
    <>
      <Elephant className="welcome-bg-img" />
      <div className="btm-transformation-text">From visions to giants</div>
    </>
  );
};
