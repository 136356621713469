import React, { useEffect } from "react";
import { Form, Input } from "antd";

import HelpTipSection from "../../../HelpTipSection";

interface DetailsProps {
  data: any;
  setData: any;
  changeStep: any;
}

export const Analytics: React.FC<DetailsProps> = ({ data, setData, changeStep }: DetailsProps) => {
  const [form] = Form.useForm();

  const handleBack = () => {
    changeStep(0);
  };

  const getAnalyticsValue = (type: string, metric: string) => {
    const stats: any = data?.analytics[0][type].filter((s: any) => s.id === metric);

    if (stats?.length > 0) {
      return stats[0].value[0];
    }

    return "";
  };

  const onCheck = () => {
    const newGeneral: any = data.analytics[0].general;
    const newOrganic: any = data.analytics[0].organic;
    const newSocial: any = data.analytics[0].social;
    const values = form.validateFields();

    values
      .then(() => {
        const formValues = form.getFieldsValue(true);

        Object.entries(formValues).forEach((entry: any) => {
          if (entry[1] && entry[1] !== "") {
            if (entry[0] === "bounceRate") {
              const originalValue = getAnalyticsValue("general", "bounceRate");
              const newMetric: any = {
                id: "bounceRate",
                name: "Bounce rate",
                value: [parseFloat(entry[1])],
                unit: "",
              };

              if (originalValue) {
                const index = newGeneral.findIndex((stat: any) => stat.id === "bounceRate");
                newGeneral[index] = newMetric;
              } else {
                newGeneral.push(newMetric);
              }
            } else if (entry[0] === "topTrafficSources") {
              const originalValue = getAnalyticsValue("organic", "topTrafficSources");
              const newMetric: any = {
                id: "topTrafficSources",
                name: "Top traffic sources",
                value: [entry[1]],
                unit: "",
              };

              if (originalValue) {
                const index = newOrganic.findIndex((stat: any) => stat.id === "topTrafficSources");
                newOrganic[index] = newMetric;
              } else {
                newOrganic.push(newMetric);
              }
            } else {
              const newMetric: any = {
                id: entry[0].toLowerCase(),
                name: entry[0],
                value: [{ followers: parseInt(entry[1], 10) }],
              };

              newSocial.push(newMetric);
            }
          }
        });

        const metrics = [{ general: newGeneral, organic: newOrganic, social: newSocial }];
        setData({ ...data, ...{ analytics: metrics } });
        changeStep(2);
      })
      .catch(() => changeStep(1));
  };

  const getSocialMetricValue = (network: string, metric: string) => {
    let value: any = "";
    const socialNetwork: any = data?.analytics[0]?.social.filter((s: any) => s.id === network);

    if (socialNetwork?.length > 0) {
      socialNetwork[0].value.forEach((val: any) => {
        if (Object.keys(val)[0] === metric) {
          [value] = Object.values(val);
        }
      });
    }

    return value;
  };

  useEffect(() => {
    form.setFieldsValue({
      Facebook: getSocialMetricValue("facebook", "followers"),
      Twitter: getSocialMetricValue("twitter", "followers"),
      LinkedIn: getSocialMetricValue("linkedin", "followers"),
      YouTube: getSocialMetricValue("youtube", "followers"),
      Instagram: getSocialMetricValue("instagram", "followers"),
      TikTok: getSocialMetricValue("tiktok", "followers"),
      Twitch: getSocialMetricValue("twitch", "followers"),
      Discord: getSocialMetricValue("discord", "followers"),
      bounceRate: getAnalyticsValue("general", "bounceRate"),
      topTrafficSources: getAnalyticsValue("organic", "topTrafficSources"),
    });
  }, []);

  return (
    <div className="step2-container">
      <div className="add-brand_slide w-slide">
        <h3 className="form-slide-heading">Analytics</h3>
        <div className="add-brand_tab">
          <Form form={form}>
            <div>
              <div className="form_field-label" style={{ marginBottom: "10px" }}>
                Top traffic sources
              </div>
              <Form.Item name="topTrafficSources" style={{ display: "inline-block", margin: 0 }}>
                <Input style={{ width: "250px" }} />
              </Form.Item>
              <div className="form_field-label" style={{ marginBottom: "10px" }}>
                Bounce rate
              </div>
              <Form.Item
                name="bounceRate"
                style={{ display: "inline-block", margin: 0 }}
                rules={[
                  { pattern: /^[+-]?(\d+\.?\d*|\.\d+)$/, message: "Please enter only numbers" },
                ]}
              >
                <Input style={{ width: "250px" }} />
              </Form.Item>
              <div className="form_field-label" style={{ marginBottom: "10px" }}>
                Social media: Followers
              </div>
              <ol style={{ marginLeft: 20 }}>
                <li style={{ marginBottom: 10 }}>
                  <span>Facebook:</span>
                  <Form.Item
                    name="Facebook"
                    style={{ display: "inline-block", margin: 0 }}
                    rules={[{ pattern: /^\d+$/, message: "Please enter only numbers" }]}
                  >
                    <Input style={{ width: "140px", marginLeft: 26 }} />
                  </Form.Item>
                </li>
                <li style={{ marginBottom: 10 }}>
                  <span>Twitter:</span>
                  <Form.Item
                    name="Twitter"
                    style={{ display: "inline-block", margin: 0 }}
                    rules={[{ pattern: /^\d+$/, message: "Please enter only numbers" }]}
                  >
                    <Input style={{ width: "140px", marginLeft: 54 }} />
                  </Form.Item>
                </li>
                <li style={{ marginBottom: 10 }}>
                  <span>LinkedIn:</span>
                  <Form.Item
                    name="LinkedIn"
                    style={{ display: "inline-block", margin: 0 }}
                    rules={[{ pattern: /^\d+$/, message: "Please enter only numbers" }]}
                  >
                    <Input style={{ width: "140px", marginLeft: 42 }} />
                  </Form.Item>
                </li>
                <li style={{ marginBottom: 10 }}>
                  <span>YouTube:</span>
                  <Form.Item
                    name="YouTube"
                    style={{ display: "inline-block", margin: 0 }}
                    rules={[{ pattern: /^\d+$/, message: "Please enter only numbers" }]}
                  >
                    <Input style={{ width: "140px", marginLeft: 36 }} />
                  </Form.Item>
                </li>
                <li style={{ marginBottom: 10 }}>
                  <span>Instagram:</span>
                  <Form.Item
                    name="Instagram"
                    style={{ display: "inline-block", margin: 0 }}
                    rules={[{ pattern: /^\d+$/, message: "Please enter only numbers" }]}
                  >
                    <Input style={{ width: "140px", marginLeft: 20 }} />
                  </Form.Item>
                </li>
                <li style={{ marginBottom: 10 }}>
                  <span>Discord:</span>
                  <Form.Item
                    name="Discord"
                    style={{ display: "inline-block", margin: 0 }}
                    rules={[{ pattern: /^\d+$/, message: "Please enter only numbers" }]}
                  >
                    <Input style={{ width: "140px", marginLeft: 45 }} />
                  </Form.Item>
                </li>
                <li style={{ marginBottom: 10 }}>
                  <span>TikTok:</span>
                  <Form.Item
                    name="TikTok"
                    style={{ display: "inline-block", margin: 0 }}
                    rules={[{ pattern: /^\d+$/, message: "Please enter only numbers" }]}
                  >
                    <Input style={{ width: "140px", marginLeft: 58 }} />
                  </Form.Item>
                </li>
                <li style={{ marginBottom: 10 }}>
                  <span>Twitch:</span>
                  <Form.Item
                    name="Twitch"
                    style={{ display: "inline-block", margin: 0 }}
                    rules={[{ pattern: /^\d+$/, message: "Please enter only numbers" }]}
                  >
                    <Input style={{ width: "140px", marginLeft: 55 }} />
                  </Form.Item>
                </li>
              </ol>
            </div>
          </Form>
          <HelpTipSection
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in
              eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum
              nulla."
            title="Some help text about Locations Producst and Services"
          />
        </div>
      </div>
      <div className="steps-container">
        <div className="next-prev-container">
          <div
            role="button"
            tabIndex={-1}
            onKeyDown={() => handleBack()}
            onClick={() => handleBack()}
            className="prev-btn w-slider-arrow-left btnEnabled"
          >
            <div>Previous</div>
          </div>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => onCheck()}
            onClick={() => onCheck()}
            className="next-btn w-slider-arrow-right"
          >
            <div>Next</div>
          </div>
          <div className="hide w-slider-nav w-round" />
        </div>
      </div>
    </div>
  );
};
