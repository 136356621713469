import React from "react";

import Adbudget from "../../assets/images/icons/Adbudget.png";
import Monthlywebtraffic from "../../assets/images/icons/Monthlywebtraffic.png";
import NumberofBacklinks from "../../assets/images/icons/NumberofBacklinks.png";
import OrganicKeywords from "../../assets/images/icons/OrganicKeywords.png";
import Socialfollowers from "../../assets/images/icons/Socialfollowers.png";
import Info from "../../assets/images/icons/info-icon.svg";

interface IconsProps {
  name: string;
}

export const Icons: React.FC<IconsProps> = (props: IconsProps) => {
  const { name } = props;

  let imageSRC = "";

  switch (name) {
    case "Adbudget":
      imageSRC = Adbudget;
      break;
    case "Monthlywebtraffic":
      imageSRC = Monthlywebtraffic;
      break;
    case "NumberofBacklinks":
      imageSRC = NumberofBacklinks;
      break;
    case "OrganicKeywords":
      imageSRC = OrganicKeywords;
      break;
    case "Socialfollowers":
      imageSRC = Socialfollowers;
      break;
    default:
      imageSRC = Info;
      break;
  }

  return <img src={imageSRC} alt={name} />;
};
