import React, { useRef } from "react";
import Turnstone from "turnstone";
import { RootStateOrAny, useSelector } from "react-redux";

import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";

interface SearchBrandProps {
  onSelectedBrand: any;
  clearOnSelect: boolean;
}

export const SearchBrand: React.FC<SearchBrandProps> = (props: SearchBrandProps) => {
  const turnstoneRef: any = useRef();
  const brands = useSelector((state: RootStateOrAny) => state.industryBrands);

  const listbox = {
    name: "Brands",
    displayField: "name",
    searchType: "startswith",
    data: brands,
  };

  const Item = ({ item }: any) => {
    return (
      <div className="searchResult">
        <img width={35} src={item.logo} alt={item.name} />
        <p>{item.name}</p>
      </div>
    );
  };

  const onBrandSelect = (selectedBrand: any) => {
    if (selectedBrand) {
      props.onSelectedBrand(selectedBrand);

      if (props.clearOnSelect) {
        turnstoneRef.current?.clear();
      }
    }
  };

  return (
    <div className="brandSearch">
      <SearchIcon />
      <Turnstone
        ref={turnstoneRef}
        listbox={listbox}
        typeahead
        matchText
        Item={Item}
        noItemsMessage="We couldn't find any brand that matches your search"
        placeholder="Enter brand name..."
        styles={{
          input: "searchInput",
          highlightedItem: "searchHighlightedItem",
          listbox: "searchListbox",
          noItems: "searchNoItems searchNoItemsHeader",
        }}
        onSelect={onBrandSelect}
      />
    </div>
  );
};
