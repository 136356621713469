import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { ReactComponent as SGLogo } from "../../assets/images/smallGaints_logo.svg";
import { SearchBrand } from "../Search/SearchBrand";
import { setBrand } from "../../actions/actions";

export const Header: React.FC = () => {
  const user = useSelector((state: RootStateOrAny) => state.user);
  const isUserAdmin = user.roles.indexOf("ADMIN") > -1;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userInitials = user.name
    .split(" ")
    .map((n: string) => n[0])
    .join("");

  const anonymousUser = () => {
    return (
      <NavLink to="/v2/login" className="login">
        Login
      </NavLink>
    );
  };

  const existingUser = () => {
    return (
      <>
        <strong>{user.name}</strong>
        <span>{user.brand}</span>
      </>
    );
  };

  const onSelectBrand = (selectedBrand: any) => {
    dispatch(setBrand(selectedBrand));
    navigate(`../v2/brands/${selectedBrand.slug}`, { replace: true });
  };

  return (
    <div className="header">
      <div className="headerContent">
        <NavLink to="/v2">
          <SGLogo />
        </NavLink>
        <span className="title">ESG Performance Index</span>
        <SearchBrand onSelectedBrand={onSelectBrand} clearOnSelect />
        <div className="user">
          <Avatar
            size={40}
            style={{ backgroundColor: "#0A9E97" }}
            icon={!userInitials ? <UserOutlined /> : null}
          >
            {userInitials}
          </Avatar>
          <div className="userContent">{user.name === "" ? anonymousUser() : existingUser()}</div>
          {user.name !== "" && (
            <div className="userMenu">
              {isUserAdmin && (
                <NavLink to="/v2/admin" className="userMenuItem">
                  Admin
                </NavLink>
              )}
              <NavLink to="/v2/logout" className="userMenuItem">
                Logout
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
