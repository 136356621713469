import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { useNavigate } from "react-router-dom";
import { Input, Table, Tooltip, Popconfirm } from "antd";
import { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import { getReviewBrands, deleteBrand } from "../../API/api";
import { setBrand, setReviewBrands, setCompetitor } from "../../actions/actions";
import { Navigation } from "../../components/Navigation/Navigation";
import Navbar from "../../components/Navbar/Navbar";
import { getImageURL } from "../../utils/utils";
import { ReactComponent as InfoIcon } from "../../assets/images/icons/info-icon.svg";
import { SearchBar } from "../../components/SearchBar/SearchBar";

const ReviewBrands: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([] as any);
  const [originalData, setOriginalData] = useState([] as any);
  const reviewBrands = useSelector((state: RootStateOrAny) => state.reviewBrands);
  const [value, setValue] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: 10,
    style: { paddingRight: 20 },
  });

  const emptyBrand = {
    name: "",
    description: "",
    image: "",
    analytics: {
      webScore: 0,
    },
    competitors: [],
    competitorIds: [],
    locations: [],
    locationIds: [],
    industries: [],
    industryIds: [],
    services: [],
    products: [],
  };

  useEffect(() => {
    dispatch(setCompetitor(emptyBrand));
  }, []);

  const getApiReviewBrands = (page: number) => {
    getReviewBrands(page).then((response) => {
      if (!response) {
        return;
      }

      dispatch(setReviewBrands(response.content));

      setPagination((prevPagination: any) => ({
        ...prevPagination,
        current: page + 1,
        total: response.totalElements,
        showTotal: (total: any) => `Total: ${total}`,
        onChange: (pageN: number) => getApiReviewBrands(pageN - 1),
      }));

      if (response.content.length > 0) {
        const data = [] as any;

        response.content.forEach((brand: any, index: number) => {
          const lastModifiedDate = new Date(brand.metadata.lastModifiedDate);

          data.push({
            key: `${index}`,
            brandname: brand.name,
            logo: brand.logo,
            id: brand.brandId || brand.id,
            url: brand.url,
            description: brand.shortDescription,
            modifiedby: brand.metadata.source.name,
            lastmodified: lastModifiedDate.toLocaleDateString(),
            lastModifiedDate,
            status:
              brand.metadata.status === "rejected" ? (
                <div className="row__status">
                  <span>{brand.metadata.status}</span>
                  <span>
                    <Tooltip
                      placement="leftBottom"
                      title={brand.metadata.statusReason}
                      autoAdjustOverflow
                    >
                      <InfoIcon className="icon--info-rejected" />
                    </Tooltip>
                  </span>
                </div>
              ) : (
                brand.metadata.status
              ),
            slug: brand.slug,
          });
        });

        // Show first the latest added brands
        data.sort((a: any, b: any) => b.lastModifiedDate.getTime() - a.lastModifiedDate.getTime());
        setDataSource(data);
        setOriginalData(data);
      } else if (response.length === 0) {
        setDataSource(response);
        setOriginalData(response);
      }
    });
  };

  useEffect(() => {
    getApiReviewBrands(0);
  }, []);

  const FilterByName = (
    <Input
      className="search_table pad w-input"
      placeholder="Search Name"
      value={value}
      onChange={(e) => {
        const currValue = e.target.value;
        setValue(currValue);

        if (currValue !== "") {
          const filteredData = originalData.filter((entry: any) =>
            entry.brandname.toLowerCase().includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        } else {
          setDataSource(originalData);
        }
      }}
    />
  );
  const BrandreviewDetails = (slug: any) => {
    navigate(`../brands/reviewdetails/${slug}`, { replace: true });
  };

  const editBrand = (slug: any) => {
    const brand = reviewBrands.filter((data: any) => data.slug === slug).pop();

    dispatch(setBrand(brand));
    localStorage.setItem("brandSlug", brand.slug);

    navigate(`../brands/${slug}/edit`, { replace: true });
  };

  interface BrandReview {
    title: string;
    dataIndex: string;
    render?: any;
    key?: string;
  }

  const columns: ColumnsType<BrandReview> = [
    {
      title: "Logo",
      dataIndex: "logo",
      render: (logo: string) => (
        <img className="brand-review-list_logo" alt={logo} src={getImageURL(logo, undefined)} />
      ),
    },
    {
      title: "Brand Name",
      dataIndex: "brandname",
      key: "1",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "2",
    },
    {
      title: "Modified By",
      key: "modifiedby",
      dataIndex: "modifiedby",
    },
    {
      title: "Last Modified",
      key: "lastmodified",
      dataIndex: "lastmodified",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "3",
    },
    {
      title: "Action",
      dataIndex: "slug",
      render: (slug: any, row: any) => (
        <div className="reviewActions">
          <button
            type="button"
            onClick={() => BrandreviewDetails(slug)}
            className="btn btn-secondary"
          >
            View
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            style={{ marginLeft: "10px" }}
            onClick={() => editBrand(slug)}
          >
            Edit
          </button>
          <Popconfirm
            title="Are you sure to delete this revision?"
            onConfirm={() => {
              if (row.id) {
                deleteBrand(row.id).then(() => {
                  getApiReviewBrands(0);
                });
              }
            }}
            okText="Yes"
            cancelText="No"
          >
            <button type="button" className="btn btn-secondary deleteButton">
              Remove
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handlePagination = (newPagination: any) => {
    const { current } = newPagination;
    getApiReviewBrands(current - 1);
  };

  return (
    <React.Fragment>
      <Navbar />
      <div className="section analysis">
        <Navigation />
        <main className="main-panel">
          <SearchBar />

          <div className="separator" />

          <section className="card">
            <h2 className="brand-name no-mgn">Review brands</h2>
          </section>
          <section className="card reviewBrands">
            <Table
              columns={columns}
              dataSource={dataSource}
              title={() => FilterByName}
              pagination={pagination}
              onChange={handlePagination}
            />
          </section>
        </main>
      </div>
    </React.Fragment>
  );
};
export default ReviewBrands;
