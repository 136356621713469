import { ProductsServices } from "./brandClass";

export class Industry {
  products: [ProductsServices];

  services: [ProductsServices];

  popularity: number;

  id: string;

  name: string;

  constructor(sel: any) {
    this.products = sel.products;
    this.name = sel.name;
    this.services = sel.services;
    this.popularity = sel.popularity;
    this.id = sel.id;
  }

  hasInput(value: string) {
    return this.name.toLowerCase().includes(value.trim().toLowerCase());
  }

  startWithInput(value: string) {
    return this.name.toLowerCase().startsWith(value.trim().toLowerCase());
  }
}
