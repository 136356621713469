export const getValue = (value: any, entry: any) => {
  return Number.isInteger(value) ? entry.displayValue[0] : Object.values(value);
};

export const hasStats = (stat: any) => {
  const stats = ["Organic Keywords", "Number of Backlinks", "Social followers"];

  return stats.includes(stat);
};

export const getStatTab = (stat: any) => {
  switch (stat) {
    case "Ad budget":
      return "paid";
    case "Organic Keywords":
    case "Number of Backlinks":
      return "organic";
    case "Social followers":
      return "social";
    default:
      return "website";
  }
};

export const capitalizeName = (name: string) => {
  const lowercaseName = name.toLowerCase();

  return lowercaseName[0].toUpperCase() + lowercaseName.slice(1);
};
