import React, { useRef } from "react";
import { Form, Button, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import SearchOthers from "../../../../v1/Search/SearchOthers";
import config from "../../config";

interface LocationsProps {
  data: any;
  setData: any;
}

export const Locations: React.FC<LocationsProps> = ({ data, setData }: LocationsProps) => {
  const inputRef = useRef<HTMLDivElement>(null);

  const addLocation = (location: any, index: number) => {
    const { locations, locationIds } = data;
    locations[index] = location.name;
    locationIds[index] = location.id;

    setData({ ...data, ...{ locations, locationIds } });
  };

  const removeLocation = (index: number) => {
    const { locations, locationIds } = data;
    locations.splice(index, 1);
    locationIds.splice(index, 1);

    setData({ ...data, ...{ locations, locationIds } });
  };

  return (
    <>
      <div className="form_field-label" style={{ marginBottom: "10px" }}>
        Locations
      </div>

      <div>
        <Form.List name="locationsList">
          {(fields, { add, remove }, { errors }) => {
            if (!fields.length) {
              add();
            }

            return (
              <>
                <div className="form_field-wrap--flex locationListForm">
                  <div className="full-width">
                    {fields.map((field, index) => {
                      return (
                        <div
                          key={`${field.key}-${index.toString()}`}
                          style={{ display: "flex", width: "100%" }}
                        >
                          <Form.Item noStyle>
                            <SearchOthers
                              style={{ flex: 1 }}
                              id={`search-${field.key}`}
                              webflowStyle="form_field text-dim-italic w-input"
                              placeholder="Locations"
                              addItemSearch={(location: {
                                id: string;
                                name?: string;
                                city?: string;
                              }) => {
                                if (location.id === "clear") {
                                  remove(index);
                                  removeLocation(index);
                                } else if (location.name !== undefined) {
                                  addLocation(location, index);
                                }
                              }}
                              type="locations"
                              inputValue={"" || data?.locations[index] || undefined}
                              excludes={data?.locationIds}
                            />
                          </Form.Item>
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => {
                                remove(index);
                                removeLocation(index);
                              }}
                              style={{ marginTop: "5px" }}
                            />
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                  <Form.Item style={{ paddingTop: "15px" }}>
                    <Button
                      ref={inputRef}
                      className="add-field-btn"
                      onClick={() => {
                        add();
                      }}
                      disabled={
                        fields.length === config.maxFields.locations ||
                        fields.length !== data?.locations?.length
                      }
                      style={{
                        cursor:
                          fields.length === config.maxFields.locations ||
                          fields.length !== data?.locations?.length
                            ? "not-allowed"
                            : "pointer",
                      }}
                      icon={<PlusOutlined style={{ display: "flex", padding: "4px" }} />}
                    >
                      <div className="add-field-btn">
                        <div className="icon add-field-icon w-embed">
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.88867 4.13574H9.45703V5.67383H5.88867V9.7168H4.25391V5.67383H0.685547V4.13574H4.25391V0.400391H5.88867V4.13574Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div>Add more</div>
                      </div>
                    </Button>
                  </Form.Item>
                </div>
                <Form.ErrorList errors={errors} />
              </>
            );
          }}
        </Form.List>
        <Form.Item
          name="locations"
          className="hiddenField"
          hidden
          rules={[
            {
              required: !data.locations?.length,
              message: (
                <div className="form_error-text">
                  <span className="error-icon" /> Please select at least one location
                </div>
              ),
            },
          ]}
        >
          <Input value={data?.locations?.join(",")} />
        </Form.Item>
      </div>
    </>
  );
};
