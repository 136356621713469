import React, { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Form } from "antd";

import HelpTipSection from "../../../HelpTipSection";

import { Locations } from "./Locations";
import { Industries } from "./Industries";
import { Products } from "./Products";
import { Services } from "./Services";

interface LocationsProductsServicesProps {
  data: any;
  setData: any;
  changeStep: any;
}

export const LocationsIndustries: React.FC<LocationsProductsServicesProps> = ({
  data,
  setData,
  changeStep,
}: LocationsProductsServicesProps) => {
  const [form] = Form.useForm();
  const brand = useSelector((state: RootStateOrAny) => state.brand);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const isUserAdmin = user.roles.indexOf("ADMIN") > -1;

  const handleBack = () => {
    changeStep(1);
  };

  const onCheck = () => {
    const values = form.validateFields();

    values.then(() => changeStep(3)).catch(() => changeStep(2));
  };

  useEffect(() => {
    form.setFieldsValue({
      locationsList: data.locations,
    });
  }, [data]);

  return (
    <div className="step2-container">
      <div className="add-brand_slide w-slide">
        <h3 className="form-slide-heading">
          Add company location, industry, products and services
        </h3>
        <div className="add-brand_tab">
          <Form form={form}>
            <div>
              <Locations data={data} setData={setData} />
              <Industries data={data} setData={setData} />
              <Products data={data} setData={setData} />
              <Services data={data} setData={setData} />
            </div>
          </Form>
          <HelpTipSection
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in
              eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum
              nulla."
            title="Some help text about Locations Producst and Services"
          />
        </div>
      </div>
      <div className="steps-container">
        <div className="next-prev-container">
          {(isUserAdmin || !brand.id) && (
            <div
              role="button"
              tabIndex={-1}
              onKeyDown={() => handleBack()}
              onClick={() => handleBack()}
              className="prev-btn w-slider-arrow-left btnEnabled"
            >
              <div>Previous</div>
            </div>
          )}
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => onCheck()}
            onClick={() => onCheck()}
            className="next-btn w-slider-arrow-right"
          >
            <div>Next</div>
          </div>
          <div className="hide w-slider-nav w-round" />
        </div>
      </div>
    </div>
  );
};
