import React from "react";

import { getImageURL } from "../../utils/utils";
import { DigitalAnalytics } from "../Analytics/DigitalAnalytics";
import { ESGAnalytics } from "../Analytics/ESGAnalytics";

interface BrandProps {
  brand: any;
}

export const Brand: React.FC<BrandProps> = ({ brand }: BrandProps) => {
  const getNames = (sourceArray: any) => {
    let names: any = [];

    if (!sourceArray) {
      return "None";
    }

    if (sourceArray.length > 1) {
      sourceArray.forEach((item: any) => {
        names.push(item.name);
      });

      names = names.sort();
    }

    return names.length > 1 ? names.join(", ") : "None";
  };

  return (
    <div className="brand">
      <div className="card">
        <div className="brandHeader">
          <div className="brandLogo">
            <img
              className="logo"
              src={getImageURL(brand.logo, undefined)}
              loading="lazy"
              alt={brand.name}
            />
            <a className="link" href={brand.url} target="_blank" rel="noreferrer">
              {brand.url}
            </a>
          </div>
          <div className="brandMetrics">
            <div className="brandMetric">
              <span className="title">ESG Score</span>
              <span className="value">XXX</span>
            </div>
            <div className="brandMetric">
              <span className="title">Brand Score</span>
              <span className="value">
                {brand.analytics ? brand?.analytics[0]?.knownDataMap.webScore.value[0] : 0}
              </span>
            </div>
            <div className="brandMetric">
              <span className="title">AUM (BNS)</span>
              <span className="value">XXX</span>
            </div>
          </div>
        </div>
        <p className="description">{brand.shortDescription}</p>
        <img
          className="screenshot"
          src={getImageURL(brand.image, undefined)}
          loading="lazy"
          alt={brand.name}
        />
        <div>
          <p className="label">Services</p>
          <p>{getNames(brand.services)}</p>
        </div>
        <div>
          <p className="label">Products</p>
          <p>{getNames(brand.products)}</p>
        </div>
        <div>
          <p className="label">Locations</p>
          <p>{getNames(brand.locations)}</p>
        </div>
      </div>
      <div className="card">
        <h1>ESG analytics {brand.extendedAttributes["esg-brand-score"] ? "" : "(Test data)"}</h1>
        <ESGAnalytics esgValues={brand?.extendedAttributes["esg-brand-score"]} />
      </div>

      {brand.analytics && (
        <div className="card">
          <h1>Digital Marketing analytics</h1>
          <DigitalAnalytics analytics={brand.analytics} />
        </div>
      )}
    </div>
  );
};
