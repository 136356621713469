import React, { useState } from "react";
import { Skeleton } from "antd";

import { Table } from "../../Table/Table";
import { Icons } from "../../Icons/Icons";
import {
  getStatTab,
  hasStats,
  capitalizeName,
} from "../../../pages/BrandAnalysis/BrandAnalysis.utils";
import { shortenNumber, toggleDropdown } from "../../../utils/utils";
import { ReactComponent as InfoIcon } from "../../../assets/images/icons/info-icon.svg";
import ArrowRight from "../../../assets/images/icons/arrow-right.png";
import { ProductsServices } from "../../../classes/brandClass";

interface BrandAnalyticsProps {
  brand: any;
  brandData: any;
  brandSelectedColumns: any;
  activeTab: string;
  setActiveTab: any;
}

export const BrandAnalytics: React.FC<BrandAnalyticsProps> = ({
  brand,
  brandData,
  brandSelectedColumns,
  activeTab,
  setActiveTab,
}: BrandAnalyticsProps) => {
  const [conditionalRowStyles, setConditionalRowStyles] = useState([]) as any;

  const organicKeywordsStyles = [
    {
      when: (row: any) => row.name === "Organic Keywords",
      style: {
        backgroundColor: "#ccc",
        color: "white !important",
      },
    },
  ];

  const backlinksStyles = [
    {
      when: (row: any) => row.name === "Number of Backlinks",
      style: {
        backgroundColor: "#ccc",
        color: "white !important",
      },
    },
  ];

  const addSelectedStatsStyles = (stat: string) => {
    if (stat === "Organic Keywords") {
      setConditionalRowStyles(organicKeywordsStyles);
    } else if (stat === "Number of Backlinks") {
      setConditionalRowStyles(backlinksStyles);
    } else {
      setConditionalRowStyles([]);
    }
  };

  const changeAnalyticsTab = (tab: string, name: string) => {
    setActiveTab(tab);
    addSelectedStatsStyles(name);
  };

  return (
    <>
      <section
        className="card mb-cols"
        style={{ marginTop: 30, marginBottom: 30, display: "flex" }}
      >
        <h2 className="brand-name no-mgn">Brand digital analytics</h2>

        <div data-hover="" data-delay="0" className="category-dropdown w-dropdown">
          <div
            className="category-dropdown_toggle orange w-dropdown-toggle"
            onClick={(e) => toggleDropdown(e.target)}
            onKeyDown={(e) => toggleDropdown(e.target)}
            tabIndex={0}
            role="button"
          >
            <div className="w-icon-dropdown-toggle" style={{ color: "#fff" }} />
            <a className="tabSelector" href="#" title={activeTab !== "" ? activeTab : "General"}>
              {activeTab !== "" ? activeTab : "General"}
            </a>
          </div>
          <nav className="category-dropdown_list w-dropdown-list">
            <a
              href="#"
              onClick={() => changeAnalyticsTab("general", "")}
              className="category-dropdown_link w-dropdown-link"
            >
              General
            </a>
            <a
              href="#"
              onClick={() => changeAnalyticsTab("website", "")}
              className="category-dropdown_link w-dropdown-link"
            >
              Website
            </a>
            <a
              href="#"
              onClick={() => changeAnalyticsTab("organic", "")}
              className="category-dropdown_link w-dropdown-link"
            >
              Organic
            </a>
            <a
              href="#"
              onClick={() => changeAnalyticsTab("paid", "")}
              className="category-dropdown_link w-dropdown-link"
            >
              Paid
            </a>
            <a
              href="#"
              onClick={() => changeAnalyticsTab("social", "")}
              className="category-dropdown_link w-dropdown-link"
            >
              Social
            </a>
            <a
              href="#"
              onClick={() => changeAnalyticsTab("products", "")}
              className="category-dropdown_link w-dropdown-link"
            >
              Products/Services
            </a>
            <a
              href="#"
              onClick={() => changeAnalyticsTab("locations", "")}
              className="category-dropdown_link w-dropdown-link"
            >
              Locations
            </a>
          </nav>
        </div>
      </section>

      {activeTab !== "products" && activeTab !== "locations" && activeTab !== "general" && (
        <div className="card analytics">
          {brand.id || brand.name === "Not Found" ? (
            <Table
              columns={brandSelectedColumns}
              data={brandData}
              config={{
                pagination: false,
                defaultSortField: "name",
                noDataComponent: "Coming soon...",
                conditionalRowStyles,
              }}
            />
          ) : (
            <div style={{ padding: "0px 30px" }}>
              <Skeleton paragraph={{ rows: 8 }} active />
            </div>
          )}
        </div>
      )}

      {activeTab === "general" && (
        <div className="generalStats mb-cols">
          {brandData ? (
            brandData.map((data: any) => (
              <a
                href="#"
                className="card general"
                title={data.tooltip}
                key={data.id}
                onClick={() =>
                  hasStats(data.name) && changeAnalyticsTab(getStatTab(data.name), data.name)
                }
                style={!hasStats(data.name) ? { cursor: "default" } : undefined}
              >
                <div className="card-icon">
                  <Icons name={data.name.replace(/\s/g, "")} />
                </div>
                <span className="card-value">
                  {Number.isNaN(parseInt(data.actual, 10))
                    ? data.actual
                    : shortenNumber(data.actual)}
                </span>
                <span className="card-name">
                  {capitalizeName(data.name)}
                  {hasStats(data.name) && <img src={ArrowRight} alt="" className="stat-arrow" />}
                </span>
                {data.tooltip && <InfoIcon className="card-tooltip" />}
              </a>
            ))
          ) : (
            <div className="table_item table_item--no-border">None</div>
          )}
        </div>
      )}

      {activeTab === "products" && (
        <>
          <div className="card" style={{ flexDirection: "column" }}>
            <h5>Products</h5>
            <div style={{ fontSize: 13 }}>
              {brand?.products?.length > 0 ? (
                brand.products
                  .sort()
                  .map((product: ProductsServices, index: number) => (
                    <span key={product.id}>
                      {index !== brand.products.length - 1
                        ? `${product.name},  `
                        : `${product.name}`}
                    </span>
                  ))
              ) : (
                <span>None</span>
              )}
            </div>
          </div>
          <div className="card" style={{ flexDirection: "column" }}>
            <h5>Services</h5>
            <div style={{ fontSize: 13 }}>
              {brand?.services?.length > 0 ? (
                brand.services
                  .sort()
                  .map((service: ProductsServices, index: number) => (
                    <span key={service.id}>
                      {index !== brand.services.length - 1
                        ? `${service.name},  `
                        : `${service.name}`}
                    </span>
                  ))
              ) : (
                <span>None</span>
              )}
            </div>
          </div>
        </>
      )}

      {activeTab === "locations" && (
        <div className="card">
          <div style={{ fontSize: 13 }}>
            {brand?.locations?.length > 0 ? (
              brand.locations
                .sort()
                .map((brandLocation: any, index: number) => (
                  <span key={brandLocation.id}>
                    {index !== brand.locations.length - 1
                      ? `${brandLocation.name}, `
                      : `${brandLocation.name}`}
                  </span>
                ))
            ) : (
              <span>None</span>
            )}
          </div>
        </div>
      )}
    </>
  );
};
