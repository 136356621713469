import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import { SearchBar } from "../components/SearchBar/SearchBar";
import Navbar from "../components/Navbar/Navbar";
import { Navigation } from "../components/Navigation/Navigation";
import { BrandForm } from "../components/Forms/Brand/BrandForm";

const EditBrand: React.FC = () => {
  const brand = useSelector((state: RootStateOrAny) => state.brand);

  return (
    <React.Fragment>
      <Navbar />
      <div className="section analysis">
        <Navigation />
        <main className="main-panel edit-brand-container">
          <SearchBar />

          <section className="add-brand-card">
            <div className="brand-card_info">
              <div className="info-block_header">
                <div className="company-header">
                  <h2 className="brand-name underlined">{brand.name}</h2>
                </div>
              </div>
              <BrandForm />
            </div>
          </section>
        </main>
      </div>
    </React.Fragment>
  );
};

export default EditBrand;
