import React from "react";
import { Routes, Route } from "react-router-dom";

import { Header } from "./components/Header/Header";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { HomePage } from "./pages/HomePage";
import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import { Brands } from "./pages/Brands";
import Categories from "./pages/Admin/Categories";
import Logout from "./pages/Logout";
import Admin from "./pages/Admin/Admin";
import RefreshAllKeywords from "./pages/Admin/RefreshAllKeywords";
import RefreshKeywords from "./pages/Admin/RefreshKeywords";

export const Layout: React.FC = () => {
  return (
    <div className="page">
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />
        <Route path="/brands/:slug" element={<Brands />} />
        <Route path="/brands/:slug/compare/:slugCompare" element={<Brands />} />
        <Route element={<ProtectedRoute isAllowed={!!localStorage.getItem("user")} />}>
          <Route path="/admin/categories" element={<Categories />} />
          <Route path="/admin/refresh-all-keywords" element={<RefreshAllKeywords />} />
          <Route path="/admin/refresh-keywords" element={<RefreshKeywords />} />
          <Route path="/admin" element={<Admin />} />
        </Route>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <div className="footer" />
    </div>
  );
};
