import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import {
  BrandAnalysis,
  Search,
  Compare,
  DigitalCompetitiveAnalysis,
  Login,
  SignUp,
  Settings,
  EditBrand,
  ContactUs,
  Logout,
  Semrush,
  AddBrand,
  ReviewBrands,
  Success,
  ReviewBrandsDetails,
  SectorIndex,
} from "./pages";

import { getBrandBySlug } from "./API/api";
import { setUser, setBrand, setIndustry } from "./actions/actions";
import { jsReload, handleDropdownClick } from "./utils/utils";
import Taxonomies from "./pages/Taxonomies/Taxonomies";
import { Layout } from "./Layout";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const brand = useSelector((state: RootStateOrAny) => state.brand);

  jsReload(window.location);

  useEffect(() => {
    // User cache
    const alreadyLoggedUser = localStorage.getItem("user");
    if (alreadyLoggedUser) {
      const jsonUser = JSON.parse(alreadyLoggedUser);
      dispatch(setUser(jsonUser));
    }

    // Brand cache
    const brandSlug = localStorage.getItem("brandSlug");
    if (!brand.name && brandSlug) {
      getBrandBySlug(brandSlug).then((slugBrand: any) => {
        if (slugBrand === undefined) {
          const notFoundBrand = {
            name: "Not found",
            url: true,
            description: "Search for an specific brand using the search field at the top",
            image: "",
            analytics: {
              webScore: 0,
            },
            competitors: [],
          };
          dispatch(setBrand(notFoundBrand));
        } else {
          dispatch(setBrand(slugBrand));
        }
      });
    }

    // Industry cache
    const industry = localStorage.getItem("industry");
    if (industry) {
      const jsonIndustry = JSON.parse(industry);
      dispatch(setIndustry(jsonIndustry));
    }

    window.addEventListener("click", handleDropdownClick);

    return () => {
      window.removeEventListener("click", handleDropdownClick);
    };
  }, []);

  const OldTheme = React.lazy(() => import("./themes/v1"));
  const NewTheme = React.lazy(() => import("./themes/v2"));

  const ThemeSelector = ({ children }: any) => {
    return (
      <>
        <React.Suspense fallback={<></>}>
          {window.location.href.indexOf("v2") > -1 ? <NewTheme /> : <OldTheme />}
        </React.Suspense>
        {children}
      </>
    );
  };

  return (
    <div>
      <ThemeSelector>
        <Routes>
          <Route element={<ProtectedRoute isAllowed={!!localStorage.getItem("user")} />}>
            <Route path="/brands/:slug/compare/:slugCompare" element={<Compare />} />
            <Route path="/brands/:slug/digital" element={<DigitalCompetitiveAnalysis />} />
            <Route path="/brands/new" element={<AddBrand />} />
            <Route path="/brands/review" element={<ReviewBrands />} />
            <Route path="/brands/reviewdetails/:slug" element={<ReviewBrandsDetails />} />
            <Route path="/brands/success" element={<Success />} />
            <Route path="/brands/:slug/edit/*" element={<EditBrand />} />
            <Route path="/brands/:slug" element={<BrandAnalysis />} />
            <Route path="/business" element={<BrandAnalysis />} />
            <Route path="/compare" element={<Compare />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/semrush" element={<Semrush />} />
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/Taxonomies" element={<Taxonomies />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/search" element={<Search />} />
            <Route path="/industries/:slug" element={<SectorIndex />} />
          </Route>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/v2/*" element={<Layout />} />
        </Routes>
      </ThemeSelector>
    </div>
  );
};
