import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { Skeleton } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import { SearchBar } from "../../components/SearchBar/SearchBar";
import { Navigation } from "../../components/Navigation/Navigation";
import Navbar from "../../components/Navbar/Navbar";
import { ProgressBar } from "../../components/v1/ProgressBar/ProgressBar";
import { Table } from "../../components/Table/Table";
import { getImageURL, toggleDropdown } from "../../utils/utils";
import { getColumns, getAnalyticsData, matchesName } from "./Compare.utils";
import { setCompetitor } from "../../actions/actions";
import ArrowRight from "../../assets/images/icons/arrow-right.png";
import { ProductsServices } from "../../classes/brandClass";
import { CategoryDropdown } from "../../components/pages/Compare/CategoryDropdown";
import { ESGAnalytics } from "../../components/pages/Compare/ESGAnalytics";

const Compare: React.FC = () => {
  const brandSelected = useSelector((state: RootStateOrAny) => state.brand);
  const brandCompare = useSelector((state: RootStateOrAny) => state.competitor);
  const industry = useSelector((state: RootStateOrAny) => state.industry);
  const [brandData, setBrandData] = useState([]);
  const [competitorData, setCompetitorData] = useState([]) as any;
  const [rowHighValues, setRowHighValues] = useState([]) as any;
  const [activeTab, setActiveTab] = useState("general");
  const [activeESG, setActiveESG] = useState("ESG Overview");

  const dispatch = useDispatch();
  const { slugCompare } = useParams();

  useEffect(() => {
    if (
      !brandCompare.name &&
      brandSelected.competitors &&
      brandSelected.competitors.length &&
      slugCompare
    ) {
      const brandToCompare = brandSelected.competitors.find(
        (brand: any) => brand.slug === slugCompare
      );
      if (brandToCompare) {
        dispatch(setCompetitor(brandToCompare));
      } else {
        const notFoundCompetitor = {
          name: "Not Found",
          description: "",
          image: "",
          analytics: {
            webScore: 0,
          },
          competitors: [],
        };
        dispatch(setCompetitor(notFoundCompetitor));
      }
    }
    if (brandSelected.id) {
      setBrandData(getAnalyticsData(brandSelected, activeTab));
    }
  }, [brandSelected, activeTab]);

  useEffect(() => {
    if (brandCompare.id) {
      setCompetitorData(getAnalyticsData(brandCompare, activeTab));
    }
  }, [brandCompare, activeTab]);

  useEffect(() => {
    if (brandData && competitorData) {
      const highValues = [] as any;

      brandData.forEach((data: any) => {
        const competitorIndexField = competitorData.findIndex(
          (field: any) => field.name === data.name
        );

        if (data && competitorIndexField >= 0) {
          let value = null;

          if (data.actualValue > competitorData[competitorIndexField].actualValue) {
            value = data.actualValue;
          } else if (data.actualValue !== competitorData[competitorIndexField].actualValue) {
            value = competitorData[competitorIndexField].actualValue;
          }

          highValues.push({ name: data.name, value });
        }
      });

      setRowHighValues([...highValues]);
    }
  }, [brandData, competitorData]);

  const conditionalRowStyles = [
    {
      when: (row: any) => {
        let isHighValue = false;
        if (rowHighValues.length > 0) {
          const highValue = rowHighValues.find((value: any) => value.name === row.name);

          if (highValue) {
            isHighValue = row.actualValue === highValue.value;
          }
        }

        return isHighValue;
      },
      style: {
        backgroundColor: "rgba(65, 199, 137, 0.3)",
      },
    },
  ];

  return (
    <React.Fragment>
      <Navbar />
      <div className="section analysis">
        <Navigation />
        <main className="main-panel compare-page">
          <SearchBar />

          {industry.id !== "" && (
            <Link to={`/industries/${industry.slug}`} className="btn orange w-button back-button">
              <LeftOutlined style={{ marginRight: 5, marginTop: "-1px" }} />
              <span>Back to sector index</span>
            </Link>
          )}

          {industry.id === "" && (
            <Link to={`/brands/${brandSelected.slug}`} className="btn orange w-button back-button">
              <LeftOutlined style={{ marginRight: 5, marginTop: "-1px" }} />
              <span>Back to brand overview</span>
            </Link>
          )}

          <section className="card">
            <h2 className="brand-name no-mgn">Brand comparison</h2>
          </section>

          <div className="w-layout-grid">
            <div className="card" style={{ flexDirection: "column", marginRight: 0 }}>
              <div className="compare-header mb-cols">
                <h2 className="brand-name">
                  {brandSelected.name ? (
                    brandSelected.name
                  ) : (
                    <Skeleton.Input style={{ width: 200 }} active size="default" />
                  )}
                </h2>
                {brandSelected.analytics && (
                  <ProgressBar
                    percentage={
                      brandSelected?.analytics[0]?.general.find(
                        (data: any) => data.id === "webScore"
                      ).value
                    }
                  />
                )}
              </div>
              <div className="screenshot-block compare">
                {brandSelected.image ? (
                  <React.Fragment>
                    <div className="laptop">
                      <div className="laptop_screen">
                        <img
                          src={getImageURL(brandSelected.image, undefined)}
                          loading="lazy"
                          alt={brandSelected.name}
                          className="screenshot"
                        />
                      </div>
                      <div className="laptop-keyboard">
                        <div className="laptop-keyboard_inner" />
                      </div>
                      <div className="laptop-suface" />
                    </div>
                  </React.Fragment>
                ) : (
                  <Skeleton.Image style={{ margin: "72px 0px" }} />
                )}
              </div>

              <h4>Digital Marketing Analytics</h4>

              <div className="category-dropdown w-dropdown" style={{ margin: 0 }}>
                <div
                  className="category-dropdown_toggle orange w-dropdown-toggle"
                  onClick={(e) => toggleDropdown(e.target)}
                  onKeyDown={(e) => toggleDropdown(e.target)}
                  tabIndex={0}
                  role="button"
                >
                  <div className="w-icon-dropdown-toggle" style={{ color: "#fff" }} />
                  <a
                    className="tabSelector"
                    href="#"
                    title={activeTab !== "" ? activeTab : "General"}
                  >
                    {activeTab !== "" ? activeTab : "General"}
                  </a>
                </div>
                <CategoryDropdown setActiveTab={setActiveTab} />
              </div>

              {activeTab !== "products" && activeTab !== "locations" && (
                <div style={{ margin: "20px 0" }}>
                  {brandSelected.id || brandSelected.name === "Not Found" ? (
                    <Table
                      columns={getColumns(brandSelected, activeTab)}
                      data={brandData}
                      config={{
                        pagination: false,
                        defaultSortField: "name",
                        conditionalRowStyles,
                        noDataComponent: "Coming soon...",
                      }}
                    />
                  ) : (
                    <div style={{ padding: "0px 30px" }}>
                      <Skeleton paragraph={{ rows: 8 }} active />
                    </div>
                  )}
                </div>
              )}

              {activeTab === "products" && (
                <div style={{ margin: "20px 0" }}>
                  <div className="score-card">
                    <div className="w-layout-grid score-card_table">
                      <div
                        id="w-node-c61d9f46-ff97-03f2-c3c2-3c69091de9cb-71dc1d82"
                        className="score-card_title-wrap"
                      >
                        <span className="score-card_title">products</span>
                      </div>
                      {brandSelected.products && brandSelected.products.length > 0 ? (
                        brandSelected.products.sort().map((product: ProductsServices) => {
                          let itemClass = "table_item";

                          if (brandCompare.products && brandCompare.products.length > 0) {
                            if (matchesName(brandCompare.products, product.name)) {
                              itemClass = "table_item highlighted";
                            }
                          }

                          return (
                            <div className={itemClass} key={product.id}>
                              {product.name}
                            </div>
                          );
                        })
                      ) : (
                        <div className="table_item">None</div>
                      )}
                    </div>
                  </div>
                  <div className="score-card">
                    <div className="w-layout-grid score-card_table">
                      <div
                        id="w-node-_59aa1867-fdd5-fdbf-babf-d0dc8698fbb7-71dc1d82"
                        className="score-card_title-wrap"
                      >
                        <span className="score-card_title">Services</span>
                      </div>
                      {brandSelected.services && brandSelected.services.length > 0 ? (
                        brandSelected.services.sort().map((service: ProductsServices) => {
                          let itemClass = "table_item";

                          if (brandCompare.services && brandCompare.services.length > 0) {
                            if (matchesName(brandCompare.services, service.name)) {
                              itemClass = "table_item highlighted";
                            }
                          }

                          return (
                            <div className={itemClass} key={service.id}>
                              {service.name}
                            </div>
                          );
                        })
                      ) : (
                        <div className="table_item">None</div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "locations" && (
                <div className="score-card" style={{ margin: "20px 0" }}>
                  <div className="w-layout-grid score-card_table">
                    <div
                      id="w-node-_9f37e722-99a7-055b-8a5c-cfdb8e842b1b-71dc1d82"
                      className="score-card_title-wrap"
                    >
                      <span className="score-card_title">locations</span>
                    </div>
                    {brandSelected.locations && brandSelected.locations.length > 0 ? (
                      brandSelected.locations.sort().map((location: any) => {
                        let itemClass = "table_item";

                        if (brandCompare.locations && brandCompare.locations.length > 0) {
                          if (matchesName(brandCompare.locations, location.name)) {
                            itemClass = "table_item highlighted";
                          }
                        }

                        return (
                          <div className={itemClass} key={location.id}>
                            {location.name}
                          </div>
                        );
                      })
                    ) : (
                      <div className="table_item">None</div>
                    )}
                  </div>
                </div>
              )}

              <ESGAnalytics activeESG={activeESG} setActiveESG={setActiveESG} />

              {brandSelected.id && (
                <Link to={`/brands/${brandSelected.slug}/digital`} className="compare-footprint">
                  Compare Digital Footprint <img src={ArrowRight} alt="" />
                </Link>
              )}
            </div>
            <div className="card" style={{ flexDirection: "column", marginLeft: 0 }}>
              <div className="compare-header mb-cols">
                <h2 className="brand-name">
                  {brandCompare.name ? (
                    brandCompare.name
                  ) : (
                    <Skeleton.Input style={{ width: 200 }} active size="default" />
                  )}
                </h2>
                <ProgressBar
                  percentage={
                    brandCompare?.analytics[0]?.general.find((data: any) => data.id === "webScore")
                      .value
                  }
                />
              </div>
              <div className="screenshot-block compare">
                {brandCompare.image ? (
                  <React.Fragment>
                    <div className="laptop">
                      <div className="laptop_screen">
                        <img
                          src={getImageURL(brandCompare.image, undefined)}
                          loading="lazy"
                          alt={brandCompare.name}
                          className="screenshot"
                        />
                      </div>
                      <div className="laptop-keyboard">
                        <div className="laptop-keyboard_inner" />
                      </div>
                      <div className="laptop-suface" />
                    </div>
                  </React.Fragment>
                ) : (
                  <Skeleton.Image style={{ margin: "72px 0px" }} />
                )}
              </div>

              <h4>Digital Marketing Analytics</h4>

              <div className="category-dropdown w-dropdown" style={{ margin: 0 }}>
                <div
                  className="category-dropdown_toggle orange w-dropdown-toggle"
                  onClick={(e) => toggleDropdown(e.target)}
                  onKeyDown={(e) => toggleDropdown(e.target)}
                  tabIndex={0}
                  role="button"
                >
                  <div className="w-icon-dropdown-toggle" style={{ color: "#fff" }} />
                  <a
                    className="tabSelector"
                    href="#"
                    title={activeTab !== "" ? activeTab : "General"}
                  >
                    {activeTab !== "" ? activeTab : "General"}
                  </a>
                </div>
                <CategoryDropdown setActiveTab={setActiveTab} />
              </div>

              {activeTab !== "products" && activeTab !== "locations" && (
                <div style={{ margin: "20px 0" }}>
                  {brandSelected.id || brandSelected.name === "Not Found" ? (
                    <Table
                      columns={getColumns(brandCompare, activeTab)}
                      data={competitorData}
                      config={{
                        pagination: false,
                        defaultSortField: "name",
                        conditionalRowStyles,
                        noDataComponent: "Coming soon...",
                      }}
                    />
                  ) : (
                    <div style={{ padding: "0px 30px" }}>
                      <Skeleton paragraph={{ rows: 8 }} active />
                    </div>
                  )}
                </div>
              )}

              {activeTab === "products" && (
                <div style={{ margin: "20px 0" }}>
                  <div className="score-card">
                    <div className="w-layout-grid score-card_table">
                      <div
                        id="w-node-c61d9f46-ff97-03f2-c3c2-3c69091de9cb-71dc1d82"
                        className="score-card_title-wrap"
                      >
                        <span className="score-card_title">products</span>
                      </div>
                      {brandCompare.products && brandCompare.products.length > 0 ? (
                        brandCompare.products.sort().map((product: ProductsServices) => {
                          let itemClass = "table_item";

                          if (brandSelected.products && brandSelected.products.length > 0) {
                            if (matchesName(brandSelected.products, product.name)) {
                              itemClass = "table_item highlighted";
                            }
                          }

                          return (
                            <div className={itemClass} key={product.id}>
                              {product.name}
                            </div>
                          );
                        })
                      ) : (
                        <div className="table_item">None</div>
                      )}
                    </div>
                  </div>
                  <div className="score-card">
                    <div className="w-layout-grid score-card_table">
                      <div
                        id="w-node-_59aa1867-fdd5-fdbf-babf-d0dc8698fbb7-71dc1d82"
                        className="score-card_title-wrap"
                      >
                        <span className="score-card_title">Services</span>
                      </div>
                      {brandCompare.services && brandCompare.services.length > 0 ? (
                        brandCompare.services.sort().map((service: ProductsServices) => {
                          let itemClass = "table_item";

                          if (brandSelected.services && brandSelected.services.length > 0) {
                            if (matchesName(brandSelected.services, service.name)) {
                              itemClass = "table_item highlighted";
                            }
                          }

                          return (
                            <div className={itemClass} key={service.id}>
                              {service.name}
                            </div>
                          );
                        })
                      ) : (
                        <div className="table_item">None</div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "locations" && (
                <div className="score-card" style={{ margin: "20px 0" }}>
                  <div className="w-layout-grid score-card_table">
                    <div
                      id="w-node-_9f37e722-99a7-055b-8a5c-cfdb8e842b1b-71dc1d82"
                      className="score-card_title-wrap"
                    >
                      <span className="score-card_title">locations</span>
                    </div>
                    {brandCompare.locations && brandCompare.locations.length > 0 ? (
                      brandCompare.locations.sort().map((location: any) => {
                        let itemClass = "table_item";

                        if (brandSelected.locations && brandSelected.locations.length > 0) {
                          if (matchesName(brandSelected.locations, location.name)) {
                            itemClass = "table_item highlighted";
                          }
                        }

                        return (
                          <div className={itemClass} key={location.id}>
                            {location.name}
                          </div>
                        );
                      })
                    ) : (
                      <div className="table_item">None</div>
                    )}
                  </div>
                </div>
              )}

              <ESGAnalytics activeESG={activeESG} setActiveESG={setActiveESG} />

              {brandCompare.id && (
                <Link to={`/brands/${brandCompare.slug}/digital`} className="compare-footprint">
                  Compare digital footprint <img src={ArrowRight} alt="" />
                </Link>
              )}
            </div>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

export default Compare;
