import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { message, Skeleton, Table } from "antd";

import Navbar from "../components/Navbar/Navbar";
import { Navigation } from "../components/Navigation/Navigation";
import { SearchBar } from "../components/SearchBar/SearchBar";
import {
  getBrandsByIndustry,
  getBrandBySlug,
  getProducts,
  getServices,
  getRegions,
} from "../API/api";
import { setBrand, setCompetitor } from "../actions/actions";
import { setOptions, toggleDropdown } from "../utils/utils";

const SectorIndex: React.FC = () => {
  const [industryData, setIndustryData] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]) as any;
  const [columns, setColumns] = useState([]) as any;
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [selectedProductService, setSelectedProductService] = useState("");
  const [productsServices, setProductsServices] = useState("");
  const [products, setProducts] = useState([]) as any;
  const [services, setServices] = useState([]) as any;
  const [locations, setLocations] = useState([]) as any;
  const [selectedLocation, setSelectedLocation] = useState("");
  const industry = useSelector((state: RootStateOrAny) => state.industry);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToBrand = (brandSlug: string) => {
    getBrandBySlug(brandSlug).then((slugBrand: any) => {
      if (slugBrand === undefined) {
        const notFoundBrand = {
          name: "Not found",
          url: true,
          description: "Search for an specific brand using the search field at the top",
          image: "",
          analytics: {
            webScore: 0,
          },
          competitors: [],
        };
        dispatch(setBrand(notFoundBrand));
      } else {
        dispatch(setBrand(slugBrand));
        navigate(`../brands/${brandSlug}`, { replace: true });
      }
    });
  };

  const dataColumns: any = [
    {
      key: "index",
      render: (text: any, record: any, index: number) => index + 1,
      width: 30,
    },
    {
      title: "Brand",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "64px", height: "64px", display: "flex", alignItems: "center" }}>
              {record.logo && (
                <img
                  src={record.logo}
                  alt={record.name}
                  style={{ width: "64px", maxWidth: "64px", maxHeight: "64px" }}
                />
              )}
            </div>
            <button
              type="button"
              onClick={() => goToBrand(record.slug)}
              style={{ border: 0, background: "none", marginLeft: 10, textAlign: "left" }}
            >
              {record.name}
            </button>
          </div>
        );
      },
    },
    {
      title: "Brand score",
      dataIndex: "webScore",
      sorter: (a: any, b: any) => a.webScore - b.webScore,
      align: "center",
    },
  ];

  useEffect(() => {
    if (industry.slug) {
      const filters = {
        productIds: selectedProduct ? [selectedProduct] : [],
        serviceIds: selectedService ? [selectedService] : [],
        locationIds: selectedLocation ? [selectedLocation] : [],
      };

      getBrandsByIndustry(industry.slug, filters)
        .then((response) => {
          const { content } = response.industryBrands;
          const brandLocations: string[] = [];
          const addedLocations: string[] = [];

          if (response.displayAttributes) {
            response.displayAttributes.forEach((attribute: any) => {
              const attributeColumns = {
                title: attribute.displayName,
                dataIndex: attribute.id,
                align: "center",
              };

              content.forEach((brand: any, index: number) => {
                content[index].key = brand.slug;

                if (brand.locations) {
                  brand.locations.forEach((location: any) => {
                    if (addedLocations.indexOf(location.displayName) < 0) {
                      brandLocations.push(location);
                      addedLocations.push(location.displayName);
                    }
                  });
                }

                content[index][attribute.id] =
                  brand.extendedAttributes[attribute.id]?.value || "N/A";
              });

              if (attribute.id === "aum") {
                const sorter = {
                  sorter: (a: any, b: any) => a.aum - b.aum,
                  render: (text: any, record: any) => {
                    return new Intl.NumberFormat("en-GB").format(record.aum / 1000000000);
                  },
                };

                dataColumns.push({ ...attributeColumns, ...sorter });
              } else if (attribute.id === "esg-brand-score") {
                const sorter = {
                  sorter: (a: any, b: any) => a[attribute.id] - b[attribute.id],
                  render: (text: any, record: any) => {
                    return record[attribute.id];
                  },
                };

                if (selectedProductService === "64219d1ef7c4e42c7c1cc555") {
                  dataColumns.push({ ...attributeColumns, ...sorter });
                }
              } else {
                dataColumns.push(attributeColumns);
              }
            });
          } else {
            content.forEach((brand: any, index: number) => {
              content[index].key = brand.slug;

              if (brand.locations) {
                brand.locations.forEach((location: any) => {
                  if (addedLocations.indexOf(location.displayName) < 0) {
                    brandLocations.push(location);
                    addedLocations.push(location.displayName);
                  }
                });
              }
            });
          }

          setColumns(dataColumns);
          setIndustryData(content);
        })
        .catch((error: any) => {
          message.error(error.message, 2);
        });
    }

    if (industry.id) {
      let productsAndServices: any = [];

      getProducts(industry.id).then((response: any) => {
        setProducts(response);
        productsAndServices = response;

        getServices(industry.id).then((sresponse: any) => {
          const serv = sresponse.filter((s: any) => s.name !== "ESG");

          setServices(serv);
          productsAndServices = [...productsAndServices, ...serv].sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          );
          setProductsServices(productsAndServices);
        });
      });

      getRegions().then((response: any) => {
        const regions = response.content.sort((a: any, b: any) => a.name.localeCompare(b.name));
        setLocations(regions);
      });

      setFirstLoad(false);
    }
  }, [industry, selectedLocation, selectedProductService]);

  const onSelectBrands = (selectedRows: any) => {
    setSelectedBrands(selectedRows);
  };

  const rowSelection: any = {
    selectedBrands,
    hideSelectAll: true,
    onChange: onSelectBrands,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };

  const compareBrands = () => {
    getBrandBySlug(selectedBrands[0]).then((firstBrand: any) => {
      dispatch(setBrand(firstBrand));

      getBrandBySlug(selectedBrands[1]).then((secondBrand: any) => {
        dispatch(setCompetitor(secondBrand));
        navigate(`../brands/${selectedBrands[0]}/compare/${selectedBrands[1]}`, { replace: true });
      });
    });
  };

  const getProductServiceName = (id: string) => {
    const product = products.filter((prod: any) => prod.id === id);

    if (product.length > 0) {
      return product[0].name;
    }

    const service = services.filter((prod: any) => prod.id === id);

    if (service.length > 0) {
      return service[0].name;
    }

    return "Products and Services";
  };

  const getLocationName = (id: string) => {
    const location = locations.filter((loc: any) => loc.id === id);
    return location.length > 0 ? location[0].displayName : "Locations";
  };

  const onPageChange = (current: any) => {
    const newColumns = columns;

    newColumns[0].render = (text: any, record: any, index: number) =>
      (current - 1) * 100 + index + 1;
    setColumns(newColumns);
  };

  const selectProductService = (id: string) => {
    if (id === "") {
      setSelectedProductService("");
      setSelectedProduct("");
      setSelectedService("");
    } else {
      setSelectedProductService(id);

      const product = products.filter((prod: any) => prod.id === id);

      if (product.length > 0) {
        setSelectedProduct(id);
        setSelectedService("");
      } else {
        setSelectedService(id);
        setSelectedProduct("");
      }
    }
  };

  return (
    <React.Fragment>
      <Navbar />
      <div className="section analysis industries-page">
        <Navigation />
        <main className="main-panel">
          <SearchBar />
          <div className="card mb-cols">
            {industry.displayName ? (
              <div className="brand-name-wrapper">
                <h1 className="brand-name brand-title btm-0" style={{ display: "inline-block" }}>
                  Sector
                </h1>
                <h1 className="brand-name orange btm-0" style={{ display: "inline-block" }}>
                  {industry.displayName}
                </h1>
              </div>
            ) : (
              <Skeleton.Input style={{ width: 200 }} active size="default" />
            )}
            <div className="category-dropdown-wrap">
              <div
                data-hover=""
                data-delay="0"
                className="category-dropdown rt-mgn w-dropdown productServicesSectors"
              >
                <div
                  className="category-dropdown_toggle w-dropdown-toggle"
                  onClick={(e) => toggleDropdown(e.target)}
                  onKeyDown={(e) => toggleDropdown(e.target)}
                  tabIndex={0}
                  role="button"
                  style={{ width: "300px" }}
                >
                  <div className="w-icon-dropdown-toggle" />
                  <a
                    style={{ textDecoration: "none", color: "#222222" }}
                    href="#"
                    onClick={() => selectProductService("")}
                    title={getProductServiceName(selectedProductService)}
                  >
                    {getProductServiceName(selectedProductService)}
                  </a>
                </div>
                <nav className="category-dropdown_list w-dropdown-list">
                  {productsServices?.length > 0 && (
                    <>
                      <a
                        href="#"
                        key="all"
                        className="category-dropdown_link w-dropdown-link"
                        onClick={() => selectProductService("")}
                      >
                        Products and Services
                      </a>
                      {setOptions(productsServices, selectProductService, true)}
                    </>
                  )}
                  {!productsServices.length && (
                    <a href="#" key="none" className="category-dropdown_link w-dropdown-link">
                      No products or services
                    </a>
                  )}
                </nav>
              </div>
              <div data-hover="" data-delay="0" className="category-dropdown w-dropdown">
                <div
                  className="category-dropdown_toggle w-dropdown-toggle"
                  onClick={(e) => toggleDropdown(e.target)}
                  onKeyDown={(e) => toggleDropdown(e.target)}
                  tabIndex={0}
                  role="button"
                >
                  <div className="w-icon-dropdown-toggle" />
                  <a
                    style={{ textDecoration: "none", color: "#222222" }}
                    href="#"
                    onClick={() => setSelectedLocation("")}
                    title={getLocationName(selectedLocation)}
                  >
                    {getLocationName(selectedLocation)}
                  </a>
                </div>
                <nav className="category-dropdown_list w-dropdown-list">
                  {locations ? (
                    locations.map((location: any) => (
                      <a
                        href="#"
                        onClick={() => setSelectedLocation(location.id)}
                        key={location.id}
                        className="category-dropdown_link w-dropdown-link"
                      >
                        {location.displayName}
                      </a>
                    ))
                  ) : (
                    <a href="#" key="none" className="category-dropdown_link w-dropdown-link">
                      No locations
                    </a>
                  )}
                </nav>
              </div>
            </div>
          </div>
          <section className="brand-card">
            <div className="brand-card_info" style={{ display: "block" }}>
              <button
                type="button"
                onClick={() => compareBrands()}
                className={
                  selectedBrands.length === 2
                    ? "btn orange w-button"
                    : "btn orange w-button btn-disabled"
                }
              >
                Compare brands
              </button>

              <div className="sector-table">
                {industryData.length > 0 && !firstLoad && (
                  <Table
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                    dataSource={industryData}
                    columns={columns}
                    sortDirections={["descend", "ascend"]}
                    pagination={{
                      onChange: onPageChange,
                      pageSize: 100,
                      hideOnSinglePage: true,
                      showSizeChanger: false,
                    }}
                  />
                )}
              </div>
            </div>
          </section>
        </main>
      </div>
    </React.Fragment>
  );
};

export default SectorIndex;
