/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Col, Row, Select } from "antd";
import { RootStateOrAny, useSelector } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { ReactComponent as InfoIcon } from "../../../../../assets/images/icons/info-icon.svg";
import Search from "../../../../v1/Search/Search";
import config from "../../config";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 104 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 104 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
  },
};

interface CompetitorsProps {
  data: any;
  setData: any;
  fields: any;
  add: any;
  remove: any;
  errors: any;
}

export const CompetitorsList: React.FC<CompetitorsProps> = ({
  data,
  setData,
  fields,
  add,
  remove,
  errors,
}: CompetitorsProps) => {
  const brand = useSelector((state: RootStateOrAny) => state.brand);
  const competitor = useSelector((state: RootStateOrAny) => state.competitor);
  const [isAddCompetitor, setIsAddCompetitor] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);

  const handlerCompetitorFields = (
    field: "brandId" | "industryIds" | "productIds" | "serviceIds" | "locationIds",
    e: { value: ""; label: ""; key: "" } | any,
    index: number
  ) => {
    const newCompetitorsArray =
      data.competitorIds && data.competitorIds.length ? [...data.competitorIds] : [];
    if (field === "brandId") {
      newCompetitorsArray[index] = {
        ...newCompetitorsArray[index],
        brandId: e.id || e.brandId,
        name: e.name,
        industryIds: newCompetitorsArray[index]?.industryIds?.length
          ? newCompetitorsArray[index].industryIds.reduce((filtered: any, option: any) => {
              if (data.industryIds?.length) {
                data.industryIds.forEach((id: string) => {
                  if (id === option.id) {
                    filtered.push(option);
                  }
                });
              }
              return filtered;
            }, [])
          : e.industries,
        locationIds: newCompetitorsArray[index]?.locationIds?.length
          ? newCompetitorsArray[index].locationIds.reduce((filtered: any, option: any) => {
              if (data.locationIds?.length) {
                data.locationIds.forEach((id: string) => {
                  if (id === option.id) {
                    filtered.push(option);
                  }
                });
              }
              return filtered;
            }, [])
          : e.locations,
        serviceIds: newCompetitorsArray[index]?.serviceIds?.length
          ? newCompetitorsArray[index].serviceIds.reduce((filtered: any, option: any) => {
              if (data.serviceIds?.length) {
                data.serviceIds.forEach((id: string) => {
                  if (id === option.id) {
                    filtered.push(option);
                  }
                });
              }
              return filtered;
            }, [])
          : e.services,
        productIds: newCompetitorsArray[index]?.productIds?.length
          ? newCompetitorsArray[index].productIds.reduce((filtered: any, option: any) => {
              if (data.productIds?.length) {
                data.productIds.forEach((id: string) => {
                  if (id === option.id) {
                    filtered.push(option);
                  }
                });
              }
              return filtered;
            }, [])
          : e.products,
      };

      setData({ ...data, ...{ competitorIds: newCompetitorsArray } });
    } else {
      const newSelectValues = e.map((item: any) => ({
        id: item.value,
        name: item.label,
      }));
      newCompetitorsArray[index] = {
        ...newCompetitorsArray[index],
        [field]: [...newSelectValues],
      };

      setData({ ...data, ...{ competitorIds: newCompetitorsArray } });
    }
  };

  useEffect(() => {
    if (!fields.length && inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  useEffect(() => {
    if (competitor?.name) {
      setIsAddCompetitor(true);
    }
  }, [competitor]);

  return (
    <>
      <Form.Item style={isAddCompetitor ? { display: "none" } : undefined}>
        <Button
          ref={inputRef}
          className="btn-wrapper mgn-top-0-5em"
          onClick={() => add()}
          icon={<PlusOutlined style={{ display: "flex", padding: "4px" }} />}
          disabled={fields.length === config.maxFields.competitors}
        >
          <a href="#" className="add-competitor-btn w-inline-block">
            <div className="icon add-competitor-icon w-embed">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.1182 14.8113H22V16.7925H17.1182V22H14.8818V16.7925H10V14.8113H14.8818V10H17.1182V14.8113Z"
                  fill="currentColor"
                />
                <circle cx="16" cy="16" r="15" stroke="currentColor" strokeWidth="2" />
              </svg>
            </div>
            <div>Add a competitor</div>
          </a>
        </Button>

        <Form.ErrorList errors={errors} />
      </Form.Item>

      {data &&
        fields?.length > 0 &&
        fields?.map((field: any, index: any) => {
          const layout = index === 0 ? { ...formItemLayout } : { ...formItemLayoutWithOutLabel };
          let searchValue = "";

          if (data.competitorIds?.length && data.competitorIds[index]) {
            searchValue = data.competitorIds[index]?.name || "";
          }

          const displayField =
            !isAddCompetitor || (isAddCompetitor && searchValue === competitor?.name);

          return (
            <React.Fragment key={field.key}>
              <div
                className="add-competitor_block"
                style={!displayField ? { display: "none" } : undefined}
              >
                <div className="form_field-label mgn-top-0">Enter a competitor&#x27;s name</div>
                <div style={{ display: "flex" }}>
                  <div className="form_field-wrap--flex" style={{ width: "100%" }}>
                    <Form.Item {...field} {...layout} style={{ width: "100%" }}>
                      <Search
                        id="search"
                        webflowStyle="search-input pad w-input"
                        placeholder="Competitor"
                        mainBrand={data}
                        addCompetitors
                        addBrandSearch={(comp: any) => {
                          const id = comp.id || comp.brandId;
                          if (id === "clear" && comp.name === "clear") {
                            setData(() => {
                              const compIds = data.competitorIds;

                              if (compIds.length) {
                                compIds.splice(index, 1, {});
                              }

                              return [{ ...data, ...{ competitorIds: compIds } }];
                            });

                            if (fields.length !== 1) {
                              remove(index);
                            }
                          } else if (id !== "no matchs" && comp.name !== "no matchs") {
                            let competitorFiltered = {};
                            if (Object.keys(data)?.length > 0) {
                              competitorFiltered = {
                                ...comp,
                                products:
                                  comp.products?.filter(
                                    (element: any) => data?.productIds?.indexOf(element.id) >= 0
                                  ) || [],
                                services:
                                  comp.services?.filter(
                                    (element: any) => data?.serviceIds?.indexOf(element.id) >= 0
                                  ) || [],
                                locations:
                                  comp.locations?.filter(
                                    (element: any) => data?.locationIds?.indexOf(element.id) >= 0
                                  ) || [],
                                industries:
                                  comp.industries?.filter(
                                    (element: any) => data?.industryIds?.indexOf(element.id) >= 0
                                  ) || [],
                              };
                            }
                            handlerCompetitorFields("brandId", competitorFiltered, index);
                          }
                        }}
                        excludes={
                          brand.id
                            ? [
                                brand.id,
                                ...((data.competitorIds &&
                                  data.competitorIds.map((comp: any) => comp.id || comp.brandId)) ||
                                  []),
                              ]
                            : [
                                ...((data.competitorIds &&
                                  data.competitorIds.map((comp: any) => comp.id || comp.brandId)) ||
                                  []),
                              ]
                        }
                        searchValue={searchValue}
                      />
                    </Form.Item>

                    <div className="hide w-embed">
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="folder"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M880 298.4H521L403.7 186.2a8.15 8.15 0 00-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32zM840 768H184V256h188.5l119.6 114.4H840V768z" />
                      </svg>
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="folder-open"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M928 444H820V330.4c0-17.7-14.3-32-32-32H473L355.7 186.2a8.15 8.15 0 00-5.5-2.2H96c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h698c13 0 24.8-7.9 29.7-20l134-332c1.5-3.8 2.3-7.9 2.3-12 0-17.7-14.3-32-32-32zM136 256h188.5l119.6 114.4H748V444H238c-13 0-24.8 7.9-29.7 20L136 643.2V256zm635.3 512H159l103.3-256h612.4L771.3 768z" />
                      </svg>
                    </div>
                  </div>
                  {fields.length > 1 ? (
                    <Row>
                      <Col style={{ marginBottom: "30px" }}>
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => {
                            remove(index);
                            if (data.competitorIds[index]) {
                              const compIds = data.competitorIds;

                              if (compIds.length) {
                                compIds.splice(index, 1);
                              }

                              setData({ ...data, ...{ competitorIds: compIds } });
                            }
                          }}
                          style={{ marginTop: "5px" }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                </div>

                {data.competitorIds[index] && Object.keys(data.competitorIds[index]).length !== 0 && (
                  <>
                    <Row style={{ width: "80%" }}>
                      <div className="form_field-label">Competitor&apos;s location(s):</div>
                      <Col className="full-width">
                        <Form.Item
                          key={
                            data.competitorIds && data.competitorIds[index]?.locations?.length
                              ? data.competitorIds[index].locations[0]?.id
                              : Math.random()
                          }
                          {...formItemLayout}
                          className="full-width"
                          rules={[
                            {
                              required:
                                data.competitorIds[index] &&
                                !data.competitorIds[index].locationIds?.length,
                              message: (
                                <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                                  <span
                                    style={{
                                      marginRight: "5px",
                                      maxWidth: "16px",
                                      maxHeight: "16px",
                                    }}
                                  >
                                    <InfoIcon />{" "}
                                  </span>
                                  Please select at least one location
                                </div>
                              ),
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            dropdownStyle={{
                              left: "auto !important",
                              right: "auto !important",
                            }}
                            onChange={(value) => {
                              if (value?.length > config.maxFields.locations) {
                                value.pop();
                              } else {
                                handlerCompetitorFields("locationIds", value, index);
                              }
                            }}
                            labelInValue
                            defaultValue={
                              data.competitorIds[index]?.locationIds?.length
                                ? data.competitorIds[index].locationIds.map((element: any) => ({
                                    value: element.id,
                                    label: element.name,
                                    key: element.id,
                                  }))
                                : null
                            }
                          >
                            {data?.competitorIds?.length &&
                              data.competitorIds[index]?.locations?.length &&
                              data.competitorIds[index].locations.map((element: any) => {
                                if (data.locationIds?.indexOf(element.id) >= 0) {
                                  return (
                                    <Option key={element.id} value={element.id}>
                                      {element.name}
                                    </Option>
                                  );
                                }

                                return null;
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                      {data.competitorIds?.length &&
                      data.competitorIds[index] &&
                      (!data.competitorIds[index]?.locations ||
                        !data.competitorIds[index]?.locations?.length) ? (
                        <Col style={{ marginTop: "-20px" }}>
                          <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                            <span style={{ marginRight: "5px", maxWidth: "16px", height: "auto" }}>
                              <InfoIcon />{" "}
                            </span>
                            This competitors does not has any location, yet.
                          </div>
                        </Col>
                      ) : data.competitorIds[index]?.locations?.filter((element: any) => {
                          if (data.locationIds?.indexOf(element.id) >= 0) {
                            return element;
                          }

                          return null;
                        }).length === 0 ? (
                        <Col style={{ marginTop: "-20px" }}>
                          <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                            <span style={{ marginRight: "5px", maxWidth: "16px", height: "auto" }}>
                              <InfoIcon />{" "}
                            </span>
                            There are no matching locations for this competitor.
                          </div>
                        </Col>
                      ) : (
                        data.competitorIds[index]?.locations?.length &&
                        data.competitorIds[index]?.locationIds?.length === 0 && (
                          <Col style={{ marginTop: "-20px" }}>
                            <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                              <span
                                style={{ marginRight: "5px", maxWidth: "16px", height: "auto" }}
                              >
                                <InfoIcon />{" "}
                              </span>
                              Select an option from the list.
                            </div>
                          </Col>
                        )
                      )}
                    </Row>

                    <Row style={{ width: "80%" }}>
                      <div className="form_field-label">Competitor&apos;s Industries:</div>
                      <Col className="full-width">
                        <Form.Item
                          key={
                            data.competitorIds &&
                            data.competitorIds[index]?.industries?.length &&
                            data.competitorIds[index]?.industries[0]
                              ? data.competitorIds[index]?.industries[0]?.id
                              : Math.random()
                          }
                          {...formItemLayout}
                          className="full-width"
                          rules={[
                            {
                              required: !data.competitorIds[index]?.industryIds?.length,
                              message: (
                                <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                                  <span
                                    style={{
                                      marginRight: "5px",
                                      maxWidth: "16px",
                                      maxHeight: "16px",
                                    }}
                                  >
                                    <InfoIcon />{" "}
                                  </span>
                                  Please select at least one industry
                                </div>
                              ),
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            className="full-width"
                            onChange={(value) => {
                              if (value?.length > config.maxFields.industries) {
                                value.pop();
                              } else {
                                handlerCompetitorFields("industryIds", value, index);
                              }
                            }}
                            labelInValue
                            defaultValue={
                              data.competitorIds[index]?.industryIds?.length
                                ? data.competitorIds[index].industryIds.map((element: any) => ({
                                    value: element.id,
                                    label: element.name,
                                    key: element.id,
                                  }))
                                : null
                            }
                          >
                            {data?.competitorIds?.length &&
                              data.competitorIds[index]?.industries?.length &&
                              data.competitorIds[index].industries.map((element: any) => {
                                if (data.industryIds?.indexOf(element.id) >= 0) {
                                  return (
                                    <Option key={element.id} value={element.id}>
                                      {element.name}
                                    </Option>
                                  );
                                }

                                return null;
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                      {data.competitorIds &&
                      data.competitorIds.length &&
                      (!data.competitorIds[index]?.industries ||
                        !data.competitorIds[index]?.industries?.length) ? (
                        <Col style={{ marginTop: "-20px" }}>
                          <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                            <span style={{ marginRight: "5px", maxWidth: "16px", height: "auto" }}>
                              <InfoIcon />{" "}
                            </span>
                            This competitors does not has any industry, yet.
                          </div>
                        </Col>
                      ) : data.competitorIds[index]?.industries?.length &&
                        data.competitorIds[index].industries.filter((element: any) => {
                          if (data.industryIds?.indexOf(element.id) >= 0) {
                            return element;
                          }

                          return null;
                        }).length === 0 ? (
                        <Col style={{ marginTop: "-20px" }}>
                          <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                            <span style={{ marginRight: "5px", maxWidth: "16px", height: "auto" }}>
                              <InfoIcon />{" "}
                            </span>
                            There are no matching industries for this competitor.
                          </div>
                        </Col>
                      ) : (
                        data.competitorIds[index]?.industries?.length &&
                        data.competitorIds[index]?.industryIds?.length === 0 && (
                          <Col style={{ marginTop: "-20px" }}>
                            <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                              <span
                                style={{ marginRight: "5px", maxWidth: "16px", height: "auto" }}
                              >
                                <InfoIcon />{" "}
                              </span>{" "}
                              Select an industry from the list.
                            </div>
                          </Col>
                        )
                      )}
                    </Row>

                    <Row style={{ width: "80%" }}>
                      <div className="form_field-label">Competitor&apos;s products:</div>
                      <Col className="full-width">
                        <Form.Item
                          key={
                            data.competitorIds[index]?.productIds &&
                            data.competitorIds[index].productIds.length &&
                            data.competitorIds[index].productIds[0]
                              ? data.competitorIds[index].productIds[0].id
                              : Math.random()
                          }
                          {...formItemLayout}
                          className="full-width"
                          rules={[
                            {
                              required: !data.competitorIds[index]?.productIds?.length,
                              message: (
                                <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                                  <span
                                    style={{
                                      marginRight: "5px",
                                      maxWidth: "16px",
                                      maxHeight: "16px",
                                    }}
                                  >
                                    <InfoIcon />{" "}
                                  </span>
                                  Please select at least one product
                                </div>
                              ),
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            className="full-width"
                            onChange={(value) => {
                              if (value?.length > config.maxFields.products) {
                                value.pop();
                              } else {
                                handlerCompetitorFields("productIds", value, index);
                              }
                            }}
                            labelInValue
                            defaultValue={
                              data.competitorIds[index]?.productIds?.length
                                ? data.competitorIds[index].productIds.map((element: any) => ({
                                    value: element.id,
                                    label: element.name,
                                    key: element.id,
                                  }))
                                : null
                            }
                          >
                            {data?.competitorIds?.length &&
                              data.competitorIds[index]?.products?.length &&
                              data.competitorIds[index].products.map((element: any) => {
                                if (data.productIds?.indexOf(element.id) >= 0) {
                                  return (
                                    <Option key={element.id} value={element.id}>
                                      {element.name}
                                    </Option>
                                  );
                                }

                                return null;
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                      {data.competitorIds &&
                      data.competitorIds.length &&
                      (!data.competitorIds[index]?.products ||
                        !data.competitorIds[index]?.products?.length) ? (
                        <Col style={{ marginTop: "-20px" }}>
                          <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                            <span style={{ marginRight: "5px", maxWidth: "16px", height: "auto" }}>
                              <InfoIcon />{" "}
                            </span>
                            This competitors does not has any product, yet.
                          </div>
                        </Col>
                      ) : data.competitorIds[index]?.products?.length &&
                        data.competitorIds[index].products.filter((element: any) => {
                          if (data.productIds?.indexOf(element.id) >= 0) {
                            return element;
                          }

                          return null;
                        }).length === 0 ? (
                        <Col style={{ marginTop: "-20px" }}>
                          <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                            <span style={{ marginRight: "5px", maxWidth: "16px", height: "auto" }}>
                              <InfoIcon />{" "}
                            </span>
                            There are no matching products for this competitor.
                          </div>
                        </Col>
                      ) : (
                        data.competitorIds[index]?.products?.length &&
                        data.competitorIds[index]?.productIds?.length === 0 && (
                          <Col style={{ marginTop: "-20px" }}>
                            <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                              <span
                                style={{ marginRight: "5px", maxWidth: "16px", height: "auto" }}
                              >
                                <InfoIcon />{" "}
                              </span>{" "}
                              Select a product from the list.
                            </div>
                          </Col>
                        )
                      )}
                    </Row>

                    <Row style={{ width: "80%" }}>
                      <div className="form_field-label">Competitor&apos;s services:</div>
                      <Col className="full-width">
                        <Form.Item
                          key={
                            data.competitorIds[index]?.services?.length &&
                            data.competitorIds[index].services[0]
                              ? data.competitorIds[index].services[0].id
                              : Math.random()
                          }
                          {...formItemLayout}
                          className="full-width"
                          rules={[
                            {
                              required: !data.competitorIds[index]?.serviceIds?.length,
                              message: (
                                <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                                  <span
                                    style={{
                                      marginRight: "5px",
                                      maxWidth: "16px",
                                      maxHeight: "16px",
                                    }}
                                  >
                                    <InfoIcon />{" "}
                                  </span>
                                  Please select at least one service
                                </div>
                              ),
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            className="full-width"
                            onChange={(value) => {
                              if (value?.length > config.maxFields.products) {
                                value.pop();
                              } else {
                                handlerCompetitorFields("serviceIds", value, index);
                              }
                            }}
                            labelInValue
                            defaultValue={
                              data.competitorIds[index]?.serviceIds?.length
                                ? data.competitorIds[index].serviceIds.map((element: any) => ({
                                    value: element.id,
                                    label: element.name,
                                    key: element.id,
                                  }))
                                : null
                            }
                          >
                            {data?.competitorIds?.length &&
                              data.competitorIds[index]?.services?.length &&
                              data.competitorIds[index].services.map((element: any) => {
                                if (data.serviceIds?.indexOf(element.id) >= 0) {
                                  return (
                                    <Option key={element.id} value={element.id}>
                                      {element.name}
                                    </Option>
                                  );
                                }

                                return null;
                              })}
                          </Select>
                        </Form.Item>
                      </Col>

                      {data.competitorIds &&
                      data.competitorIds.length &&
                      data.competitorIds[index] &&
                      (!data.competitorIds[index]?.services ||
                        !data.competitorIds[index]?.services?.length) ? (
                        <Col style={{ marginTop: "-20px" }}>
                          <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                            <span style={{ marginRight: "5px", maxWidth: "16px", height: "auto" }}>
                              <InfoIcon />{" "}
                            </span>
                            This competitors does not has any service, yet.
                          </div>
                        </Col>
                      ) : data.competitorIds[index]?.services?.length &&
                        data.competitorIds[index].services.filter((element: any) => {
                          if (data.serviceIds?.indexOf(element.id) >= 0) {
                            return element;
                          }

                          return null;
                        }).length === 0 ? (
                        <Col style={{ marginTop: "-20px" }}>
                          <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                            <span style={{ marginRight: "5px", maxWidth: "16px", height: "auto" }}>
                              <InfoIcon />{" "}
                            </span>
                            There are no matching services for this competitor.
                          </div>
                        </Col>
                      ) : (
                        data.competitorIds[index]?.services?.length &&
                        data.competitorIds[index]?.serviceIds?.length === 0 && (
                          <Col style={{ marginTop: "-20px" }}>
                            <div className="form_warning-text" style={{ marginTop: "0.5rem" }}>
                              <span
                                style={{ marginRight: "5px", maxWidth: "16px", height: "auto" }}
                              >
                                <InfoIcon />{" "}
                              </span>{" "}
                              Select a service from the list.
                            </div>
                          </Col>
                        )
                      )}
                    </Row>
                  </>
                )}
              </div>
            </React.Fragment>
          );
        })}
    </>
  );
};

export default CompetitorsList;
