import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import Search from "../v1/Search/Search";
import { ReactComponent as SearchIcon } from "../../webflow/images/search-icon.svg";

export const SearchBar: React.FC = () => {
  const brand = useSelector((state: RootStateOrAny) => state.brand);

  return (
    <form action="/search" className="search top-bar mob w-form">
      <div className="search-wrapper">
        <Search
          id="search"
          webflowStyle="search-input pad w-input"
          placeholder="Enter brand name"
          excludes={brand ? [brand.id] : undefined}
        >
          <SearchIcon className="search-icon" />
        </Search>
      </div>
    </form>
  );
};
