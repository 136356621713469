import React from "react";
import PropTypes from "prop-types";
import { Outlet, Navigate } from "react-router-dom";

export const ProtectedRoute = ({ isAllowed, redirectPath, children }: any) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
};

ProtectedRoute.defaultProps = {
  isAllowed: true,
  redirectPath: "/",
  children: null,
};

ProtectedRoute.propTypes = {
  isAllowed: PropTypes.bool,
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};
