import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { createBrand, patchBrand } from "../../../API/api";
import { setCompetitor } from "../../../actions/actions";
import { Details, LocationsIndustries, Competitors, Analytics } from "./Steps";
import { normalizedCompetitors } from "./BrandForm.utils";
import { Stepper } from "..";

export const BrandForm: React.FC = () => {
  const brand = useSelector((state: RootStateOrAny) => state.brand);
  const competitor = useSelector((state: RootStateOrAny) => state.competitor);
  const userInput = useSelector((state: RootStateOrAny) => state.userInput);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const isUserAdmin = user.roles.indexOf("ADMIN") > -1;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationURL = useLocation();
  const addCompetitors = locationURL.pathname.indexOf("edit/competitors") > -1;

  const emptyData = {
    name: userInput,
    shortDescription: "",
    url: "",
    logo: "",
    competitorIds: [],
    locations: [],
    locationIds: [],
    industries: [],
    industryIds: [],
    services: [],
    serviceIds: [],
    products: [],
    productIds: [],
    analytics: [{ general: [], organic: [], social: [] }],
  };

  const [data, setData] = useState(emptyData);
  const [step, setStep] = useState(isUserAdmin || !brand.id ? 0 : 2);
  const [steps, setSteps] = useState([
    {
      title: "details",
      status: "wait",
    },
    {
      title: "analytics",
      status: "wait",
    },
    {
      title: "locationsProductsServices",
      status: "wait",
    },
    {
      title: "competitors",
      status: "wait",
    },
  ]);

  const changeStep = (newStep: number) => {
    const detailsError = newStep === step && newStep === 0;
    const detailsStatus = detailsError ? "error" : "wait";
    const analyticsError = newStep === step && newStep === 1;
    const analyticsStatus = analyticsError ? "error" : "wait";
    const locationsError = newStep === step && newStep === 2;
    const locationsStatus = locationsError ? "error" : "wait";
    const compatitorsError = newStep === step && newStep === 3;
    const compatitorsStatus = compatitorsError ? "error" : "wait";

    const newSteps = [
      {
        title: "details",
        status: newStep < 1 ? detailsStatus : "finish",
      },
      {
        title: "analytics",
        status: newStep < 2 ? analyticsStatus : "finish",
      },
      {
        title: "locationsProductsServices",
        status: newStep < 3 ? locationsStatus : "finish",
      },
      {
        title: "competitors",
        status: newStep < 4 ? compatitorsStatus : "finish",
      },
    ];

    setSteps([...[], ...newSteps]);

    if (newStep !== step) {
      setStep(newStep);
    }
  };

  useEffect(() => {
    if (brand.id) {
      const locationIds = brand?.locations?.map((loc: any) => loc.id) || [];
      const industryIds = brand?.industries?.map((ind: any) => ind.id) || [];
      const serviceIds = brand?.services?.map((svc: any) => svc.id) || [];
      const productIds = brand?.products?.map((prod: any) => prod.id) || [];
      const newBrand: any = brand;
      const newData = {
        name: brand?.name,
        shortDescription: brand?.shortDescription,
        url: brand?.url,
        logo: brand?.logo,
        competitorIds: normalizedCompetitors(newBrand, newBrand.competitors) || [],
        locations: brand?.locations?.map((loc: any) => loc.name) || [],
        locationIds,
        industries: brand?.industries?.map((ind: any) => ind.name) || [],
        industryIds,
        services: brand?.services?.map((svc: any) => svc.name) || [],
        serviceIds,
        products: brand?.products?.map((prod: any) => prod.name) || [],
        productIds,
        analytics: [
          {
            general: brand?.analytics[0]?.general || [],
            organic: brand?.analytics[0]?.organic || [],
            social: brand?.analytics[0]?.social || [],
          },
        ],
      };

      setData(newData);

      if (competitor?.name && addCompetitors) {
        const competitorList: any = newData.competitorIds;
        const newCompetitor: any = normalizedCompetitors(newBrand, [competitor]);
        const newCompetitorsArray: any = [...competitorList, ...newCompetitor];

        setData({ ...newData, ...{ competitorIds: newCompetitorsArray } });
      }
    }
  }, [brand, competitor]);

  const normalizedData = () => {
    const newData: any = data;

    if (newData.competitorIds.length > 0) {
      newData.competitorIds.forEach((comp: any, index: number) => {
        delete newData.competitorIds[index].name;

        if (comp?.industryIds?.length > 0) {
          newData.competitorIds[index].industryIds = comp.industryIds.map((v: any) => v.id);
        }
        if (comp?.locationIds?.length > 0) {
          newData.competitorIds[index].locationIds = comp.locationIds.map((v: any) => v.id);
        }
        if (comp?.productIds?.length > 0) {
          newData.competitorIds[index].productIds = comp.productIds.map((v: any) => v.id);
        }
        if (comp?.serviceIds?.length > 0) {
          newData.competitorIds[index].serviceIds = comp.serviceIds.map((v: any) => v.id);
        }
      });
    }

    return newData;
  };

  const addBrand = async () => {
    const newData: any = normalizedData();

    createBrand(JSON.stringify(newData))
      .then((result: any) => {
        if (result === undefined) {
          navigate(`../brands/success`, {
            state: { success: false, from: "form", brandSlug: brand.slug },
            replace: true,
          });
        }

        navigate(`../brands/success`, {
          state: { success: true, from: "form", brandSlug: brand.slug },
          replace: true,
        });
      })
      .catch(() => {
        navigate(`../brands/success`, {
          state: { success: false, from: "form", brandSlug: brand.slug },
          replace: true,
        });
      });
  };

  const editBrand = () => {
    const dataToApi = [] as any;
    const newData: any = normalizedData();

    if (brand && newData) {
      if (brand?.name !== newData?.name || (!brand.name && newData.name)) {
        dataToApi.push({ op: "replace", path: "/name", value: newData.name });
      }
      if (
        brand?.shortDescription !== newData?.shortDescription ||
        (!brand.shortDescription && newData.shortDescription)
      ) {
        dataToApi.push({
          op: "replace",
          path: "/shortDescription",
          value: newData.shortDescription,
        });
      }
      if (brand?.logo !== newData?.logo || (!brand.logo && newData.logo)) {
        dataToApi.push({ op: "replace", path: "/logo", value: newData.logo });
      }
      if (brand?.url !== newData?.url || (!brand.url && newData.url)) {
        dataToApi.push({ op: "replace", path: "/url", value: newData.url });
      }
      if (newData?.locationIds?.length > 0) {
        const newLocations = [] as any;
        newData.locationIds.forEach((el: any) => {
          if (!brand.locations) {
            newLocations.push(el);
          } else if (brand.locations.map((location: any) => location.id).indexOf(el) === -1) {
            newLocations.push(el);
          }
        });
        if (newLocations) {
          dataToApi.push({ op: "replace", path: "/locationIds", value: newData.locationIds });
        }
      }
      if (newData?.industryIds?.length > 0) {
        const newIndustries = [] as any;
        newData.industryIds.forEach((el: any) => {
          if (!brand.industries) {
            newIndustries.push(el);
          } else if (brand.industries.map((industrie: any) => industrie.id).indexOf(el) === -1) {
            newIndustries.push(el);
          }
        });
        if (newIndustries) {
          dataToApi.push({ op: "replace", path: "/industryIds", value: newData.industryIds });
        }
      }
      if (newData?.productIds?.length > 0) {
        const newProducts = [] as any;
        newData.productIds.forEach((el: any) => {
          if (!brand.products) {
            newProducts.push(el);
          } else if (brand.products.map((product: any) => product.id).indexOf(el) === -1) {
            newProducts.push(el);
          }
        });
        if (newProducts) {
          dataToApi.push({ op: "replace", path: "/productIds", value: newData.productIds });
        }
      }
      if (newData?.serviceIds?.length > 0) {
        const newServices = [] as any;
        newData.serviceIds.forEach((el: any) => {
          if (!brand.services) {
            newServices.push(el);
          } else if (brand.services.map((service: any) => service.id).indexOf(el) === -1) {
            newServices.push(el);
          }
        });
        if (newServices) {
          dataToApi.push({ op: "replace", path: "/serviceIds", value: newData.serviceIds });
        }
      }
      if (newData?.competitorIds?.length > 0) {
        const newCompetitors = [] as any;
        newData.competitorIds?.forEach((el: any) => {
          if (!brand.competitors) {
            newCompetitors.push(el);
          } else if (brand.competitors?.map((comp: any) => comp.id).indexOf(el.brandId) === -1) {
            newCompetitors.push(el);
          }
        });
        if (newCompetitors) {
          dataToApi.push({ op: "replace", path: "/competitorIds", value: newData.competitorIds });
        }
      }
      if (newData?.analytics[0].general?.length > 0) {
        dataToApi.push({
          op: "replace",
          path: "/analytics/0/general",
          value: newData.analytics[0].general,
        });
      }
      if (newData?.analytics[0].organic?.length > 0) {
        dataToApi.push({
          op: "replace",
          path: "/analytics/0/organic",
          value: newData.analytics[0].organic,
        });
      }
      if (newData?.analytics[0].social?.length > 0) {
        dataToApi.push({
          op: "replace",
          path: "/analytics/0/social",
          value: newData.analytics[0].social,
        });
      }
    }

    patchBrand(dataToApi, brand.id)
      .then((result: any) => {
        if (competitor?.name) {
          dispatch(setCompetitor({}));
        }
        if (result === undefined) {
          navigate(`../brands/success`, {
            state: { success: false, from: "form", brandSlug: brand.slug },
            replace: true,
          });
        }

        navigate(`../brands/success`, {
          state: { success: true, from: "form", brandSlug: brand.slug },
          replace: true,
        });
      })
      .catch(() => {
        navigate(`../brands/success`, {
          state: { success: false, from: "form", brandSlug: brand.slug },
          replace: true,
        });
      });
  };

  useEffect(() => {
    if (step === 4 && !brand.id) {
      addBrand();
    } else if (step === 4 && brand.id) {
      editBrand();
    }
  }, [step]);

  useEffect(() => {
    if (addCompetitors) {
      changeStep(3);
    }
  }, []);

  return (
    <section className="add-brand-card">
      <React.Fragment>
        <div className="brand-card_info _w-100">
          <div className="add-brand-card_content">
            <div className="add-brand">
              <div className="w-form">
                <div className="form">
                  <div
                    data-animation="outin"
                    data-hide-arrows="1"
                    data-disable-swipe="1"
                    data-duration="600"
                    data-infinite="1"
                    className={`w-slider ${
                      step === 1 ? "add-brand_slider--fixed" : "add-brand_slider"
                    }`}
                  >
                    <div className="w-slider-mask">
                      <div>
                        {step === 0 && (
                          <Details data={data} setData={setData} changeStep={changeStep} />
                        )}
                        {step === 1 && (
                          <Analytics data={data} setData={setData} changeStep={changeStep} />
                        )}
                        {step === 2 && (
                          <LocationsIndustries
                            data={data}
                            setData={setData}
                            changeStep={changeStep}
                          />
                        )}
                        {step === 3 && (
                          <Competitors data={data} setData={setData} changeStep={changeStep} />
                        )}
                      </div>
                      <div>
                        <div className="hide w-slider-nav w-round" />
                        {!brand.id && <Stepper step={step} steps={steps} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="add-brand-card_blue-bg" />
      </React.Fragment>
    </section>
  );
};
