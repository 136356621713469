import React, { useEffect, useState } from "react";
import { Form, Input, Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { RootStateOrAny, useSelector } from "react-redux";

import HelpTipSection from "../../../HelpTipSection";
import {
  uploadImage,
  checkUrlExists,
  checkNameExists,
  getBrandDescription,
} from "../../../../../API/api";
import { ReactComponent as PlaceholderLogo } from "../../../../../assets/images/placeholder-uploadLogo.svg";

interface DetailsProps {
  data: any;
  setData: any;
  changeStep: any;
}

export const Details: React.FC<DetailsProps> = ({ data, setData, changeStep }: DetailsProps) => {
  const brand = useSelector((state: RootStateOrAny) => state.brand);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState("");
  const [name, setName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [url, setUrl] = useState("");
  const [logoList, setLogoList] = useState([] as any);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      logo: data.logo,
      name: data.name,
      shortDescription: data.shortDescription,
      url: data.url,
    });

    setName(data.name);
    setLogo(data.logo);
    setUrl(data.url);
    setShortDescription(data.shortDescription);
  }, [data]);

  const getDescriptionFromURL = () => {
    if (url) {
      setLoading(true);
      getBrandDescription(url)
        .then((description: string) => {
          setLoading(false);
          if (description) {
            form.setFieldsValue({
              shortDescription: description,
            });
            setShortDescription(description);
          } else {
            message.info("The specified URL doesn't have a description");
          }
        })
        .catch((error: any) => {
          message.error(error.message, 2);
        });
    } else {
      message.error("Please enter a valid URL", 2);
    }
  };

  const handleLogoChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
    }
    if (info.file.status === "done") {
      setLoading(false);
    }

    setLogoList([...info.fileList]);
  };

  const customRequest = async (options: any) => {
    const { onError, file } = options;

    uploadImage(file, "LOGOS")
      .then((result) => {
        setLogo(result.data);
        setLoading(false);
        setLogoList([...logoList]);
        const newData = {
          logo: result.data,
          name,
          shortDescription,
          url,
        };

        setData({ ...data, ...newData });
      })
      .catch((error) => {
        onError({ error });
      });
  };

  const onCheckForm = () => {
    const values = form.validateFields();

    values
      .then((result) => {
        setData({ ...data, ...result });
        changeStep(1);
      })
      .catch(() => changeStep(0));
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="upload-img-block">
        <PlaceholderLogo className="upload-img-block_img" />
        <div className="upload-img-block_btn">
          <div className="icon w-embed">
            <svg
              width="14"
              height="14"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.88867 4.13574H9.45703V5.67383H5.88867V9.7168H4.25391V5.67383H0.685547V4.13574H4.25391V0.400391H5.88867V4.13574Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <a href="#" className="upload-img_link">
          Upload logo
        </a>
      </div>
    </div>
  );

  return (
    <div className="add-brand_slide w-slide">
      <h3 className="form-slide-heading">Add company info</h3>
      <Form form={form}>
        <div className="add-brand_tab">
          <div className="add-brand_tab-container">
            <div className="add-brand_tab-content">
              <div className="upload-img-area">
                <Form.Item name="logoUpload">
                  <ImgCrop shape="round">
                    <Upload
                      listType="picture-card"
                      customRequest={customRequest}
                      className="avatar-uploader"
                      showUploadList={false}
                      onChange={handleLogoChange}
                      fileList={logoList}
                    >
                      {logo ? (
                        <div className="upload-img-block">
                          <img
                            src={logo}
                            loading="lazy"
                            alt="logo"
                            className="upload-img-block_img"
                          />
                        </div>
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
                <Form.Item
                  name="logo"
                  className="hiddenField"
                  hidden
                  rules={[
                    {
                      required: false,
                      message: (
                        <div className="form_error-text">
                          <span className="error-icon" />
                          Please provide a brand logo.
                        </div>
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div>
                <div className="form_field-label">Name of the brand</div>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: (
                        <div className="form_error-text">
                          <span className="error-icon" /> Please provide a brand name.
                        </div>
                      ),
                    },
                    {
                      validator: async () => {
                        if (!brand.id || name.toLowerCase() !== brand.name.toLowerCase()) {
                          const nameExists = await checkNameExists(name.toLowerCase());
                          if (nameExists) {
                            return Promise.reject(
                              new Error("This brand name already exists in our database")
                            );
                          }
                          return Promise.resolve();
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter brand name"
                    className="form_field text-dim-italic w-input"
                    maxLength={256}
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </Form.Item>
                <div className="form_field-label">URL</div>
                <Form.Item
                  className="brandDetails-input"
                  name="url"
                  rules={[
                    {
                      required: true,
                      message: (
                        <div className="form_error-text">
                          <span className="error-icon" /> Please provide an url.
                        </div>
                      ),
                    },
                    {
                      type: "url",
                      message: (
                        <div className="form_error-text">
                          <span className="error-icon" />
                          This field must be a valid url and start with https:// or http://.
                        </div>
                      ),
                    },
                    {
                      validator: async () => {
                        if (url && (!brand.id || url.toLowerCase() !== brand.url)) {
                          const urlExists = await checkUrlExists(url.toLowerCase());
                          if (urlExists) {
                            return Promise.reject(
                              new Error("This url already exists in our database")
                            );
                          }

                          const newURL = new URL(url);

                          if (newURL.pathname !== "/") {
                            return Promise.reject(new Error("URLs can't contain paths"));
                          }

                          return Promise.resolve();
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    id="url"
                    placeholder="https://www.example.com"
                    maxLength={256}
                    className="form_field text-dim-italic w-input"
                    onChange={(e) => setUrl(e.target.value.toLowerCase())}
                    value={url}
                  />
                </Form.Item>
                <div className="form_field-label">Short description</div>
                <Form.Item
                  name="shortDescription"
                  rules={[
                    {
                      required: true,
                      message: (
                        <div className="form_error-text">
                          <span className="error-icon" /> Tell us a short description of the brand.
                        </div>
                      ),
                    },
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    maxLength={256}
                    rows={4}
                    placeholder="Briefly describe your brand"
                    className="form_field text-dim-italic w-input shortDescription"
                    onChange={(e) => setShortDescription(e.target.value)}
                    value={shortDescription}
                  />
                </Form.Item>
                <div
                  style={{ cursor: "pointer", display: "inline-block" }}
                  className="btn orange w-button"
                  role="button"
                  tabIndex={0}
                  onClick={() => getDescriptionFromURL()}
                  onKeyDown={() => getDescriptionFromURL()}
                >
                  Get brand description from website
                </div>
              </div>
            </div>
          </div>
          <HelpTipSection
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in
                eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum
                nulla."
            title="Some help text for Brand Details"
          />
        </div>
      </Form>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => onCheckForm()}
        onClick={() => onCheckForm()}
        className="next-btn w-slider-arrow-right"
      >
        <div>Next</div>
      </div>
    </div>
  );
};
