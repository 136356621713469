import React from "react";
import parse from "html-react-parser";

import { shortenNumber } from "../../utils/utils";
import { ReactComponent as ArrowUp } from "../../assets/images/icons/arrow-up-circle.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/icons/arrow-down-circle.svg";

interface AnalyticsProps {
  analytics: any;
}

export const DigitalAnalytics: React.FC<AnalyticsProps> = ({ analytics }: AnalyticsProps) => {
  const getDifference = (actualValue: number, pastValue: number) => {
    const difference = actualValue - pastValue;
    const differenceTotal = (difference * 100) / pastValue;
    return pastValue !== 0 ? differenceTotal : 0;
  };

  const numberValues = [
    {
      name: analytics[0].knownDataMap.adBudget.name,
      actual: analytics[0].knownDataMap.adBudget.value[0],
      last: analytics[1].knownDataMap.adBudget.value[0],
      change: getDifference(
        analytics[0].knownDataMap.adBudget.value[0],
        analytics[1].knownDataMap.adBudget.value[0]
      ),
    },
    {
      name: analytics[0].knownDataMap.backlinks.name,
      actual: analytics[0].knownDataMap.backlinks.value[0],
      last: analytics[1].knownDataMap.backlinks.value[0],
      change: getDifference(
        analytics[0].knownDataMap.backlinks.value[0],
        analytics[1].knownDataMap.backlinks.value[0]
      ),
    },
    {
      name: analytics[0].knownDataMap.organicKeywords.name,
      actual: analytics[0].knownDataMap.organicKeywords.value[0],
      last: analytics[1].knownDataMap.organicKeywords.value[0],
      change: getDifference(
        analytics[0].knownDataMap.organicKeywords.value[0],
        analytics[1].knownDataMap.organicKeywords.value[0]
      ),
    },
  ];

  const textValues = [
    {
      name: analytics[0].knownDataMap.topBacklinkReferrers.name,
      values: analytics[0].knownDataMap.topBacklinkReferrers.value.join(", "),
    },
    {
      name: analytics[0].knownDataMap.topCountries.name,
      values: analytics[0].knownDataMap.topCountries.value.join(", "),
    },
    {
      name: analytics[0].knownDataMap.topReferrers.name,
      values: analytics[0].knownDataMap.topReferrers.value.join(", "),
    },
    {
      name: analytics[0].knownDataMap.topRankedOrganicKeywords.name,
      values: `<ul><li>${analytics[0].knownDataMap.topRankedOrganicKeywords.value.join(
        "</li><li>"
      )}</li></ul>`,
    },
  ];

  return (
    <>
      <table className="analyticsTable" cellPadding={0} cellSpacing={0}>
        <tr>
          <th>&nbsp;</th>
          <th>Actual</th>
          <th>Last</th>
          <th>Change %</th>
        </tr>
        {numberValues.map((value) => (
          <tr>
            <td className="name">{value.name}</td>
            <td>{shortenNumber(value.actual)}</td>
            <td>{shortenNumber(value.last)}</td>
            <td style={{ color: value.change >= 0 ? "#27D076" : "#EE3327" }}>
              {value.change >= 0 ? <ArrowUp /> : <ArrowDown />} {value.change.toFixed(1)}
            </td>
          </tr>
        ))}
      </table>

      <table className="analyticsTable" cellPadding={0} cellSpacing={0}>
        {textValues.map((value) => (
          <tr>
            <td className="name">{value.name}</td>
            <td>{parse(value.values)}</td>
          </tr>
        ))}
      </table>
    </>
  );
};
