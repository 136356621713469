import React from "react";
import { Steps } from "antd";

const { Step } = Steps;

interface StepProps {
  step: number;
  steps: any;
}

const Stepper: React.FC<StepProps> = ({ step, steps }: StepProps) => {
  return (
    <Steps current={step} className="form-steps-indicator-fe">
      {steps.map((item: any) => (
        <Step key={item.title} status={item.status} />
      ))}
    </Steps>
  );
};

export default Stepper;
