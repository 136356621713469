import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Table } from "antd";

import { getBrandBySlug } from "../../API/api";
import { setBrand } from "../../actions/actions";

interface OverviewProps {
  setSelectedBrands: any;
  displayAttributes: any;
  selectedProductService: any;
}

export const Overview: React.FC<OverviewProps> = ({
  setSelectedBrands,
  displayAttributes,
  selectedProductService,
}: OverviewProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToBrand = (brandSlug: string) => {
    getBrandBySlug(brandSlug).then((slugBrand: any) => {
      if (slugBrand === undefined) {
        const notFoundBrand = {
          name: "Not found",
          url: true,
          description: "Search for an specific brand using the search field at the top",
          image: "",
          analytics: {
            webScore: 0,
          },
          competitors: [],
        };
        dispatch(setBrand(notFoundBrand));
      } else {
        dispatch(setBrand(slugBrand));
        navigate(`../brands/${brandSlug}`, { replace: true });
      }
    });
  };

  const dataColumns: any = [
    {
      title: "Brand",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "64px", height: "64px", display: "flex", alignItems: "center" }}>
              {record.logo && (
                <img
                  src={record.logo}
                  alt={record.name}
                  style={{ width: "64px", maxWidth: "64px", maxHeight: "64px" }}
                />
              )}
            </div>
            <button
              type="button"
              onClick={() => goToBrand(record.slug)}
              className="tableNameButton"
            >
              {record.name}
            </button>
          </div>
        );
      },
    },
    {
      title: "Brand score",
      dataIndex: "webScore",
      sorter: (a: any, b: any) => a.webScore - b.webScore,
      align: "center",
    },
  ];

  const [columns, setColumns] = useState(dataColumns) as any;
  const industryBrands = useSelector((state: RootStateOrAny) => state.industryBrands);

  const onPageChange = (current: any) => {
    const newColumns = columns;

    newColumns[0].render = (text: any, record: any, index: number) =>
      (current - 1) * 100 + index + 1;
    setColumns(newColumns);
  };

  const onChangeSelect = (e: any, slug: string) => {
    if (e.target.checked) {
      setSelectedBrands((oldArray: string[]) => [...oldArray, slug]);
    } else {
      setSelectedBrands((oldArray: string[]) => oldArray.filter((item: string) => item !== slug));
    }
  };

  useEffect(() => {
    if (displayAttributes) {
      displayAttributes.forEach((attribute: any) => {
        const attributeColumns = {
          title: attribute.displayName,
          dataIndex: attribute.id,
          align: "center",
        };

        if (attribute.id === "aum") {
          const sorter = {
            sorter: (a: any, b: any) => a.aum - b.aum,
            render: (text: any, record: any) => {
              return new Intl.NumberFormat("en-GB").format(record.aum / 1000000000);
            },
          };

          dataColumns.push({ ...attributeColumns, ...sorter });
        } else if (attribute.id === "esg-brand-score") {
          const sorter = {
            sorter: (a: any, b: any) => a[attribute.id] - b[attribute.id],
            render: (text: any, record: any) => {
              return record[attribute.id];
            },
          };

          if (selectedProductService === "64219d1ef7c4e42c7c1cc555") {
            dataColumns.push({ ...attributeColumns, ...sorter });
          }
        } else {
          dataColumns.push(attributeColumns);
        }
      });
    }

    const selectColumn = {
      title: "Select",
      dataIndex: "name",
      align: "center",
      render: (text: any, record: any) => {
        return (
          <div style={{ textAlign: "center" }}>
            <input type="checkbox" onChange={(e) => onChangeSelect(e, record.slug)} />
          </div>
        );
      },
    };

    dataColumns.push(selectColumn);

    setColumns(dataColumns);
  }, []);

  return (
    <Table
      dataSource={industryBrands}
      columns={columns}
      sortDirections={["descend", "ascend"]}
      pagination={{
        onChange: onPageChange,
        pageSize: 100,
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
    />
  );
};
