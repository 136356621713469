import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Tag, Input, Tree } from "antd";

import { getIndustries, getProducts, getServices } from "../../../../../API/api";

const { DirectoryTree } = Tree;
const { Search } = Input;

interface IndustriesProps {
  data: any;
  setData: any;
}

export const Industries: React.FC<IndustriesProps> = ({ data, setData }: IndustriesProps) => {
  const [activeModal, setActiveModal] = useState(false);
  const [treeData, setTreeData] = useState([] as any);
  const [searchValue, setSearchValue] = useState("");
  const [expandedKeys, setExpandedKeys] = useState([] as any);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [dataList, setDataList] = useState([] as any);

  const reset = () => {
    const resetObject = {
      industries: [],
      industryIds: [],
      products: [],
      productIds: [],
      services: [],
      serviceIds: [],
    };

    setData({ ...data, ...resetObject });
  };

  const removeIndustry = async (industry: any) => {
    const { industries, industryIds, services, serviceIds, products, productIds } = data;
    const industryProducts = await getProducts(industry.id);
    const industryServices = await getServices(industry.id);
    let newServices = services;
    let newServiceIds = serviceIds;
    let newProducts = products;
    let newProductIds = productIds;

    if (services && industryServices) {
      industryServices.forEach((industryService: any) => {
        newServices = services.filter((item: string) => item !== industryService.name);
        newServiceIds = serviceIds.filter((item: string) => item !== industryService.id);
      });
    }

    if (products && industryProducts) {
      industryProducts.forEach((industryProduct: any) => {
        newProducts = products.filter((item: string) => item !== industryProduct.name);
        newProductIds = productIds.filter((item: string) => item !== industryProduct.id);
      });
    }

    const newData = {
      industries: industries.filter((item: string) => item !== industry.displayName),
      industryIds: industryIds.filter((item: string) => item !== industry.id),
      services: newServices,
      serviceIds: newServiceIds,
      products: newProducts,
      productIds: newProductIds,
    };

    setData({ ...data, ...newData });
  };

  useEffect(() => {
    getIndustries().then((response) => {
      const newData: any = [];
      const industryNodes: any = [];
      let list: any = [];

      if (!response) {
        return;
      }

      if (response.content.length > 0) {
        response.content.forEach((industry: any) => {
          const industrySectorData = {
            title: industry.sector.displayName,
            key: industry.sector.id,
            children: [] as any,
            selectable: industry.sector.industryGroups.length === 0,
            isLeaf: industry.sector.industryGroups.length === 0,
          };

          list.push({ key: industry.sector.id, title: industry.sector.displayName });

          if (industry.sector.industryGroups.length > 0) {
            industry.sector.industryGroups.forEach((group: any) => {
              const industryGroupData = {
                title: group.displayName,
                key: group.id,
                children: [] as any,
                selectable: group.industries.length === 0,
                isLeaf: group.industries.length === 0,
              };

              list.push({ key: group.id, title: group.displayName });

              if (group.industries.length > 0) {
                group.industries.forEach((industries: any) => {
                  const industriesData = {
                    title: industries.displayName,
                    key: industries.id,
                    children: [] as any,
                    selectable: industries.subIndustries.length === 0,
                    isLeaf: industries.subIndustries.length === 0,
                  };

                  list.push({ key: industries.id, title: industries.displayName });

                  if (industries.subIndustries.length > 0) {
                    industries.subIndustries.forEach((subindustry: any) => {
                      const subindustryData = {
                        title: subindustry.displayName,
                        key: subindustry.id,
                        children: [] as any,
                        isLeaf: true,
                      };

                      list.push({ key: subindustry.id, title: subindustry.displayName });

                      industriesData.children.push(subindustryData);
                      industryNodes.push(subindustryData);
                    });
                  }

                  industryGroupData.children.push(industriesData);
                });
              }

              industrySectorData.children.push(industryGroupData);
            });
          }

          newData.push(industrySectorData);
        });
      }
      setTreeData([...newData]);

      list = list.filter(
        (value: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.key === value.key && t.title === value.title)
      );

      setDataList([...list]);
    });
  }, []);

  const getParentKey = (key: any, tree: any) => {
    let parentKey: any;
    for (let i = 0; i < tree.length; i += 1) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item: any) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  };

  const onChangeSearch = (e: any) => {
    const { value } = e.target;

    if (value === "") {
      setExpandedKeys([]);
      setAutoExpandParent(false);
    } else {
      const keys = dataList
        .map((item: any) => {
          if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
            return getParentKey(item.key, treeData);
          }
          return null;
        })
        .filter((item: any, i: any, self: any) => item && self.indexOf(item) === i);

      setExpandedKeys(keys);
      setAutoExpandParent(true);
    }

    setSearchValue(value);
  };

  const onSelectIndustryTree = (selectedKey: any, info: any) => {
    const { industries, industryIds } = data;

    // folder
    if (info.node.children.length > 0) {
      return;
    }

    // industry already added
    if (industries.indexOf(info.node.originalTitle) > -1) {
      return;
    }

    industries.push(info.node.originalTitle);
    industryIds.push(info.node.key);

    setData({ ...data, ...{ industries, industryIds } });
  };

  const onExpand = (keys: any) => {
    setExpandedKeys(keys);
    setAutoExpandParent(false);
  };

  const loop = (tree: any) =>
    tree.map((item: any) => {
      const index = item.title.toLowerCase().indexOf(searchValue.toLowerCase());
      const beforeStr = item.title.substr(0, index);
      const afterStr = item.title.substr(index + searchValue.length);
      const itemTitle =
        index > -1 ? (
          <span>
            {beforeStr}
            <span className="site-tree-search-value">{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );

      if (item.children) {
        return {
          title: itemTitle,
          originalTitle: item.title,
          key: item.key,
          children: loop(item.children),
        };
      }

      return {
        title: itemTitle,
        originalTitle: item.title,
        key: item.key,
      };
    });

  return (
    <>
      <div className="form_field-label" style={{ marginTop: "0px" }}>
        Industries
      </div>
      <div className="selected-tags-block">
        {data?.industries?.map((industry: any, index: any) => (
          <Tag
            key={`industryTag${index + 1}`}
            className="selected-tag"
            closable
            onClose={() => removeIndustry({ name: industry, id: data.industryIds[index] })}
          >
            {industry}
          </Tag>
        ))}
      </div>
      <Button className="btn-wrapper mgn-top-0-5em" onClick={() => setActiveModal(true)}>
        <a
          data-w-id="52c1b269-53e8-ac77-1b10-da836d2ac6f7"
          href="#"
          className="btn orange w-inline-block"
        >
          <div>Add industry</div>
          <div className="icon add-category-icon w-embed">
            <svg
              width="14"
              height="14"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.88867 4.13574H9.45703V5.67383H5.88867V9.7168H4.25391V5.67383H0.685547V4.13574H4.25391V0.400391H5.88867V4.13574Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </a>
      </Button>

      <Form.Item
        name="industryIds"
        className="hiddenField"
        hidden
        rules={[
          {
            required: data?.industries?.length === 0,
            message: (
              <div className="form_error-text">
                <span className="error-icon" /> Please select at least one industry
              </div>
            ),
          },
        ]}
      >
        <Input value={data?.industries?.join(",")} />
      </Form.Item>

      <Row>
        <div
          className="blurred-bg industry-popup"
          style={{ display: activeModal ? "flex" : "none" }}
        >
          <div className="popup_c-block">
            <div className="form_field-label btm-mgn-0-5em">Add industry</div>
            <Col>
              <div className="search industry-search w-form">
                <Form.Item required={false} validateTrigger={["onChange", "onBlur"]} noStyle>
                  <div className="selected-tags-block">
                    {data?.industries?.map((industry: any, index: any) => (
                      <Tag
                        key={`industryTag${index + 1}`}
                        className="selected-tag"
                        closable
                        onClose={() =>
                          removeIndustry({ name: industry, id: data.industryIds[index] })
                        }
                      >
                        {industry}
                      </Tag>
                    ))}
                  </div>

                  <Search
                    style={{ marginTop: 10, marginBottom: 10 }}
                    placeholder="Search..."
                    onChange={onChangeSearch}
                    defaultValue={searchValue}
                  />

                  <DirectoryTree
                    onSelect={onSelectIndustryTree}
                    treeData={loop(treeData)}
                    autoExpandParent={autoExpandParent}
                    expandedKeys={expandedKeys}
                    onExpand={onExpand}
                  />
                </Form.Item>
              </div>
            </Col>

            <div className="reset-block">
              <div
                className="reset-suggestions cursor-pointer"
                role="button"
                tabIndex={0}
                onClick={reset}
                onKeyDown={reset}
              >
                Reset
              </div>
              <div
                className="btn orange w-button cursor-pointer"
                role="button"
                tabIndex={0}
                onClick={() => setActiveModal(false)}
                onKeyDown={() => setActiveModal(false)}
              >
                Ok
              </div>
            </div>
            <div
              role="button"
              tabIndex={0}
              data-w-id="88dbabb2-e4f5-b6cd-2422-cd4d8e59c2d6"
              className="modal-close"
              onClick={() => setActiveModal(false)}
              onKeyDown={() => setActiveModal(false)}
            >
              <div className="icon w-embed">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.75736 4.75736L13.2426 13.2426"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.0729 4.58765L4.58765 13.0729"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};
