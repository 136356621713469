import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";
import { Avatar, Select } from "antd";

import Search from "../v1/Search/Search";
import SearchTaxonomy from "../v1/Search/SearchTaxonomy";
import { ReactComponent as SearchIcon } from "../../webflow/images/search-icon.svg";
import { ReactComponent as SGLogo } from "../../webflow/images/SG-Logo.svg";
import { ReactComponent as UserProfileIcon } from "../../assets/images/icons/userProfile.svg";
import { ReactComponent as HelpIcon } from "../../assets/images/icons/help.svg";
import { ReactComponent as LogOutIcon } from "../../assets/images/icons/logout.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/icons/user-arrow-down.svg";

const Navbar = () => {
  const brand = useSelector((state: RootStateOrAny) => state.brand);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const [mobileScreen, setMobileScreen] = useState<number>(window.innerWidth);
  const [showForm, setShowForm] = useState(false);
  const isIndustryPage = window.location.pathname.includes("/industries/");
  const [selectedOption, setSelectedOption] = useState(isIndustryPage ? "industries" : "brands");

  const handleWindowSizeChange = () => {
    setMobileScreen(window.innerWidth);
  };

  const userInitials = user.name
    .split(" ")
    .map((n: string) => n[0])
    .join("");

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleChange = (value: string) => {
    setSelectedOption(value);
  };

  return (
    <div className="dashboard-nav">
      <NavLink
        to="/search"
        className="brand-logo w-inline-block mb-only"
        style={{ margin: "0 auto 0 0" }}
      >
        <SGLogo />
      </NavLink>
      <div className="nav-right">
        <div
          data-w-id="6b5dbb45-6144-c332-9e0d-4f281397b64b"
          className="search-block"
          style={{ width: "100%" }}
        >
          <SearchIcon className="search-block_icon" onClick={() => setShowForm(!showForm)} />
          {((mobileScreen <= 991 && showForm) || mobileScreen > 991) && (
            <div style={{ display: "flex", width: "100%" }}>
              {selectedOption === "brands" ? (
                <form action="/search" className="search-bar w-form" style={{ flex: 1 }}>
                  <div className="search-wrapper">
                    <Search
                      id="search"
                      webflowStyle="search-input pad w-input"
                      placeholder="Enter brand name"
                      excludes={brand ? [brand.id] : undefined}
                    >
                      <SearchIcon className="search-icon" />
                    </Search>
                  </div>
                </form>
              ) : (
                <SearchTaxonomy />
              )}
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                defaultValue={selectedOption}
                className="search-type-selector"
                onChange={handleChange}
                options={[
                  {
                    value: "brands",
                    label: "Brands",
                  },
                  {
                    value: "industries",
                    label: "Industries",
                  },
                ]}
              />
            </div>
          )}
        </div>

        <div className="user">
          {(user.image && user.image) !== "" ? (
            <img src={user.image} loading="lazy" alt={user.name} className="profile-img" />
          ) : (
            <Avatar className="profile-img" size={45}>
              {userInitials}
            </Avatar>
          )}
          <div className="user-details">
            <span className="user_name">
              {user.name}
              <br />
            </span>
            <span className="user_company">{user.brand}</span>
          </div>
          <div className="user_dropdown">
            <div className="user_dropdown_wrapper">
              <a href="#" className="user_dropdown-link w-inline-block">
                <div className="icon rt-mgn-0-5em w-embed">
                  <UserProfileIcon />
                </div>
                <div>Edit Profile</div>
              </a>
              <a href="#" className="user_dropdown-link w-inline-block">
                <div className="icon rt-mgn-0-5em w-embed">
                  <HelpIcon />
                </div>
                <div>Help</div>
              </a>
              <NavLink to="/logout" className="user_dropdown-link w-inline-block">
                <div className="icon rt-mgn-0-5em w-embed">
                  <LogOutIcon />
                </div>
                <div>Log out</div>
              </NavLink>
              <div className="user_dd-notch w-embed">
                <ArrowDownIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
