export const normalizedCompetitors = (brand: any, competitors: any) => {
  const locationIds = brand?.locations?.map((loc: any) => loc.id) || [];
  const industryIds = brand?.industries?.map((ind: any) => ind.id) || [];
  const serviceIds = brand?.services?.map((svc: any) => svc.id) || [];
  const productIds = brand?.products?.map((prod: any) => prod.id) || [];

  return competitors.map((comp: any) => {
    let productsFiltered = [] as any;
    let servicesFiltered = [] as any;
    let locationsFiltered = [] as any;
    let industriesFiltered = [] as any;

    if (comp.products?.length) {
      productsFiltered = comp.products.reduce((filtered: any, option: any) => {
        productIds?.forEach((id: string) => {
          if (option.id === id) {
            filtered.push(option);
          }
        });

        return filtered;
      }, []);
    }
    if (comp.industries?.length) {
      industriesFiltered = comp.industries.reduce((filtered: any, option: any) => {
        industryIds?.forEach((id: string) => {
          if (option.id === id) {
            filtered.push(option);
          }
        });

        return filtered;
      }, []);
    }
    if (comp.locations?.length) {
      locationsFiltered = comp.locations.reduce((filtered: any, option: any) => {
        locationIds?.forEach((id: string) => {
          if (option.id === id) {
            filtered.push(option);
          }
        });

        return filtered;
      }, []);
    }
    if (comp.services?.length) {
      servicesFiltered = comp.services.reduce((filtered: any, option: any) => {
        serviceIds?.forEach((id: string) => {
          if (option.id === id) {
            filtered.push(option);
          }
        });

        return filtered;
      }, []);
    }

    return {
      brandId: comp.id,
      name: comp.name,
      industryIds: industriesFiltered.filter(
        (value: any, i: number, self: any) =>
          i === self.findIndex((t: any) => t.place === value.place && t.name === value.name)
      ),
      locationIds: locationsFiltered.filter(
        (value: any, i: number, self: any) =>
          i === self.findIndex((t: any) => t.place === value.place && t.name === value.name)
      ),
      serviceIds: servicesFiltered.filter(
        (value: any, i: number, self: any) =>
          i === self.findIndex((t: any) => t.place === value.place && t.name === value.name)
      ),
      productIds: productsFiltered.filter(
        (value: any, i: number, self: any) =>
          i === self.findIndex((t: any) => t.place === value.place && t.name === value.name)
      ),
    };
  });
};
