import React, { useEffect } from "react";

import { PUBLIC_API_KEY } from "../API/apiConstants";
import { store } from "../store";
import placeHolderImg from "../assets/images/placeholder_image.png";

export const buildURLQuery = (params: any, size?: number) => {
  if (params) {
    let str = "?";
    let separator = "";
    Object.keys(params).forEach((key) => {
      str = `${str}${separator}${key}=${params[key]}`;
      if (separator === "") {
        separator = "&";
      }
    });
    if (size) {
      str = `${str}&size=${size}`;
    }
    return str;
  }
  return "";
};

declare global {
  interface Window {
    Webflow?: any;
  }
}

export const jsReload = (location: Location) => {
  useEffect(() => {
    if (window) {
      window.Webflow.destroy();
      window.Webflow.ready();
    }
  }, [location.pathname]);
};

export const apiConfig = () => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;
  const API_CONFIG = {
    headers: {
      "X-LOOMI-KEY": API_KEY,
      "X-API-VERSION": API_VERSION,
    },
  };
  return API_CONFIG;
};

export const anyMmatch = (text: any, query: any) => {
  if (!query || !text) {
    return [];
  }
  const results = [];
  const trimmedQuery = query.trim().toLowerCase();
  const textLower = text.toLowerCase();
  const queryLength = trimmedQuery.length;
  let indexOf = textLower.indexOf(trimmedQuery);

  while (indexOf > -1) {
    results.push([indexOf, indexOf + queryLength]);
    indexOf = textLower.indexOf(query, indexOf + queryLength);
  }

  return results;
};

export const formatDate = (date: any) => {
  const formattedDate = new Date(date);

  return formattedDate.toLocaleDateString();
};

export const getImageURL = (imageURL: string, image: string | undefined) => {
  const imageExists = imageURL !== null && imageURL !== undefined && imageURL !== "";

  if (imageExists) {
    const dotIndex = imageURL.lastIndexOf(".");
    const hasExtension = imageURL.substring(dotIndex).length === 4;

    if (hasExtension) {
      return imageURL;
    }
  }

  return image ?? placeHolderImg;
};

export const shortenNumber = (number: any) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 1,
    notation: "compact",
    compactDisplay: "short",
  }).format(number);
};

export const getKeyValue = (analytics: any, key: string) => {
  const object = analytics.find((data: any) => data.id === key);
  return object ? object.value : 0;
};

export const getKeyValueInString = (analytics: any, key: string) => {
  const object = analytics.find((data: any) => data.id === key);
  return object || "N/A";
};

export const getFilteredCompetitors = (brand: any, filters: any) => {
  let competitors = [...brand.competitors];

  if (filters.selectedIndustry) {
    competitors = competitors.filter((competitor: any) =>
      competitor?.industries?.filter(
        (industry: any) => industry.displayName === filters.selectedIndustry
      )
    );
  }

  if (filters.selectedProductService) {
    const competitorsProducts = competitors.filter((competitor: any) =>
      competitor?.products?.filter(
        (product: any) => product.name === filters.selectedProductService
      )
    );
    const competitorsServices = competitors.filter((competitor: any) =>
      competitor?.services?.filter(
        (service: any) => service.name === filters.selectedProductService
      )
    );

    competitors = [...competitorsProducts, ...competitorsServices];
  }

  if (filters.selectedLocation) {
    competitors = competitors.filter((competitor: any) =>
      competitor?.locations?.filter((location: any) => location.name === filters.selectedLocation)
    );
  }

  if (filters.typeOfCompetitor) {
    const filterTypeOfCompetitor = (type: string) => {
      competitors =
        brand[type] && brand.competitors?.length > 0
          ? brand[type].filter((elem: any) =>
              brand.competitors.find(({ id }: any) => elem.id === id)
            )
          : competitors;
    };

    switch (filters.typeOfCompetitor) {
      case "Challengers":
        filterTypeOfCompetitor("challengerCompetitors");
        break;
      case "Similar size":
        filterTypeOfCompetitor("similarSizeCompetitors");
        break;
      case "Market Leaders":
        filterTypeOfCompetitor("establishedCompetitors");
        break;
      default:
        filterTypeOfCompetitor("competitors");
        break;
    }
  }

  return competitors;
};

export const getSocialFollowers = (brandAnalytics: any) => {
  let followers = 0;

  if (brandAnalytics?.social?.length > 0) {
    brandAnalytics.social.forEach((network: any) => {
      network.value.forEach((val: any) => {
        if (val.followers) {
          followers += val.followers;
        }
      });
    });
  }

  return [followers];
};

export const brandOptions = {
  populateCompetitors: true,
  populateIndustries: true,
  populateProducts: true,
  populateServices: true,
  populateLocations: true,
};

export const setOptions = (data: any, onClick: any, id: boolean = false) => {
  const options = [] as any;
  if (data && data.length > 0) {
    data.forEach((element: any) =>
      options.push(
        <a
          href="#"
          key={element.id}
          className="category-dropdown_link w-dropdown-link"
          onClick={() => onClick(id ? element.id : element.name)}
        >
          {element.name}
        </a>
      )
    );
  } else {
    options.push(
      <a href="#" className="category-dropdown_link w-dropdown-link">
        No data
      </a>
    );
  }
  return options;
};

const hideLists = () => {
  const lists = document.querySelectorAll(".w-dropdown-list");

  if (lists.length) {
    lists.forEach((list: any) => {
      // eslint-disable-next-line no-param-reassign
      list.style.display = "none";
    });
  }
};

export const toggleDropdown = (dropdown: any) => {
  const list = dropdown.closest(".w-dropdown").querySelector(".w-dropdown-list");

  if (list.style.display === "block") {
    list.style.display = "none";
  } else {
    hideLists();
    list.style.display = "block";
  }
};

export const handleDropdownClick = (e: any) => {
  if (e.target.classList.contains("category-dropdown_link")) {
    toggleDropdown(e.target);
  }
};

export const emptyBrand = {
  name: "",
  description: "",
  image: "",
  analytics: {
    webScore: 0,
  },
  competitors: [],
  competitorIds: [],
  locations: [],
  locationIds: [],
  industries: [],
  industryIds: [],
  services: [],
  products: [],
};

export const isInViewport = (el: any) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
