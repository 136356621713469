import React from "react";

import { SignUpForm } from "../components/Forms/SignUp/SignUpForm";
import { Footer } from "../components/Footer/Footer";
import { ReactComponent as Logo } from "../webflow/images/Small_Giants_logo_orange.svg";

const SignUp: React.FC = () => {
  return (
    <div className="section welcome wf-section">
      <div className="search-box_container">
        <Logo className="smallgiants-logo_small" />
        <h1 className="search-box_heading">BRAND INDEX</h1>
        <div className="form-block w-form">
          <SignUpForm />
        </div>
        <a
          href="https://www.smallgiants.agency/"
          target="_blank"
          rel="noreferrer"
          className="link-sg-website"
        >
          www.smallgiants.agency
        </a>
      </div>
      <Footer />
    </div>
  );
};
export default SignUp;
