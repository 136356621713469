export interface BrandDto {
  id?: string;
  name: string;
  url?: string;
  type?: string;
  aliases?: string[];
  products?: [ProductsServices];
  services?: [ProductsServices];
  description?: string;
  shortDescription?: string;
  summary?: {
    strengths: string;
    weaknesses: string;
    opportunities: string;
    threats: string;
  };
  analytics?: {
    webScore: number;
    bounceRate: number;
    pagesPerSession: number;
    clickthroughRate?: string;
    exitRateAndTopExitPages?: string;
    returningVisitors?: string;
    monthlyVisitors: number;
    averageSessionDuration?: number;
    backlinks: number;
    keywords: number;
    adBudget: number;
  };
  competitors?: [BrandDto];
  competitorIds?: string[];
  logo?: string;
  image?: string;
  source?: string;
  slug?: string;
  createdDate?: Date;
  lastModifiedDate?: Date;
}

export interface ProductsServices {
  id: string;
  name: string;
  parentId: string;
  type: string;
}
export class Brand {
  id?: string;

  name: string;

  url?: string;

  type?: string;

  slug?: string;

  products?: [ProductsServices];

  summary?: {
    strengths: string;
    weaknesses: string;
    opportunities: string;
    threats: string;
  };

  services?: [ProductsServices];

  aliases?: string[];

  description?: string;

  shortDescription?: string;

  analytics?: {
    webScore: number;
    bounceRate: number;
    pagesPerSession: number;
    clickthroughRate?: string;
    exitRateAndTopExitPages?: string;
    returningVisitors?: string;
    averageSessionDuration?: number;
    monthlyVisitors: number;
    backlinks: number;
    keywords: number;
    adBudget: number;
  };

  competitors?: [BrandDto];

  competitorIds?: string[];

  logo?: string;

  image?: string;

  source?: string;

  createdDate?: Date;

  lastModifiedDate?: Date;

  constructor(sel: BrandDto) {
    this.name = sel.name;
    this.aliases = sel.aliases;
    this.description = sel.description;
    this.shortDescription = sel.shortDescription;
    this.id = sel.id;
    this.products = sel.products;
    this.services = sel.services;
    this.competitors = sel.competitors;
    this.summary = sel.summary;
    this.competitors = sel.competitors;
    this.competitorIds = sel.competitorIds;
    this.url = sel.url;
    this.source = sel.source;
    this.analytics = sel.analytics;
    this.logo = sel.logo;
    this.image = sel.image;
    this.slug = sel.slug;
    this.createdDate = sel.createdDate;
    this.lastModifiedDate = sel.lastModifiedDate;
  }

  hasInput(value: string) {
    return this.name.toLowerCase().includes(value.trim().toLowerCase());
  }

  startWithInput(value: string) {
    return this.name.toLowerCase().startsWith(value.trim().toLowerCase());
  }
}
