import React from "react";
import "antd/dist/antd.css";

import { BrandForm } from "../../components/Forms/Brand/BrandForm";
import { ReactComponent as SgLogo } from "../../webflow/images/SG-Logo.svg";

const AddBrand: React.FC = () => {
  return (
    <React.Fragment>
      <div className="dashboard-nav simple">
        <div className="flex al-center">
          <a href="/search" className="brand-logo small w-inline-block">
            <SgLogo title="logo smallGiants" />
          </a>
        </div>
      </div>
      <div className="section add-brand-section wf-section">
        <main className="main-panel add-brand-container">
          <div className="info-block_header left-mgn-2em">
            <div className="company-header">
              <h2 className="brand-name">Add your brand&apos;s details</h2>
            </div>
          </div>
          <BrandForm />
        </main>
      </div>
    </React.Fragment>
  );
};

export default AddBrand;
