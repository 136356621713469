import React, { useState, useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Modal, Button } from "antd";

import { SearchBar } from "../../components/SearchBar/SearchBar";
import { Navigation } from "../../components/Navigation/Navigation";
import Navbar from "../../components/Navbar/Navbar";
import { Table } from "../../components/Table/Table";
import { getKeyValue, getSocialFollowers, toggleDropdown } from "../../utils/utils";
import { exportBrand } from "../../API/api";
import {
  getArrayMax,
  getArrayMin,
  setOptions,
  filterCompetitors,
  getColumns,
  sortCompetitors,
} from "./DigitalCompetitiveAnalysis.utils";

const DigitalCompetitiveAnalysis: React.FC = () => {
  const brand = useSelector((state: RootStateOrAny) => state.brand);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedProductService, setSelectedProductService] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [productsAndServices, setProductsAndServices] = useState([]) as any;
  const [productsAndServicesTitle, setProductsAndServicesTitle] = useState("Products and services");
  const [rows, setRows] = useState([]) as any;
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [exportLink, setExportLink] = useState("");
  const [sorting, setSorting] = useState({ name: "webScore", direction: "desc" });
  const [bestScoreSelectors, setBestScoreSelectors] = useState({}) as any;
  const [competitors, setCompetitors] = useState([]) as any;

  const rowBrand = [
    {
      id: brand.id,
      name: brand.name,
      analytics: brand.analytics,
      selected: true,
    },
  ];

  useEffect(() => {
    if (brand.id) {
      let competitorsData = brand.competitors;

      if (selectedIndustry || selectedProductService || selectedLocation) {
        competitorsData = filterCompetitors(
          brand,
          selectedIndustry,
          selectedProductService,
          selectedLocation
        );
        setCompetitors(competitorsData);
      } else {
        setCompetitors(brand.competitors);
      }

      const rowsCompetitors = competitorsData
        ? competitorsData.map((comp: any) => {
            return {
              id: comp.id,
              name: comp.name,
              analytics: comp.analytics,
            };
          })
        : [];

      const selectors = {
        organic: ["followers", "backlinks", "organicKeywords"],
        general: ["monthlyVisitors", "bounceRate", "webScore"],
        paid: ["adBudget"],
      } as any;

      const bestScores = {} as any;

      Object.keys(selectors).forEach((type: string) => {
        selectors[type].forEach((selector: string) => {
          if (selector === "bounceRate") {
            bestScores[selector] = getArrayMin(
              [...rowBrand, ...rowsCompetitors].map((comp: any) => {
                return (
                  comp.analytics &&
                  comp.analytics[0] &&
                  comp.analytics[0][type] &&
                  getKeyValue(comp.analytics[0][type], selector)
                );
              })
            );
          } else {
            bestScores[selector] = getArrayMax(
              [...rowBrand, ...rowsCompetitors].map((comp: any) => {
                return (
                  comp.analytics &&
                  comp.analytics[0] &&
                  comp.analytics[0][type] &&
                  getKeyValue(comp.analytics[0][type], selector)
                );
              })
            );
          }
        });
      });

      setBestScoreSelectors(bestScores);

      setRows(
        [...rowBrand, ...rowsCompetitors].map((comp: any) => {
          return {
            id: comp.id,
            name: comp.name,
            topTrafficSources: comp?.analytics[0]?.organic
              ? getKeyValue(comp.analytics[0].organic, "topTrafficSources")
              : 0,
            monthlyVisits: comp?.analytics[0]?.general
              ? getKeyValue(comp.analytics[0].general, "monthlyVisitors")
              : 0,
            bounceRate: comp?.analytics[0]?.general
              ? getKeyValue(comp.analytics[0].general, "bounceRate")
              : 0,
            backlinks: comp?.analytics[0]?.organic
              ? getKeyValue(comp.analytics[0].organic, "backlinks")
              : 0,
            adBudget: comp?.analytics[0]?.paid
              ? getKeyValue(comp.analytics[0].paid, "adBudget")
              : 0,
            keywords: comp?.analytics[0]?.organic
              ? getKeyValue(comp.analytics[0].organic, "organicKeywords")
              : 0,
            webScore: comp?.analytics[0]?.general
              ? getKeyValue(comp.analytics[0].general, "webScore")
              : 0,
            followers: comp?.analytics[0]?.social ? getSocialFollowers(comp.analytics[0]) : 0,
            selected: comp.selected,
          };
        })
      );
    }
    if (brand.products && brand.products.length && brand.services && brand.services.length) {
      setProductsAndServices({
        products: brand.products,
        services: brand.services,
      });
      setProductsAndServicesTitle("Products and services");
    } else if (
      brand.products &&
      brand.products.length &&
      (!brand.services || !brand.services.length)
    ) {
      setProductsAndServices({
        products: brand.products,
        services: [],
      });
      setProductsAndServicesTitle("Products");
    } else if (
      (!brand.products || !brand.products.length) &&
      brand.services &&
      brand.services.length
    ) {
      setProductsAndServices({
        products: [],
        services: brand.services,
      });
      setProductsAndServicesTitle("Services");
    } else {
      setProductsAndServices({
        products: [],
        services: [],
      });
      setProductsAndServicesTitle("Products and services");
    }
  }, [brand, selectedIndustry, selectedProductService, selectedLocation]);

  const conditionalRowStyles = [
    {
      when: (row: any) => row.selected === true,
      style: {
        border: 0,
        borderRadius: "5px",
        backgroundColor: "#ccc",
        color: "white !important",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#ccc",
        },
      },
    },
  ];

  const onSort = (selectedColumn: any, sortDirection: any) => {
    setSorting({ name: selectedColumn.selector, direction: sortDirection });
  };

  const getExport = (exportType: string) => {
    setIsExportModalVisible(true);
    setExportLoading(true);
    setExportLink("");

    const competitorIds = sortCompetitors(competitors, sorting);

    const data = {
      location: "competitors",
      competitorIds,
      exportType,
    };

    exportBrand(data, brand.id).then((response: any) => {
      if (exportType === "googleDocs") {
        setExportLink(response);
      }

      setExportLoading(false);
    });
  };

  useEffect(() => {
    if (brand.products && brand.products.length && brand.services && brand.services.length) {
      setProductsAndServices({
        products: brand.products,
        services: brand.services,
      });
      setProductsAndServicesTitle("Products and services");
    } else if (
      brand.products &&
      brand.products.length &&
      (!brand.services || !brand.services.length)
    ) {
      setProductsAndServices({
        products: brand.products,
        services: [],
      });
      setProductsAndServicesTitle("Products");
    } else if (
      (!brand.products || !brand.products.length) &&
      brand.services &&
      brand.services.length
    ) {
      setProductsAndServices({
        products: [],
        services: brand.services,
      });
      setProductsAndServicesTitle("Services");
    } else {
      setProductsAndServices({
        products: [],
        services: [],
      });
      setProductsAndServicesTitle("Products and services");
    }
  }, [brand]);

  return (
    <React.Fragment>
      <Navbar />
      <div className="section analysis">
        <Navigation />
        <main className="main-panel">
          <SearchBar />
          <div className="card categories-bar">
            <h1 className="brand-name orange btm-0">{brand.name}</h1>
            <div className="category-dropdown-wrap">
              <div data-hover="" data-delay="0" className="category-dropdown rt-mgn w-dropdown">
                <div
                  className="category-dropdown_toggle w-dropdown-toggle"
                  onClick={(e) => toggleDropdown(e.target)}
                  onKeyDown={(e) => toggleDropdown(e.target)}
                  tabIndex={0}
                  role="button"
                >
                  <div className="w-icon-dropdown-toggle" />
                  <a
                    style={{ textDecoration: "none", color: "#222222" }}
                    href="#"
                    onClick={() => setSelectedIndustry("")}
                    title={selectedIndustry !== "" ? selectedIndustry : "Industries"}
                  >
                    {selectedIndustry !== "" ? selectedIndustry : "Industries"}
                  </a>
                </div>
                <nav className="category-dropdown_list w-dropdown-list">
                  {brand.industries ? (
                    brand.industries.map((industry: any) => (
                      <a
                        href="#"
                        key={industry.displayName}
                        onClick={() => setSelectedIndustry(industry.displayName)}
                        className="category-dropdown_link w-dropdown-link"
                      >
                        {industry.displayName}
                      </a>
                    ))
                  ) : (
                    <a href="#" key="none" className="category-dropdown_link w-dropdown-link">
                      No industries
                    </a>
                  )}
                </nav>
              </div>
              <div data-hover="" data-delay="0" className="category-dropdown rt-mgn w-dropdown">
                <div
                  className="category-dropdown_toggle w-dropdown-toggle"
                  onClick={(e) => toggleDropdown(e.target)}
                  onKeyDown={(e) => toggleDropdown(e.target)}
                  tabIndex={0}
                  role="button"
                >
                  <div className="w-icon-dropdown-toggle" />
                  <a
                    style={{ textDecoration: "none", color: "#222222" }}
                    href="#"
                    onClick={() => setSelectedProductService("")}
                    title={
                      selectedProductService !== ""
                        ? selectedProductService
                        : productsAndServicesTitle
                    }
                  >
                    {selectedProductService !== ""
                      ? selectedProductService
                      : productsAndServicesTitle}
                  </a>
                </div>
                <nav className="category-dropdown_list w-dropdown-list">
                  {productsAndServices?.products?.length > 0 && (
                    <>
                      <div className="dropdown-category">Products</div>
                      {setOptions(productsAndServices.products, setSelectedProductService)}
                    </>
                  )}
                  {productsAndServices?.services?.length > 0 && (
                    <>
                      <div className="dropdown-category">Services</div>
                      {setOptions(productsAndServices.services, setSelectedProductService)}
                    </>
                  )}
                  {productsAndServices.services &&
                    !productsAndServices.services.length &&
                    productsAndServices.products &&
                    !productsAndServices.products.length && (
                      <a href="#" key="none" className="category-dropdown_link w-dropdown-link">
                        No products or services
                      </a>
                    )}
                </nav>
              </div>
              <div data-hover="" data-delay="0" className="category-dropdown w-dropdown">
                <div
                  className="category-dropdown_toggle w-dropdown-toggle"
                  onClick={(e) => toggleDropdown(e.target)}
                  onKeyDown={(e) => toggleDropdown(e.target)}
                  tabIndex={0}
                  role="button"
                >
                  <div className="w-icon-dropdown-toggle" />
                  <a
                    style={{ textDecoration: "none", color: "#222222" }}
                    href="#"
                    onClick={() => setSelectedLocation("")}
                    title={selectedLocation !== "" ? selectedLocation : "Locations"}
                  >
                    {selectedLocation !== "" ? selectedLocation : "Locations"}
                  </a>
                </div>
                <nav className="category-dropdown_list w-dropdown-list">
                  {brand.locations ? (
                    brand.locations.map((location: any) => (
                      <a
                        href="#"
                        key={location.name}
                        onClick={() => setSelectedLocation(location.name)}
                        className="category-dropdown_link w-dropdown-link"
                      >
                        {location.name}
                      </a>
                    ))
                  ) : (
                    <a href="#" key="none" className="category-dropdown_link w-dropdown-link">
                      No locations
                    </a>
                  )}
                </nav>
              </div>
            </div>
          </div>

          <div className="separator" />

          <section className="card mb-cols">
            <h2 className="brand-name no-mgn">Digital Competitive Analysis</h2>
            <div data-hover="" data-delay="0" className="category-dropdown w-dropdown">
              <div
                className="category-dropdown_toggle orange w-dropdown-toggle"
                onClick={(e) => toggleDropdown(e.target)}
                onKeyDown={(e) => toggleDropdown(e.target)}
                tabIndex={0}
                role="button"
              >
                <div className="w-icon-dropdown-toggle" style={{ color: "#fff" }} />
                <a style={{ textDecoration: "none", color: "#fff" }} href="#">
                  Export
                </a>
              </div>
              <nav className="category-dropdown_list w-dropdown-list">
                <a
                  href="#"
                  onClick={() => getExport("googleDocs")}
                  className="category-dropdown_link w-dropdown-link"
                >
                  Google Slides
                </a>
                <a
                  href="#"
                  onClick={() => getExport("pdf")}
                  className="category-dropdown_link w-dropdown-link"
                >
                  PDF
                </a>
                <a
                  href="#"
                  onClick={() => getExport("png")}
                  className="category-dropdown_link w-dropdown-link"
                >
                  Images
                </a>
              </nav>
              <Modal
                className="exportModal"
                visible={isExportModalVisible}
                confirmLoading={exportLoading}
                closable={false}
                onOk={() => setIsExportModalVisible(false)}
                footer={[
                  <Button
                    type="primary"
                    key="ok"
                    onClick={() => setIsExportModalVisible(false)}
                    loading={exportLoading}
                  >
                    Ok
                  </Button>,
                ]}
              >
                {exportLink ? (
                  <>
                    <p>Here is your exported file:</p>
                    <p>
                      <a href={exportLink} target="_blank" rel="noreferrer">
                        {exportLink}
                      </a>
                    </p>
                  </>
                ) : (
                  <p>Your export file is being processed, please wait.</p>
                )}
              </Modal>
            </div>
          </section>
          <section className="card competitive-analysis">
            <Table
              columns={getColumns(bestScoreSelectors, rows)}
              data={rows}
              config={{
                pagination: true,
                defaultSortField: "webScore",
                defaultSortAsc: false,
                conditionalRowStyles,
                noDataComponent: "There is no data available...",
                onSort,
              }}
            />
          </section>
        </main>
      </div>
    </React.Fragment>
  );
};

export default DigitalCompetitiveAnalysis;
