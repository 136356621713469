import React from "react";
import { Link, useLocation } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";

import { ReactComponent as SuccessIcon } from "../../webflow/images/success-checkmark-icon.svg";
import { ReactComponent as ErrorIcon } from "../../webflow/images/warning-1.svg";

const Success: React.FC = () => {
  const brand = useSelector((state: RootStateOrAny) => state.brand);
  const location = useLocation() as any;
  const { success, from, brandSlug } = location.state;
  const isBrandOffline = brand?.metadata?.status === "OFFLINE";
  let homeLink = brandSlug ? `/brands/${brandSlug}` : "/search";

  // we are editing a non-approved brand
  if (isBrandOffline) {
    homeLink = "/brands/review";
  }

  return (
    <React.Fragment>
      <div className="main-panel add-brand-success-container">
        <div className="add-brand-success">
          {success ? (
            <>
              <SuccessIcon className="success-icon" />
              <div className="brand-name green">Brand details sent!</div>
              <>
                {from === "form" ? (
                  <p>
                    Our administrators are validating your information and will be getting back to
                    you within a few hours.
                    <br />
                    <br />
                    Thanks in advance for your patience.
                  </p>
                ) : (
                  <p>
                    Review finished with success.
                    <br />
                    <br />
                    Thanks in advance for your patience.
                  </p>
                )}
              </>
            </>
          ) : (
            <>
              <ErrorIcon className="" />
              <div className="brand-name red">Error!</div>
              <p>
                Sorry, try again.
                <br />
                <br />
                Thanks in advance for your patience.
              </p>
            </>
          )}
          {from === "review" ? (
            <div style={{ display: "flex" }}>
              <Link
                to="/brands/review"
                className="btn orange top-mgn-20 w-button"
                style={{ marginRight: 10 }}
              >
                Back to review
              </Link>
              <Link to={homeLink} className="btn orange top-mgn-20 w-button">
                Back to Home
              </Link>
            </div>
          ) : (
            <Link to={homeLink} className="btn orange top-mgn-20 w-button">
              Back to Home
            </Link>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Success;
