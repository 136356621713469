import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";

import Navbar from "../components/Navbar/Navbar";
import { Navigation } from "../components/Navigation/Navigation";
import { setVersion } from "../actions/actions";
import { SearchBar } from "../components/SearchBar/SearchBar";

const Settings: React.FC = () => {
  const version = useSelector((state: RootStateOrAny) => state.apiVersion);
  const dispatch = useDispatch();

  const handleVersion = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setVersion(`${event.target.value}`));
  };

  return (
    <React.Fragment>
      <Navbar />
      <div className="section analysis">
        <Navigation />
        <main className="main-panel settings-page">
          <SearchBar />
          <section className="card">
            <h2 className="brand-name no-mgn">Settings</h2>
          </section>
          <section className="brand-card">
            <div className="brand-card_info">
              <p className="info-block_desc">Choose the Api version</p>
            </div>
            <div className="screenshot-block">
              <select className="version-dropdown" onChange={handleVersion} value={version}>
                <option value="1">Version 1</option>
                <option value="2">Version 2</option>
              </select>
            </div>
          </section>
        </main>
      </div>
    </React.Fragment>
  );
};

export default Settings;
