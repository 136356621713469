import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { message, Select } from "antd";

import {
  getBrandsByIndustry,
  getBrandBySlug,
  getProducts,
  getServices,
  getRegions,
} from "../API/api";
import { setBrand, setCompetitor, setIndustryBrands } from "../actions/actions";
import { ESG } from "../components/Homepage/ESG";
import { DigitalMarketing } from "../components/Homepage/DigitalMarketing";
import { Overview } from "../components/Homepage/Overview";
import { isInViewport } from "../utils/utils";

export const HomePage: React.FC = () => {
  const [showSticky, setShowSticky] = useState(false);
  const [displayAttributes, setDisplayAttributes] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]) as any;
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [selectedProductService, setSelectedProductService] = useState("");
  const [productsServices, setProductsServices] = useState([]);
  const [products, setProducts] = useState([]) as any;
  const [services, setServices] = useState([]) as any;
  const [locations, setLocations] = useState([]) as any;
  const [selectedLocation, setSelectedLocation] = useState("");
  const [activeTab, setActiveTab] = useState("Overview");
  const industry = useSelector((state: RootStateOrAny) => state.industry);
  const industryBrands = useSelector((state: RootStateOrAny) => state.industryBrands);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let productsAndServices: any = [];

    getProducts(industry.id).then((response: any) => {
      setProducts(response);
      productsAndServices = response;

      getServices(industry.id).then((sresponse: any) => {
        const serv = sresponse.filter((s: any) => s.name !== "ESG");

        setServices(serv);
        productsAndServices = [...productsAndServices, ...serv].sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        );
        setProductsServices(productsAndServices);
      });
    });

    getRegions().then((response: any) => {
      const regions = response.content.sort((a: any, b: any) => a.name.localeCompare(b.name));
      setLocations(regions);
    });

    const filters = {
      productIds: selectedProduct ? [selectedProduct] : [],
      serviceIds: selectedService ? [selectedService] : [],
      locationIds: selectedLocation ? [selectedLocation] : [],
    };

    getBrandsByIndustry(industry.slug, filters)
      .then((response) => {
        const { content } = response.industryBrands;

        if (response.displayAttributes) {
          setDisplayAttributes(response.displayAttributes);

          response.displayAttributes.forEach((attribute: any) => {
            content.forEach((brand: any, index: number) => {
              content[index][attribute.id] = brand.extendedAttributes[attribute.id]?.value || "N/A";
            });
          });
        }

        content.forEach((brand: any, index: number) => {
          content[index].key = brand.slug;
        });

        dispatch(setIndustryBrands(content));
      })
      .catch((error: any) => {
        message.error(error.message, 2);
      });
  }, [selectedLocation, selectedProductService]);

  const compareBrands = () => {
    getBrandBySlug(selectedBrands[0]).then((firstBrand: any) => {
      dispatch(setBrand(firstBrand));

      getBrandBySlug(selectedBrands[1]).then((secondBrand: any) => {
        dispatch(setCompetitor(secondBrand));
        navigate(`../brands/${selectedBrands[0]}/compare/${selectedBrands[1]}`, { replace: true });
      });
    });
  };

  const getProductServiceName = (id: string) => {
    const product = products.filter((prod: any) => prod.id === id);

    if (product.length > 0) {
      return product[0].name;
    }

    const service = services.filter((prod: any) => prod.id === id);

    if (service.length > 0) {
      return service[0].name;
    }

    return "All";
  };

  const getLocationName = (id: string) => {
    const location = locations.filter((loc: any) => loc.id === id);
    return location.length > 0 ? location[0].displayName : "All";
  };

  const selectProductService = (id: string) => {
    if (id === "") {
      setSelectedProductService("");
      setSelectedProduct("");
      setSelectedService("");
    } else {
      setSelectedProductService(id);

      const product = products.filter((prod: any) => prod.id === id);

      if (product.length > 0) {
        setSelectedProduct(id);
        setSelectedService("");
      } else {
        setSelectedService(id);
        setSelectedProduct("");
      }
    }
  };

  const getAvailableLocations = () => {
    const availableLocations = locations.map((location: any) => {
      return {
        value: location.id,
        label: location.displayName,
      };
    });

    return availableLocations;
  };

  const getAvailableProductsServices = () => {
    const availableProductsServices = productsServices.map((prod: any) => {
      return {
        value: prod.id,
        label: prod.name,
      };
    });

    return availableProductsServices;
  };

  useEffect(() => {
    const handleScroll = (event: any) => {
      if (!isInViewport(event.target.querySelector(".non-sticky"))) {
        setShowSticky(true);
      } else {
        setShowSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="content">
      <h1 className="heading">Brand Analytics</h1>
      <div className="breadcrumbs">
        Financial Services &gt; Institutional Investment &gt; Asset managers
      </div>

      <div className="filters">
        <div className="filter">
          <span className="label">Industry</span>
          <Select
            defaultValue="asset-management"
            style={{ width: "100%" }}
            options={[
              {
                value: "asset-management",
                label: "Asset management",
              },
            ]}
          />
        </div>
        <div className="filter">
          <span className="label">Products + Services</span>
          <Select
            allowClear
            defaultValue={getProductServiceName(selectedProductService)}
            style={{ width: "100%" }}
            options={getAvailableProductsServices()}
            onChange={(option) => selectProductService(option)}
          />
        </div>
        <div className="filter">
          <span className="label">Locations</span>
          <Select
            allowClear
            defaultValue={getLocationName(selectedLocation)}
            style={{ width: "100%" }}
            options={getAvailableLocations()}
            onChange={(option) => setSelectedLocation(option)}
          />
        </div>
      </div>

      <section className="compareSection non-sticky">
        <p className="text">
          Click on individual brands to view full analysis, or select two brands to compare and
          contrast analytics
        </p>
        <button
          type="button"
          onClick={() => compareBrands()}
          className={selectedBrands.length === 2 ? "button active" : "button"}
        >
          Compare brands
        </button>
      </section>

      {showSticky && (
        <section className="compareSection sticky">
          <p className="text">
            Click on individual brands to view full analysis, or select two brands to compare and
            contrast analytics
          </p>
          <button
            type="button"
            onClick={() => compareBrands()}
            className={selectedBrands.length === 2 ? "button active" : "button"}
          >
            Compare brands
          </button>
        </section>
      )}

      <section className="tabsOverview">
        <div className={activeTab === "Overview" ? "tab active" : "tab"}>
          <button type="button" onClick={() => setActiveTab("Overview")}>
            Overview
          </button>
        </div>
        <div className={activeTab === "ESG" ? "tab active" : "tab"}>
          <button type="button" onClick={() => setActiveTab("ESG")}>
            ESG
          </button>
        </div>
        <div className={activeTab === "Digital Marketing" ? "tab active" : "tab"}>
          <button type="button" onClick={() => setActiveTab("Digital Marketing")}>
            Digital Marketing
          </button>
        </div>
      </section>
      <section className="tabsContent">
        {activeTab === "Overview" && industryBrands.length > 0 && (
          <Overview
            setSelectedBrands={setSelectedBrands}
            displayAttributes={displayAttributes}
            selectedProductService={selectedProductService}
          />
        )}
        {activeTab === "ESG" && industryBrands.length > 0 && (
          <ESG selectedBrands={selectedBrands} setSelectedBrands={setSelectedBrands} />
        )}
        {activeTab === "Digital Marketing" && industryBrands.length > 0 && (
          <DigitalMarketing selectedBrands={selectedBrands} setSelectedBrands={setSelectedBrands} />
        )}
      </section>
    </div>
  );
};
