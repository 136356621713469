import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

import { setUser } from "../../actions/actions";

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  localStorage.clear();

  dispatch(
    setUser({
      name: "",
      image: "",
      brand: {},
      roles: "[]",
      token: "",
    })
  );

  message.success("Logged out", 2);

  navigate("../", { replace: true });

  return <React.Fragment />;
};

export default Logout;
