import React from "react";

import { getIndustries, getProducts, getServices } from "../../API/api";

export const getParentKey = (key: any, tree: any) => {
  let parentKey: any;
  for (let i = 0; i < tree.length; i += 1) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item: any) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

export const loop = (tree: any, searchValue: string) =>
  tree.map((item: any) => {
    const index = item.title.toLowerCase().indexOf(searchValue.toLowerCase());
    const beforeStr = item.title.substr(0, index);
    const afterStr = item.title.substr(index + searchValue.length);
    const title =
      index > -1 ? (
        <span>
          {beforeStr}
          <span className="site-tree-search-value">{searchValue}</span>
          {afterStr}
        </span>
      ) : (
        <span>{item.title}</span>
      );

    if (item.children) {
      return { title, key: item.key, children: loop(item.children, searchValue) };
    }

    return {
      title,
      key: item.key,
    };
  });

export const populateProducts = (
  industryId: string,
  setPrtreeData: any,
  setOriginaProductData: any,
  setTreeProductLeafs: any
) => {
  getProducts(industryId).then((response) => {
    const newProductData: any = [];
    const productNodes: any = [];

    if (!response) {
      return;
    }

    if (response.length > 0) {
      response.forEach((parent: any) => {
        const parentProduct = {
          title: parent.name,
          key: parent.id,
          children: [] as any,
          selectable: parent.children?.length === 0,
          isLeaf: parent.children?.length === 0,
        };

        if (parent.children?.length > 0) {
          parent.children.forEach((subproduct: any) => {
            const subProduct = {
              title: subproduct.name,
              key: subproduct.id,
              children: [] as any,
              selectable: subproduct.children.length === 0,
              isLeaf: subproduct.children.length === 0,
            };
            if (subproduct.children.length > 0) {
              subproduct.children.forEach((childproduct: any) => {
                const childProduct = {
                  title: childproduct.name,
                  key: childproduct.id,
                  children: [] as any,
                  isLeaf: childproduct.children.length === 0,
                  selectable: childproduct.children.length === 0,
                };
                if (childproduct.children.length > 0) {
                  childproduct.children.forEach((grandChildren: any) => {
                    const gGrandpr = {
                      title: grandChildren.name,
                      key: grandChildren.id,
                      isLeaf: true,
                    };
                    childProduct.children.push(gGrandpr);
                  });
                }
                subProduct.children.push(childProduct);
              });
            }
            parentProduct.children.push(subProduct);
          });
        }
        newProductData.push(parentProduct);
        productNodes.push(parentProduct);
      });
    }
    setPrtreeData([...newProductData]);
    setOriginaProductData([...newProductData]);
    setTreeProductLeafs([...productNodes]);
  });
};

export const populateServices = (
  industryId: string,
  setSrtreeData: any,
  setOriginaServiceData: any,
  setTreeServiceLeafs: any
) => {
  getServices(industryId).then((response) => {
    const newServiceData: any = [];
    const serviceNodes: any = [];

    if (!response) {
      return;
    }

    if (response.length > 0) {
      response.forEach((parent: any) => {
        const parentProduct = {
          title: parent.name,
          key: parent.id,
          children: [] as any,
          selectable: parent.children?.length === 0,
          isLeaf: parent.children?.length === 0,
        };

        if (parent.children?.length > 0) {
          parent.children.forEach((subproduct: any) => {
            const subProduct = {
              title: subproduct.name,
              key: subproduct.id,
              children: [] as any,
              selectable: subproduct.children.length === 0,
              isLeaf: subproduct.children.length === 0,
            };
            if (subproduct.children.length > 0) {
              subproduct.children.forEach((childproduct: any) => {
                const childProduct = {
                  title: childproduct.name,
                  key: childproduct.id,
                  children: [] as any,
                  selectable: childproduct.children.length === 0,
                  isLeaf: childproduct.children.length === 0,
                };
                if (childproduct.children.length > 0) {
                  childproduct.children.forEach((grandChildren: any) => {
                    const gGrandpr = {
                      title: grandChildren.name,
                      key: grandChildren.id,
                      isLeaf: true,
                    };
                    childProduct.children.push(gGrandpr);
                  });
                }
                subProduct.children.push(childProduct);
              });
            }
            parentProduct.children.push(subProduct);
          });
        }
        newServiceData.push(parentProduct);
        serviceNodes.push(parentProduct);
      });
    }
    setSrtreeData([...newServiceData]);
    setOriginaServiceData([...newServiceData]);
    setTreeServiceLeafs([...serviceNodes]);
  });
};

export const populateIndustries = (
  setTreeServiceLeafs: any,
  setTreeProductLeafs: any,
  setTreeData: any,
  setDataList: any
) => {
  getIndustries().then((response) => {
    const newData: any = [];
    const industryNodes: any = [];
    let list: any = [];

    if (!response) {
      return;
    }

    setTreeServiceLeafs([]);
    setTreeProductLeafs([]);

    if (response.content.length > 0) {
      response.content.forEach((industry: any) => {
        const industrySectorData = {
          title: industry.sector.displayName,
          key: industry.sector.id,
          children: [] as any,
          selectable: industry.sector.industryGroups.length === 0,
          isLeaf: industry.sector.industryGroups.length === 0,
        };

        list.push({ key: industry.sector.id, title: industry.sector.displayName });

        if (industry.sector.industryGroups.length > 0) {
          industry.sector.industryGroups.forEach((group: any) => {
            const industryGroupData = {
              title: group.displayName,
              key: group.id,
              children: [] as any,
              selectable: group.industries.length === 0,
              isLeaf: group.industries.length === 0,
            };

            list.push({ key: group.id, title: group.displayName });

            if (group.industries.length > 0) {
              group.industries.forEach((industries: any) => {
                const industriesData = {
                  title: industries.displayName,
                  key: industries.id,
                  children: [] as any,
                  selectable: industries.subIndustries.length === 0,
                  isLeaf: industries.subIndustries.length === 0,
                };

                list.push({ key: industries.id, title: industries.displayName });

                if (industries.subIndustries.length > 0) {
                  industries.subIndustries.forEach((subindustry: any) => {
                    const subindustryData = {
                      title: subindustry.displayName,
                      key: subindustry.id,
                      children: [] as any,
                      isLeaf: true,
                    };

                    list.push({ key: subindustry.id, title: subindustry.displayName });

                    industriesData.children.push(subindustryData);
                    industryNodes.push(subindustryData);
                  });
                }

                industryGroupData.children.push(industriesData);
              });
            }

            industrySectorData.children.push(industryGroupData);
          });
        }

        newData.push(industrySectorData);
      });
    }
    setTreeData([...newData]);

    list = list.filter(
      (value: any, index: any, self: any) =>
        index === self.findIndex((t: any) => t.key === value.key && t.title === value.title)
    );

    setDataList([...list]);
  });
};
