import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, message, Spin } from "antd";

import { SearchBrand } from "../../components/Search/SearchBrand";
import { uploadBrandKeywords, refreshBrandKeywords } from "../../API/api";

const RefreshKeywords: React.FC = () => {
  const [brand, setBrand] = useState(null) as any;
  const [isUploading, setIsUploading] = useState(false);

  const onSelectBrand = (selectedBrand: any) => {
    setBrand(selectedBrand);
  };

  const customRequest = async (options: any) => {
    const { onError, file } = options;

    uploadBrandKeywords(file, brand.id)
      .then(() => {
        refreshBrandKeywords(brand.id).then(() => {
          setIsUploading(false);
          setBrand(null);
          message.success("Brand analytics updated");
        });
      })
      .catch((error) => {
        onError({ error });
      });
  };

  const onFileChange = (info: any) => {
    if (info.file.status === "uploading") {
      setIsUploading(true);
    }
  };

  return (
    <div className="content">
      <NavLink to="/v2/admin">&lt; Back to admin page</NavLink>
      <h2 style={{ marginTop: 20 }}>Update brand keywords</h2>
      <Spin spinning={isUploading}>
        <div style={{ width: "50%", marginTop: 20 }}>
          <SearchBrand onSelectedBrand={onSelectBrand} clearOnSelect={false} />
          {brand && (
            <div style={{ marginTop: 20 }}>
              <Upload customRequest={customRequest} onChange={onFileChange}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default RefreshKeywords;
