import React from "react";

import { ContactUsForm } from "../components/Forms/ContactUs/ContactUsForm";
import { Footer } from "../components/Footer/Footer";
import { ReactComponent as Logo } from "../webflow/images/Small_Giants_logo_orange.svg";

const ContactUs: React.FC = () => {
  return (
    <div className="section welcome wf-section">
      <div className="search-box_container">
        <Logo className="smallgiants-logo_small" />
        <h1 className="search-box_heading">Get in touch</h1>
        <div className="form-block _w-100 w-form">
          <ContactUsForm />
        </div>
        <a
          href="https://www.smallgiants.agency/"
          target="_blank"
          rel="noreferrer"
          className="link-sg-website"
        >
          www.smallgiants.agency
        </a>
      </div>
      <Footer />
    </div>
  );
};
export default ContactUs;
