import React from "react";
import { NavLink } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";

import Search from "../components/v1/Search/Search";
import SearchTaxonomy from "../components/v1/Search/SearchTaxonomy";
import { Footer } from "../components/Footer/Footer";

import { ReactComponent as Logo } from "../webflow/images/Small_Giants_logo_orange.svg";
import { ReactComponent as SearchIcon } from "../webflow/images/search-icon.svg";

const SearchPage: React.FC = () => {
  const user = useSelector((state: RootStateOrAny) => state.user);
  const isUserAdmin = user.roles.indexOf("ADMIN") > -1;

  return (
    <div className="section welcome">
      <div className="search-box_container">
        <Logo className="smallgiants-logo_small" />
        <h1 className="search-box_heading">BRAND INDEX</h1>
        <form action="/search" className="search w-form" style={{ marginTop: "40px" }}>
          <label htmlFor="searchText">Search</label>
          <div className="search-wrapper search-wrapper-home">
            <Search
              id="searchText"
              webflowStyle="search-input w-input"
              placeholder="Search by brand"
            >
              <SearchIcon className="search-icon" />
            </Search>
            <p>or</p>
            <SearchTaxonomy />
          </div>
        </form>

        <div className="btn-wrapper" style={{ marginTop: "80px" }}>
          {user.token === "" && (
            <NavLink to="/login" className="btn dark w-button">
              Login
            </NavLink>
          )}
          {isUserAdmin && (
            <NavLink to="/brands/review" className="btn dark w-button">
              Review brands
            </NavLink>
          )}
          <a href="/ContactUs" className="btn outlined w-button">
            Contact us
          </a>
        </div>
        <a
          href="https://www.smallgiants.agency/"
          target="_blank"
          rel="noreferrer"
          className="link-sg-website"
        >
          www.smallgiants.agency
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default SearchPage;
