import React, { useState, useEffect } from "react";
import { Form, Button, Tag, Row, Col, AutoComplete, Alert, message, Input } from "antd";

import {
  getProductsServicesAutocomplete,
  postProduct,
  getRecommendedProducts,
  getProducts,
} from "../../../../../API/api";
import { Product, Suggested } from "../../config";
import { toggleDropdown } from "../../../../../utils/utils";
import searchIcon from "../../../../../webflow/images/search-icon.svg";

interface ProductsProps {
  data: any;
  setData: any;
}

export const Products: React.FC<ProductsProps> = ({ data, setData }: ProductsProps) => {
  const [activeModal, setActiveModal] = useState(false);
  const [selectIndustryOpened, setSelectIndustryOpened] = useState(false);
  const [valueAutoComplete, setValueAutoComplete] = useState("");
  const [suggestedProducts, setSuggestedProducts] = useState<Suggested[]>([]);
  const [listOfProducts, setListOfProducts] = useState([]);
  const [newProductValue, setNewProductValue] = useState("");
  const [originalListOfProducts, setOriginalListOfProducts] = useState<any[]>([]);
  const [alertText, setAlertText] = useState("") as any;
  const [industrySelected, setIndustrySelected] = useState<any>({
    name: "",
    id: "",
  });
  const isMandatory = data?.products?.length === 0 && data?.services?.length === 0;

  const getPlaceholderText = () => {
    let text = "";

    if (!data.industries[0]) {
      text = "First add industries";
    } else if (!industrySelected.id) {
      text = "Select an industry";
    } else if (listOfProducts?.length) {
      text = "Search a product";
    } else {
      text = "No products related to the industry";
    }

    return text;
  };

  const industrySelectedName = industrySelected.name ? industrySelected.name : "Select an industry";

  const reset = () => {
    const resetObject = {
      products: [],
      productIds: [],
    };

    setData({ ...data, ...resetObject });
  };

  const addProduct = (product: any) => {
    const { products, productIds } = data;
    products.push(product.value);
    productIds.push(product.key);

    setData({ ...data, ...{ products, productIds } });
  };

  const removeProduct = (product: any) => {
    const { products, productIds } = data;
    const index = products.indexOf(product);
    productIds.splice(index, 1);

    const newData = {
      products: products.filter((item: string) => item !== product),
      productIds,
    };

    setData({ ...data, ...newData });
  };

  const handleSelectIndustry = async (ind: { name: string; id: string }) => {
    const products = await getProducts(ind.id);
    setIndustrySelected(ind);
    setOriginalListOfProducts(products);
    setListOfProducts(products);
    setSelectIndustryOpened(false);
    setValueAutoComplete("");
  };

  const handleProductSearch = (value: string) => {
    const res: any = [];

    originalListOfProducts?.forEach((option) => {
      if (option.name) {
        /** Industry case */
        if (option.name.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
          res.push(option);
        }
      } else if (option.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
        /** Products or services case */
        res.push(option);
      }
    });

    setListOfProducts(res);
  };

  const handleClickSuggestion = (suggestion: any) => {
    const idxOfSuggestion = suggestedProducts.indexOf(suggestion);
    const suggesteds = [...suggestedProducts];
    suggesteds.splice(idxOfSuggestion, 1, {
      id: suggestion.id,
      name: suggestion.name,
      selected: !suggesteds[idxOfSuggestion].selected,
    });
    if (suggesteds[idxOfSuggestion].selected) {
      addProduct({
        value: suggestion.name,
        key: suggestion.id,
      });
    } else {
      removeProduct(suggestion.name);
    }
    setSuggestedProducts(suggesteds);
  };

  const addNewProduct = (productName: string) => {
    const newProduct = {
      subIndustryId: industrySelected.id,
      parentId: industrySelected.id,
      type: "product",
      name: productName,
    };
    try {
      postProduct(newProduct).then((response: any) => {
        if (response.status === 409) {
          setAlertText(response.message);
          setTimeout(() => {
            setAlertText("");
          }, 3000);
        } else if (response.status === 200) {
          addProduct({ key: response.data.id, value: response.data.name });
          handleSelectIndustry(industrySelected);
        }
      });
    } catch (error) {
      message.error("Error posting product", 2);
    }
  };

  useEffect(() => {
    if (industrySelected?.id) {
      (async () => {
        let products = await getRecommendedProducts(industrySelected.id);
        products = products.map((pr: Suggested) => ({
          ...pr,
          selected: data?.productIds ? data.productIds.indexOf(pr.id) >= 0 : false,
        }));
        setSuggestedProducts(products);
      })();
    } else if (suggestedProducts?.length) {
      setSuggestedProducts([]);
    }
  }, [industrySelected]);

  useEffect(() => {
    if (data?.industries?.length === 1) {
      handleSelectIndustry({ name: data.industries[0], id: data.industryIds[0] });
    }
  }, [data]);

  return (
    <>
      <div className="form_field-label">Products</div>
      <div className="selected-tags-block">
        {data?.products?.map((product: any, index: any) => {
          return (
            <Tag
              key={`productTag${index + 1}`}
              className="selected-tag"
              closable
              onClose={() => removeProduct(product)}
            >
              {product}
            </Tag>
          );
        })}
      </div>
      <Button className="btn-wrapper mgn-top-0-5em" onClick={() => setActiveModal(true)}>
        <a href="#" className="btn orange w-inline-block">
          <div>Add product</div>
          <div className="icon add-category-icon w-embed">
            <svg
              width="14"
              height="14"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.88867 4.13574H9.45703V5.67383H5.88867V9.7168H4.25391V5.67383H0.685547V4.13574H4.25391V0.400391H5.88867V4.13574Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </a>
      </Button>

      <Form.Item
        name="products"
        className="hiddenField"
        hidden
        rules={[
          {
            required: isMandatory,
            message: (
              <div className="form_error-text">
                <span className="error-icon" /> Please select at least one product
              </div>
            ),
          },
        ]}
      >
        <Input value={data?.products?.join(",")} />
      </Form.Item>

      <Row>
        <div
          className="blurred-bg industry-popup"
          style={{ display: activeModal ? "flex" : "none" }}
        >
          <div className="popup_c-block">
            <div className="form_field-label btm-mgn-0-5em">Add products</div>
            <>
              {data?.industries?.length > 1 && (
                <Col
                  span={24}
                  data-hover="false"
                  data-delay="0"
                  className="category-dropdown fw w-dropdown"
                >
                  <div
                    className={`category-dropdown_toggle fw w-dropdown-toggle ${
                      data.industries.length === 1 ? "category-dropdown_list--disabled" : ""
                    }`}
                    role="button"
                    tabIndex={0}
                    onClick={(e) => {
                      setSelectIndustryOpened(!selectIndustryOpened);
                      toggleDropdown(e.target);
                    }}
                    onKeyDown={(e) => {
                      setSelectIndustryOpened(!selectIndustryOpened);
                      toggleDropdown(e.target);
                    }}
                  >
                    <div className="w-icon-dropdown-toggle" />
                    <div>{!data.industries[0] ? "First add industries" : industrySelectedName}</div>
                  </div>
                  <nav
                    className={`category-dropdown_list w-dropdown-list ${
                      data.industries.length === 1 ? "category-dropdown_list--disabled" : ""
                    } ${selectIndustryOpened ? "w--open" : ""}`}
                  >
                    {data?.industries?.map((industry: string, index: number) => (
                      <a
                        key={`${industry}-${index.toString()}`}
                        role="button"
                        tabIndex={0}
                        className="category-dropdown_link w-dropdown-link"
                        onClick={() =>
                          handleSelectIndustry({ name: industry, id: data.industryIds[index] })
                        }
                        onKeyDown={() =>
                          handleSelectIndustry({ name: industry, id: data.industryIds[index] })
                        }
                      >
                        {industry}
                      </a>
                    ))}
                  </nav>
                </Col>
              )}
              <div className="search industry-search w-form">
                <Form.Item required={false} validateTrigger={["onChange", "onBlur"]} noStyle>
                  <div className="search-wrapper">
                    <AutoComplete
                      onSelect={(value, instance) => {
                        addProduct(instance);
                      }}
                      className="search-input pad w-input"
                      onSearch={handleProductSearch}
                      placeholder={getPlaceholderText()}
                      style={{ width: 500 }}
                      onChange={(input: string) => {
                        setValueAutoComplete(input);
                        getProductsServicesAutocomplete(
                          {
                            success: (response: []) => {
                              if (response.length) {
                                setListOfProducts(response);
                              }
                            },
                            error: (error: any) => {
                              message.error(error.message, 2);
                            },
                          },
                          { value: input, type: "product", subIndustryId: industrySelected.id }
                        );
                      }}
                      value={valueAutoComplete}
                      disabled={
                        !data.industries[0] ||
                        !industrySelected.id ||
                        !originalListOfProducts?.length
                      }
                    >
                      {listOfProducts?.map((option: Product) => (
                        <AutoComplete.Option key={option.id} value={option.name}>
                          {option.name}
                        </AutoComplete.Option>
                      ))}
                    </AutoComplete>
                    <img src={searchIcon} loading="lazy" className="search-icon" alt="search" />
                  </div>
                </Form.Item>
              </div>
              {suggestedProducts?.length > 0 && (
                <div className="popular-suggestions">
                  <div className="w-form">
                    <div className="form_field-label btm-mgn-0-5em">Suggested products:</div>

                    <div className="grid-2x">
                      {suggestedProducts?.map((suggestion: Suggested, index: number) => {
                        return (
                          <label
                            key={`${suggestion}-${index.toString()}`}
                            className="w-checkbox industry-suggestions_checkbox"
                          >
                            <div
                              className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${
                                suggestion.selected ? "w--redirected-checked" : ""
                              }`}
                            />
                            <input
                              type="checkbox"
                              id="checkbox"
                              name="checkbox"
                              data-name="Checkbox"
                              style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                              onClick={() => handleClickSuggestion(suggestion)}
                            />
                            <span className="w-form-label">{suggestion.name}</span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}

              <div className="selected-tags-block">
                {data?.products?.map((product: any, index: any) => {
                  return (
                    <Tag
                      key={`productTag${index + 1}`}
                      className="selected-tag"
                      closable
                      onClose={() => removeProduct(product)}
                    >
                      {product}
                    </Tag>
                  );
                })}
              </div>

              <div className="not-found-block">
                <div className="form_field-label btm-mgn-0-5em">
                  Product not found? Please add here:
                </div>
                <div className="w-form">
                  <div className="form_field-wrap--flex">
                    <div className="search-wrapper">
                      <input
                        type="text"
                        className="search-input pad w-input"
                        maxLength={256}
                        name="Add-product"
                        disabled={!data.industries[0] || !industrySelected.name}
                        data-name="Add product"
                        placeholder={getPlaceholderText()}
                        id="Add-product"
                        onChange={(e) => setNewProductValue(e.target.value)}
                        value={newProductValue}
                        onKeyDown={(e: any) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            e.stopPropagation();
                            addNewProduct(newProductValue);
                            setNewProductValue("");
                          }
                        }}
                      />
                      <input
                        type="submit"
                        disabled={!data.industries[0] || !industrySelected.name}
                        value="Add"
                        className="search-button w-button"
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          addNewProduct(newProductValue);
                          setNewProductValue("");
                        }}
                      />
                    </div>
                  </div>
                  {!!alertText && (
                    <Alert style={{ marginTop: "15px" }} message={alertText} type="error" />
                  )}
                </div>
              </div>
            </>

            <div className="reset-block">
              <div
                className="reset-suggestions cursor-pointer"
                role="button"
                tabIndex={0}
                onClick={reset}
                onKeyDown={reset}
              >
                Reset
              </div>
              <div
                className="btn orange w-button cursor-pointer"
                role="button"
                tabIndex={0}
                onClick={() => setActiveModal(false)}
                onKeyDown={() => setActiveModal(false)}
              >
                Ok
              </div>
            </div>
            <div
              role="button"
              tabIndex={0}
              data-w-id="88dbabb2-e4f5-b6cd-2422-cd4d8e59c2d6"
              className="modal-close"
              onClick={() => setActiveModal(false)}
              onKeyDown={() => setActiveModal(false)}
            >
              <div className="icon w-embed">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.75736 4.75736L13.2426 13.2426"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.0729 4.58765L4.58765 13.0729"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};
