import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Button, Tabs, Input } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import { postReviewBrand, getBrandBySlug } from "../../API/api";
import { setBrand } from "../../actions/actions";
import Navbar from "../../components/Navbar/Navbar";
import { formatDate, getImageURL } from "../../utils/utils";
import { Navigation } from "../../components/Navigation/Navigation";
import { ProductsServices } from "../../classes/brandClass";
import { SearchBar } from "../../components/SearchBar/SearchBar";

const ReviewBrandsDetails: React.FC = () => {
  const { TabPane } = Tabs;
  const { TextArea } = Input;
  const navigate = useNavigate();
  const { slug } = useParams() as any;
  const reviewBrands = useSelector((state: RootStateOrAny) => state.reviewBrands);
  const dispatch = useDispatch();
  const [rejectReason, setRejectReason] = useState("");
  const brand = reviewBrands
    .filter((reviewBrand: any) => {
      return reviewBrand.slug === slug;
    })
    .pop();

  if (!brand) {
    navigate(`../brands/review`, { replace: true });
  }

  const reviewBrand = (approved: boolean) => {
    const review = {
      brandId: brand.id,
      approved,
      reason: rejectReason,
    };

    postReviewBrand(review).then(() => {
      getBrandBySlug(slug).then((slugBrand) => {
        if (slugBrand === undefined) {
          const notFoundBrand = {
            name: "Not Found",
            url: true,
            description: "Search for an specific brand using the search field at the top",
            image: "",
            analytics: {
              webScore: 0,
            },
            competitors: [],
          };
          dispatch(setBrand(notFoundBrand));
          localStorage.setItem("brandSlug", "");
        } else {
          dispatch(setBrand(slugBrand));
          localStorage.setItem("brandSlug", slug);
        }
      });

      navigate(`../brands/review`, { replace: true });
    });
  };

  const getMatchingElements = (brandList: any, competitorList: any) => {
    const matchingElements = [] as any;

    if (brandList && brandList.length > 0 && competitorList && competitorList.length > 0) {
      brandList.forEach((element: any) => {
        competitorList.forEach((competitorElement: any) => {
          if (competitorElement.name === element.name) {
            matchingElements.push(competitorElement.name);
          }
        });
      });
    }

    return matchingElements;
  };

  const getCompetitorInfo = (competitor: any) => {
    const matchingProducts = getMatchingElements(brand.products, competitor.products);
    const matchingServices = getMatchingElements(brand.services, competitor.services);
    const matchingLocations = getMatchingElements(brand.locations, competitor.locations);

    return (
      <div>
        <h5>{competitor.name}</h5>

        {matchingProducts.length > 0 && <p>Products: {matchingProducts.join(", ")}</p>}
        {matchingServices.length > 0 && <p>Services: {matchingServices.join(", ")}</p>}
        {matchingLocations.length > 0 && <p>Locations: {matchingLocations.join(", ")}</p>}
      </div>
    );
  };

  const getSocialInfo = (analytics: any) => {
    return (
      <>
        <h4>{analytics.name}</h4>
        {analytics.value.map((value: any) => (
          <p>
            <strong>{Object.keys(value)}:</strong> {Object.values(value)}
          </p>
        ))}
      </>
    );
  };

  return (
    <React.Fragment>
      <Navbar />
      <div className="section analysis">
        <Navigation />
        <main className="main-panel">
          <SearchBar />
          <section className="card">
            <h2 className="brand-name no-mgn">Brand review</h2>
          </section>
          <section className="card" style={{ flexDirection: "column" }}>
            <div className="row">
              <div className="reviewColumn">
                <div className="brand_logo_name pad-20">
                  <img
                    src={brand.logo ?? getImageURL(brand.logo, undefined)}
                    loading="lazy"
                    alt={brand.name}
                    className="brand_logo_circular"
                  />
                  <h1 className="brand-name orange btm-0 pad-20">{brand.name}</h1>
                </div>
                <div className="pad-20" style={{ width: "95%" }}>
                  <h5 className=" btm-0"> Short description</h5>
                  <p className="info-block_desc">{brand.shortDescription}</p>

                  <h5 className="btm-0">URL</h5>
                  <a className="orange-text" target="_blank" rel="noreferrer" href={brand.url}>
                    <span>{brand.url}</span>
                  </a>
                </div>
              </div>
              <div className="reviewColumn">
                <div style={{ width: "100%", display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <h5 className="btm-0">Last modified by</h5>
                    <p className="info-block_desc">{brand.metadata.source.name}</p>
                  </div>
                  <div style={{ flex: 1 }}>
                    <h5 className="btm-0">Last modified</h5>
                    <p className="info-block_desc">{formatDate(brand.metadata.lastModifiedDate)}</p>
                  </div>
                  <div style={{ flex: 1 }}>
                    <h5 className="btm-0">Status</h5>
                    <p className="info-block_desc">{brand.metadata.status}</p>
                  </div>

                  {brand.metadata.status === "rejected" && (
                    <>
                      <h5 className=" btm-0"> Status reason</h5>
                      <p className="info-block_desc">{brand.metadata.statusReason}</p>
                    </>
                  )}
                </div>
                <div>
                  <button
                    type="button"
                    className="btn right btn-secondary"
                    onClick={() => reviewBrand(true)}
                  >
                    Approve
                  </button>
                  <button
                    type="button"
                    className="btn left btn-secondary"
                    onClick={() => reviewBrand(false)}
                  >
                    Reject
                  </button>

                  <div className="reject_reason">
                    <TextArea
                      placeholder="Reason for rejection"
                      value={rejectReason}
                      onChange={(e: any) => setRejectReason(e.target.value)}
                      rows={6}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="full-desc">
              <Tabs type="card">
                <TabPane tab="Location" key="location">
                  <h4>Locations</h4>
                  {(brand.locations &&
                    brand.locations.length > 0 &&
                    brand.locations.map((location: any) => (
                      <p key={location.name}>{location.name}</p>
                    ))) || <p>No locations</p>}
                </TabPane>
                <TabPane tab="Industries" key="industries">
                  <h4>Industries</h4>
                  {(brand.industries &&
                    brand.industries.length > 0 &&
                    brand.industries.map((industry: any) => (
                      <p key={industry.displayName}>{industry.displayName}</p>
                    ))) || <p>No industries</p>}
                </TabPane>
                <TabPane tab="Products/Services" key="products">
                  <h4>Products</h4>
                  {(brand.products &&
                    brand.products.length > 0 &&
                    brand.products.map((product: ProductsServices) => (
                      <p key={product.name}>{product.name}</p>
                    ))) || <p>No products</p>}
                  <h4>Services</h4>
                  {(brand.services &&
                    brand.services.length > 0 &&
                    brand.services.map((service: ProductsServices) => (
                      <p key={service.name}>{service.name}</p>
                    ))) || <p>No services</p>}
                </TabPane>
                <TabPane tab="Competitors" key="competitors">
                  <h4>Competitors</h4>
                  {(brand.competitors &&
                    brand.competitors.length > 0 &&
                    brand.competitors.map((competitor: any) => (
                      <div>{getCompetitorInfo(competitor)}</div>
                    ))) || <p>No competitors.</p>}
                </TabPane>
                <TabPane tab="Social" key="social">
                  {(brand.analytics[0]?.social &&
                    brand.analytics[0]?.social.length > 0 &&
                    brand.analytics[0]?.social.map((analytics: any) => (
                      <div>{getSocialInfo(analytics)}</div>
                    ))) || <p>No social metrics</p>}
                </TabPane>
                <TabPane tab="History" key="history">
                  <h4>History</h4>
                  {brand.metadata.auditTrail && brand.metadata.auditTrail.length > 0 && (
                    <table className="revisionsHistory">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>User</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {brand.metadata.auditTrail.map((rhistory: any) => (
                          <tr>
                            <td style={{ width: "15%" }}>{formatDate(rhistory.date)}</td>
                            <td style={{ width: "30%", whiteSpace: "nowrap" }}>{rhistory.user}</td>
                            <td style={{ width: "50%" }}>{rhistory.action}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </TabPane>
              </Tabs>

              <Link to="/brands/review">
                <Button type="primary" className="margin-top-40" icon={<LeftOutlined />}>
                  Back to brand review
                </Button>
              </Link>
            </div>
          </section>
        </main>
      </div>
    </React.Fragment>
  );
};

export default ReviewBrandsDetails;
