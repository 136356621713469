import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Table, Button, Form, Input, Popconfirm, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";

import { getCategories, addCategory, updateCategory, deleteCategory } from "../../API/api";

const Categories: React.FC = () => {
  const user = useSelector((state: RootStateOrAny) => state.user);
  const [refresh, setRefresh] = useState(0);
  const [categories, setCategories] = useState([]) as any;
  const emptyForm = {
    name: "",
    themes: [{ name: "", keywords: "" }],
  };
  const [selectedCategory, setSelectedCategory] = useState(emptyForm) as any;
  const [form] = Form.useForm();

  useEffect(() => {
    if (user.token) {
      getCategories().then((data: any) => setCategories(data));
    }
  }, [user, refresh]);

  useEffect(() => {
    form.resetFields();
  }, [selectedCategory]);

  const onFinish = (values: any) => {
    const themes = values.themes.map((theme: any) => {
      let keywords = theme.keywords.split(",");
      keywords = keywords.map((keyword: any) => keyword.trim());

      return {
        name: theme.name,
        keywords,
      };
    });

    const newValues = {
      name: values.name,
      themes,
    };

    if (selectedCategory.name === "") {
      addCategory(newValues).then(() => {
        setSelectedCategory(emptyForm);
        setRefresh(refresh + 1);
        message.success("Added new category", 2);
      });
    } else {
      updateCategory(selectedCategory.id, newValues).then(() => {
        setRefresh(refresh + 1);
        message.success("Updated category", 2);
      });
    }
  };

  const onSelectRow = (row: any) => {
    const themes = row.themes.map((theme: any) => {
      return {
        name: theme.name,
        keywords: theme.keywords.join(", "),
      };
    });

    const selectedValues = {
      id: row.id,
      name: row.name,
      themes,
    };

    setSelectedCategory(selectedValues);
  };

  const onDelete = (id: string) => {
    deleteCategory(id).then(() => {
      setRefresh(refresh + 1);
      message.success("Category has been deleted succesfully", 2);
    });
  };

  const columns = [
    {
      title: "Category name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name - b.name,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: any, row: any) => (
        <>
          <button type="button" onClick={() => onSelectRow(row)}>
            Edit
          </button>
          <Popconfirm
            title="Are you sure to delete this category?"
            onConfirm={() => onDelete(id)}
            okText="Yes"
            cancelText="No"
          >
            <button style={{ marginLeft: 20 }} type="button">
              Delete
            </button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div className="content">
      <NavLink to="/v2/admin">&lt; Back to admin page</NavLink>
      <h2 style={{ marginTop: 20 }}>ESG categories, themes and keywords</h2>
      <br />
      <br />
      <Button type="primary" htmlType="button" onClick={() => setSelectedCategory(emptyForm)}>
        Add new category
      </Button>
      <Table style={{ margin: "20px 0 40px" }} dataSource={categories} columns={columns} />

      <Form
        initialValues={selectedCategory}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        form={form}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Category name"
          name="name"
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input />
        </Form.Item>

        <Form.List name="themes">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey }) => (
                <div key={key} style={{ display: "flex", marginBottom: 8, width: "100%", gap: 10 }}>
                  <Form.Item
                    fieldKey={fieldKey}
                    label="Theme name"
                    name={[name, "name"]}
                    rules={[{ required: true, message: "Missing name" }]}
                    style={{ flex: "1" }}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                  <Form.Item
                    fieldKey={fieldKey}
                    label="Keywords"
                    name={[name, "keywords"]}
                    rules={[{ required: true, message: "Missing keywords" }]}
                    style={{ flex: "1" }}
                  >
                    <Input.TextArea rows={6} placeholder="Keywords" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add new theme
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {selectedCategory.name === "" ? "Add category" : "Save"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Categories;
