import axios from "axios";
import { message } from "antd";
import { store } from "../store";
import {
  PUBLIC_API_KEY,
  API_URL,
  SEARCH_AUTOCOMPLETE_URL,
  SEARCH_AUTOCOMPLETE_PRODUCTS,
  SEARCH_AUTOCOMPLETE_LOCATIONS,
  SEARCH_AUTOCOMPLETE_INDUSTRIES,
  BRANDS_URL,
  BRANDS_SLUG,
  AUTH_URL,
  UPLOAD_URL,
  INDUSTRIES_URL,
  PRODUCTS,
} from "./apiConstants";

import { buildURLQuery, apiConfig, brandOptions } from "../utils/utils";

/// TODO: replace hardcoded data/body with values relevant for each call.
/// In order to do this some API calls need to be broken up into multiple calls
export const getBrandById = (brandID: string) => {
  return axios
    .post(`${API_URL}${BRANDS_URL}/${brandID}`, brandOptions, apiConfig())
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const getBrandBySlug = (brandSlug: string) => {
  return axios
    .post(`${API_URL}${BRANDS_SLUG}/${brandSlug}`, brandOptions, apiConfig())
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const createBrand = (brand: any) => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;

  return axios
    .post(`${API_URL}${BRANDS_URL}`, brand, {
      headers: {
        "Content-Type": "application/json",
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result) => {
      if (!result.data) {
        message.error("No data received from api", 2);
      }
      return result.data;
    })
    .catch((error) => {
      message.error(error.message, 2);
      return undefined;
    });
};

export const addCompetitors = (competitorIds: any, brandID: string) => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;
  const data = competitorIds.map((comp: any) => {
    return { op: "add", path: "/competitorIds/-", value: comp };
  });

  return axios
    .patch(`${API_URL}${BRANDS_URL}/${brandID}`, data, {
      headers: {
        "Content-Type": "application/json",
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result) => {
      if (!result.data) {
        message.error("No data received from api", 2);
      }
      return result.data;
    })
    .catch((error) => {
      message.error(error.message, 2);
      return undefined;
    });
};

export const deleteBrand = (brandID: string) => {
  return axios
    .delete(`${API_URL}${BRANDS_URL}/revisions/${brandID}`, apiConfig())
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const patchBrand = (data: any, brandID: string) => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;

  return axios
    .patch(`${API_URL}${BRANDS_URL}/${brandID}`, data, {
      headers: {
        "Content-Type": "application/json",
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result) => {
      if (!result.data) {
        message.error("No data received from api", 2);
      }
      return result.data;
    })
    .catch((error) => {
      message.error(error.message, 2);
      return undefined;
    });
};

export const getBrandsAutocomplete = (
  onResponse: {
    success: (response: []) => void;
    error: (error: any) => void;
  },
  params?: { search: string; exclude?: string }
) => {
  axios
    .get(
      `${API_URL}${SEARCH_AUTOCOMPLETE_URL}${buildURLQuery(params, 10)}&sort=name,ASC`,
      apiConfig()
    )
    .then((response: any) => {
      if (response.status !== 200 && response.status !== 201) {
        if (onResponse.error) {
          onResponse.error(response);
        }
      } else if (onResponse.success) {
        onResponse.success(response.data);
      }
    })
    .catch((error: any) => {
      message.error(error.message, 2);
    });
};

export const getProductsServicesAutocomplete = (
  onResponse: {
    success: (response: []) => void;
    error?: (error: any) => void;
  },
  params: { value: string; type: string; subIndustryId: string }
) => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;

  axios
    .get(
      `${API_URL}${SEARCH_AUTOCOMPLETE_PRODUCTS}?search=${params.value}&type=${params.type}&subIndustryId=${params.subIndustryId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-LOOMI-KEY": API_KEY,
          "X-API-VERSION": API_VERSION,
        },
      }
    )
    .then((response: any) => {
      if (response.status !== 200 && response.status !== 201) {
        if (onResponse.error) {
          onResponse.error(response);
        }
      } else if (onResponse.success) {
        onResponse.success(response.data);
      }
    })
    .catch((error: any) => {
      message.error(error.message, 2);
    });
};

export const getLocationsOrIndustriesAutocomplete = (
  onResponse: {
    success: (response: []) => void;
    error: (error: any) => void;
  },
  params: { search: string; exclude?: string },
  type: string
) => {
  const path =
    type === "locations"
      ? `${SEARCH_AUTOCOMPLETE_LOCATIONS}${buildURLQuery(params, 10)}`
      : SEARCH_AUTOCOMPLETE_INDUSTRIES;
  const locationType = type === "locations" ? "&type=country" : "";
  axios
    .get(`${API_URL}${path}?search=${params.search}${locationType}`, apiConfig())
    .then((response: any) => {
      if (response.status !== 200 && response.status !== 201) {
        if (onResponse.error) {
          onResponse.error(response);
        }
      } else if (onResponse.success) {
        onResponse.success(response.data);
      }
    })
    .catch((error: any) => {
      message.error(error.message, 2);
    });
};

export const authLogin = (loginInfo: any) => {
  const loginData = JSON.stringify(loginInfo);
  return axios
    .post(`${API_URL}${AUTH_URL}/login`, loginData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((result: any) => {
      document.cookie = `token=${result.data.token};max-age=604800`;
      return result.data;
    })
    .catch((error) => {
      if (error.response.data.message) {
        return {
          error: error.response.data.message,
        };
      }
      return false;
    });
};

export const uploadImage = (imageFile: any, location: any) => {
  const formData = new FormData();
  formData.append("file", imageFile);
  formData.append("location", location);
  const state = store.getState();
  const API_KEY = state.user.token || PUBLIC_API_KEY;

  return axios
    .post(`${API_URL}${UPLOAD_URL}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-LOOMI-KEY": API_KEY,
      },
    })
    .then((result: any) => {
      return result;
    })
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const getIndustries = () => {
  return axios
    .get(`${API_URL}${INDUSTRIES_URL}`, apiConfig())
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const getReviewBrands = (page: number) => {
  const params = {
    sort: "metadata.lastModifiedDate,ASC",
    size: 10,
    page: page.toString(),
  };
  return axios
    .post(`${API_URL}${BRANDS_URL}/review`, brandOptions, {
      params: {
        ...params,
      },
      ...apiConfig(),
    })
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const postReviewBrand = (review: any) => {
  const data = JSON.stringify(review);
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;

  return axios
    .post(`${API_URL}${BRANDS_URL}/review`, data, {
      headers: {
        "Content-Type": "application/json",
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result) => result)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const getProducts = (industryID: string) => {
  return axios
    .get(`${API_URL}${PRODUCTS}/industry/${industryID}?type=product`, apiConfig())
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const getServices = (industryID: string) => {
  return axios
    .get(`${API_URL}${PRODUCTS}/industry/${industryID}?type=service`, apiConfig())
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const postProduct = (product: any) => {
  const data = JSON.stringify(product);
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;

  return axios
    .post(`${API_URL}${PRODUCTS}`, data, {
      headers: {
        "Content-Type": "application/json",
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result) => result)
    .catch((error) => {
      message.error(error.response.data.message, 2);
    });
};

export const getRecommendedServices = (industryID: string) => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;

  return axios
    .get(`${API_URL}${PRODUCTS}/industry/${industryID}/recommendedServices`, {
      headers: {
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const getRecommendedProducts = (industryID: string) => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;

  return axios
    .get(`${API_URL}${PRODUCTS}/industry/${industryID}/recommendedProducts`, {
      headers: {
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const checkNameExists = (url: string) => {
  return axios
    .get(`${API_URL}${BRANDS_URL}/exists?type=name&value=${url}`, apiConfig())
    .then((result) => result?.data?.response)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const checkUrlExists = (url: string) => {
  return axios
    .get(`${API_URL}${BRANDS_URL}/exists?type=url&value=${url}`, apiConfig())
    .then((result) => result?.data?.response)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const getSuggestedCompetitors = (brandID: string) => {
  return axios
    .get(`${API_URL}${BRANDS_URL}/${brandID}/competitors/suggest`, apiConfig())
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const getAnalytics = (brandID: string) => {
  return axios
    .get(`${API_URL}${BRANDS_URL}/refresh/${brandID}`, apiConfig())
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const exportBrand = (requestData: any, brandID: string) => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;

  return axios
    .get(
      `${API_URL}${BRANDS_URL}/export/${brandID}?location=${requestData.location}&competitorIds=${requestData.competitorIds}&exportType=${requestData.exportType}`,
      {
        headers: {
          "X-LOOMI-KEY": API_KEY,
          "X-API-VERSION": API_VERSION,
        },
        responseType: "blob",
      }
    )
    .then((response) => {
      if (!response.data) {
        message.error("No data received from api", 2);
      }

      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type });

      if (requestData.exportType !== "googleDocs") {
        const link = document.createElement("a");
        const filename = response.headers["content-disposition"].split("=");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename.pop();
        link.click();
      }

      return blob.text();
    })
    .catch((error) => {
      message.error(error.message, 2);
      return undefined;
    });
};

export const getSemrushReport = (startDate: string, endDate: string) => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token;
  let params = "";

  if (startDate && !endDate) {
    params = `?startDate=${startDate}`;
  } else if (!startDate && endDate) {
    params = `?endDate=${endDate}`;
  } else if (startDate && endDate) {
    params = `?startDate=${startDate}&endDate=${endDate}`;
  }

  return axios
    .get(`${API_URL}/semrush/report${params}`, {
      headers: {
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const getBrandDescription = (brandURL: string) => {
  return axios
    .get(`${API_URL}${BRANDS_URL}/fetch-description?brandUrl=${brandURL}`, apiConfig())
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const getRegions = () => {
  return axios
    .get(`${API_URL}/locations?type=region`, apiConfig())
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const getBrandsByIndustry = (industrySlug: string, filters: any) => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;

  return axios
    .post(`${API_URL}/industries/${industrySlug}`, filters, {
      headers: {
        "Content-Type": "application/json",
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const getCategories = () => {
  return axios
    .get(`${API_URL}/categories`, apiConfig())
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const addCategory = (category: any) => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;
  const data = JSON.stringify(category);

  return axios
    .post(`${API_URL}/categories`, data, {
      headers: {
        "Content-Type": "application/json",
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const updateCategory = (categoryID: string, category: any) => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;
  const data = JSON.stringify(category);

  return axios
    .put(`${API_URL}/categories/${categoryID}`, data, {
      headers: {
        "Content-Type": "application/json",
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const deleteCategory = (category: any) => {
  return axios
    .delete(`${API_URL}/categories/${category}`, apiConfig())
    .then((result) => result.data)
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const refreshAllKeywords = () => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;

  return axios
    .post(`${API_URL}/brands/refresh-category-scores`, null, {
      headers: {
        "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result: any) => {
      return result;
    })
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const refreshBrandKeywords = (brandID: string) => {
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;

  return axios
    .post(`${API_URL}/brands/refresh-category-scores/${brandID}`, null, {
      headers: {
        "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result: any) => {
      return result;
    })
    .catch((error) => {
      message.error(error.message, 2);
    });
};

export const uploadBrandKeywords = (file: any, brandId: string) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("location", "KEYWORDS");
  formData.append("brandId", brandId);
  const state = store.getState();
  const API_VERSION = state.apiVersion;
  const API_KEY = state.user.token || PUBLIC_API_KEY;

  return axios
    .post(`${API_URL}${UPLOAD_URL}`, formData, {
      headers: {
        "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "X-LOOMI-KEY": API_KEY,
        "X-API-VERSION": API_VERSION,
      },
    })
    .then((result: any) => {
      return result;
    })
    .catch((error) => {
      message.error(error.message, 2);
    });
};
