import React, { useState, useEffect } from "react";
import { Tree, Input } from "antd";
import "antd/dist/antd.css";
import { Navigation } from "../../components/Navigation/Navigation";
import Navbar from "../../components/Navbar/Navbar";
import {
  getParentKey,
  loop,
  populateProducts,
  populateServices,
  populateIndustries,
} from "./Taxonomies.utils";

const { DirectoryTree } = Tree;
const { Search } = Input;

const Taxonomies: React.FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [searchService, setSearchService] = useState("");
  const [originalProductData, setOriginaProductData] = useState([] as any);
  const [originalServiceData, setOriginaServiceData] = useState([] as any);
  const [treeData, setTreeData] = useState([] as any);
  const [prtreeData, setPrtreeData] = useState([] as any);
  const [srtreeData, setSrtreeData] = useState([] as any);
  const [activeTab, setActiveTab] = useState("products");
  const [treeProductLeafs, setTreeProductLeafs] = useState([] as any);
  const [treeServiceLeafs, setTreeServiceLeafs] = useState([] as any);
  const [industryId, setIndustryId] = useState("");
  const [expandedKeys, setExpandedKeys] = useState([] as any);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [dataList, setDataList] = useState([] as any);

  const onChangeSearch = (e: any) => {
    const { value } = e.target;

    if (value === "") {
      setExpandedKeys([]);
      setAutoExpandParent(false);
    } else {
      const keys = dataList
        .map((item: any) => {
          if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
            return getParentKey(item.key, treeData);
          }
          return null;
        })
        .filter((item: any, i: any, self: any) => item && self.indexOf(item) === i);

      setExpandedKeys(keys);
      setAutoExpandParent(true);
    }

    setSearchValue(value);
    setSrtreeData([] as any);
    setPrtreeData([] as any);
    setIndustryId("");
  };

  const onChangeProduct = (e: any) => {
    const { value } = e.target;
    setSearchProduct(value);
    if (value.length < 3) {
      setPrtreeData(originalProductData);
    } else {
      const leafsPrTree = treeProductLeafs.filter((node: any) =>
        node.title.toLowerCase().includes(value.toLowerCase())
      );
      setPrtreeData(leafsPrTree);
    }
  };

  const onChangeService = (e: any) => {
    const { value } = e.target;
    setSearchService(value);
    if (value.length < 3) {
      setSrtreeData(originalServiceData);
    } else {
      const leafsSrTree = treeServiceLeafs.filter((node: any) =>
        node.title.toLowerCase().includes(value.toLowerCase())
      );
      setSrtreeData(leafsSrTree);
    }
  };

  const onSelectIndustryTree = (selectedKey: any, info: any) => {
    setIndustryId(info.node.key);
  };

  useEffect(() => {
    populateProducts(industryId, setPrtreeData, setOriginaProductData, setTreeProductLeafs);
    populateServices(industryId, setSrtreeData, setOriginaServiceData, setTreeServiceLeafs);
  }, [industryId]);

  useEffect(() => {
    populateIndustries(setTreeServiceLeafs, setTreeProductLeafs, setTreeData, setDataList);
  }, []);

  const onExpand = (keys: any) => {
    setExpandedKeys(keys);
    setAutoExpandParent(false);
  };

  return (
    <React.Fragment>
      <Navbar />
      <div className="section analysis">
        <Navigation />
        <main className="main-panel taxonomies-page">
          <section className="card">
            <h2 className="brand-name no-mgn">Taxonomies</h2>
          </section>
          <div className="brand-card for-review brand-txnmy">
            <div className="w-layout-grid compare-grid compare-grid-taxonomy">
              <div className="compare-block left">
                <div className="categories-bar taxonomy-heading">
                  <h1 className="brand-name orange btm-0">Industry</h1>
                </div>
                <div className="ant-tree-area">
                  <Search
                    className="taxonomies-search"
                    placeholder="Search..."
                    onChange={onChangeSearch}
                    defaultValue={searchValue}
                  />

                  <DirectoryTree
                    onSelect={onSelectIndustryTree}
                    treeData={loop(treeData, searchValue)}
                    autoExpandParent={autoExpandParent}
                    expandedKeys={expandedKeys}
                    onExpand={onExpand}
                  />
                </div>
              </div>

              <div className="compare-block  right-taxonomy">
                <div className="categories-bar taxonomy-heading">
                  <h1 className="brand-name orange btm-0"> Products and services </h1>

                  {industryId !== "" && (
                    <div className="button-container">
                      <button
                        type="button"
                        className={
                          activeTab === "products"
                            ? "compareTab taxbtn active"
                            : "compareTab taxbtn"
                        }
                        onClick={() => setActiveTab("products")}
                      >
                        Products
                      </button>
                      <button
                        type="button"
                        className={
                          activeTab === "services"
                            ? "compareTab taxbtn active"
                            : "compareTab taxbtn"
                        }
                        onClick={() => setActiveTab("services")}
                      >
                        Services
                      </button>
                    </div>
                  )}
                </div>

                <div className="product-service-card">
                  <div className="products">
                    {activeTab === "products" && treeProductLeafs.length > 0 && (
                      <div className="compare-block no-padding-top">
                        <Search
                          className="taxonomies-search"
                          placeholder="Search..."
                          onChange={onChangeProduct}
                          defaultValue={searchProduct}
                        />
                        <DirectoryTree treeData={prtreeData} />
                      </div>
                    )}
                    {activeTab === "products" &&
                      industryId !== "" &&
                      !treeProductLeafs.length &&
                      industryId !== null && (
                        <div className="compare-block no-padding-top">No products found.</div>
                      )}
                    {activeTab === "services" &&
                      industryId !== "" &&
                      !treeServiceLeafs.length &&
                      industryId !== null && (
                        <div className="compare-block no-padding-top">No services found.</div>
                      )}
                    {activeTab === "services" && treeServiceLeafs.length > 0 && (
                      <div className="compare-block no-padding-top">
                        <Search
                          className="taxonomies-search"
                          placeholder="Search..."
                          onChange={onChangeService}
                          defaultValue={searchService}
                        />
                        <DirectoryTree treeData={srtreeData} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};
export default Taxonomies;
