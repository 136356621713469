import React from "react";

import { shortenNumber } from "../../utils/utils";
import { ReactComponent as TrophyIcon } from "../../assets/images/icons/trophy.svg";

export function getArrayMax(array: any) {
  return Math.max.apply(null, array.filter(Number));
}

export function getArrayMin(array: any) {
  return Math.min.apply(null, array.filter(Number));
}

export const setOptions = (data: any, clickFunction: any) => {
  const options = [] as any;
  if (data && data.length > 0) {
    data.forEach((element: any) =>
      options.push(
        <a
          href="#"
          key={element.id}
          className="category-dropdown_link w-dropdown-link"
          onClick={() => clickFunction(element.name)}
        >
          {element.name}
        </a>
      )
    );
  } else {
    options.push(
      <a href="#" className="category-dropdown_link w-dropdown-link">
        No data
      </a>
    );
  }
  return options;
};

export const filterCompetitors = (
  brand: any,
  selectedIndustry: string,
  selectedProductService: string,
  selectedLocation: string
) => {
  let filteredCompetitors = brand.competitors;

  if (brand.competitors && brand.competitors.length > 0) {
    if (selectedIndustry && filteredCompetitors.length > 0) {
      const filteredByIndustry: any = [];

      filteredCompetitors.forEach((competitor: any) => {
        const industries =
          competitor?.industries?.filter(
            (industry: any) => industry.displayName === selectedIndustry
          ) || [];

        if (industries.length > 0) {
          filteredByIndustry.push(competitor);
        }
      });

      filteredCompetitors = filteredByIndustry;
    }

    if (selectedProductService && filteredCompetitors.length > 0) {
      const filteredByProductService: any = [];

      filteredCompetitors.forEach((competitor: any) => {
        const products =
          competitor?.products?.filter((product: any) => product.name === selectedProductService) ||
          [];
        const services =
          competitor?.services?.filter((service: any) => service.name === selectedProductService) ||
          [];

        if (products.length > 0 || services.length > 0) {
          filteredByProductService.push(competitor);
        }
      });

      filteredCompetitors = filteredByProductService;
    }

    if (selectedLocation && filteredCompetitors.length > 0) {
      const filteredByLocation: any = [];

      filteredCompetitors.forEach((competitor: any) => {
        const locations =
          competitor?.locations?.filter((location: any) => location.name === selectedLocation) ||
          [];

        if (locations.length > 0) {
          filteredByLocation.push(competitor);
        }
      });

      filteredCompetitors = filteredByLocation;
    }
  }

  return [...filteredCompetitors];
};

const columnSorter = (a: any, b: any) => {
  const aVal = a.length > 0 ? a[0] : 0;
  const bVal = b.length > 0 ? b[0] : 0;

  return aVal - bVal;
};

export const getColumns = (bestScoreSelectors: any, rows: any) => {
  const cellRenderer = (data: any, metric: string) => {
    if (data[0] === Number(bestScoreSelectors[metric]) && rows.length > 0 && data !== 0) {
      return (
        <div className="winner-brand-container">
          <span>
            <TrophyIcon className="trophy-icon" />{" "}
          </span>
          {shortenNumber(data)}
        </div>
      );
    }

    if (typeof data[0] === "string") {
      return <div data-tag="allowRowEvents">{data}</div>;
    }

    return <div data-tag="allowRowEvents">{data !== 0 ? shortenNumber(data) : "N/A"}</div>;
  };

  const columns = [
    {
      name: "Brand",
      selector: "name",
      sortable: true,
      grow: 2,
    },
    {
      name: "TOP TRAFFIC SOURCES",
      selector: "topTrafficSources",
      cell: (row: any) => cellRenderer(row.topTrafficSources, "topTrafficSources"),
    },
    {
      name: "MONTHLY VISITS",
      selector: "monthlyVisits",
      sortable: true,
      sortFunction: (a: any, b: any) => columnSorter(a.monthlyVisits, b.monthlyVisits),
      cell: (row: any) => cellRenderer(row.monthlyVisits, "monthlyVisits"),
    },
    {
      name: "BOUNCE RATE",
      selector: "bounceRate",
      sortable: true,
      sortFunction: (a: any, b: any) => columnSorter(a.bounceRate, b.bounceRate),
      cell: (row: any) => cellRenderer(row.bounceRate, "bounceRate"),
    },
    {
      name: "BACKLINKS",
      selector: "backlinks",
      sortable: true,
      sortFunction: (a: any, b: any) => columnSorter(a.backlinks, b.backlinks),
      cell: (row: any) => cellRenderer(row.backlinks, "backlinks"),
    },
    {
      name: "KEYWORDS",
      selector: "keywords",
      sortable: true,
      sortFunction: (a: any, b: any) => columnSorter(a.keywords, b.keywords),
      cell: (row: any) => cellRenderer(row.keywords, "organicKeywords"),
    },
    {
      name: "FOLLOWERS",
      selector: "followers",
      sortable: true,
      sortFunction: (a: any, b: any) => columnSorter(a.followers, b.followers),
      cell: (row: any) => cellRenderer(row.followers, "followers"),
    },
    {
      name: "AD BUDGET",
      selector: "adBudget",
      sortable: true,
      sortFunction: (a: any, b: any) => columnSorter(a.adBudget, b.adBudget),
      cell: (row: any) => cellRenderer(row.adBudget, "adBudget"),
    },
    {
      name: "WEB SCORE",
      selector: "webScore",
      sortable: true,
      right: true,
      grow: 1.6,
      sortFunction: (a: any, b: any) => columnSorter(a.webScore, b.webScore),
      cell: (row: any) => (
        <div data-tag="allowRowEvents" className="web-score-stats-block">
          <div className="digital-analysis-card_stats-wrap">
            <span className="digital-analysis-card_text blod">
              {cellRenderer(row.webScore, "webScore")}
            </span>
          </div>
          <div className="web-score-bar">
            <div className="web-score-bar-meter" style={{ width: `${row.webScore}%` }} />
          </div>
        </div>
      ),
    },
  ];

  return columns;
};

const getStatValue = (stats: any, statName: string) => {
  const values = stats.filter((s: any) => s.id === statName);

  if (values.length > 0) {
    return values[0].value[0];
  }

  return 0;
};

export const sortCompetitors = (comps: any, sorting: any) => {
  const values: any = [];
  let value = 0;

  comps.forEach((comp: any) => {
    switch (sorting.name) {
      case "topTrafficSources":
        value = getStatValue(comp.analytics[0].organic, "topTrafficSources");
        break;
      case "monthlyVisits":
        value = getStatValue(comp.analytics[0].general, "monthlyVisitors");
        break;
      case "bounceRate":
        value = getStatValue(comp.analytics[0].general, "bounceRate");
        break;
      case "backlinks":
        value = getStatValue(comp.analytics[0].organic, "backlinks");
        break;
      case "adBudget":
        value = getStatValue(comp.analytics[0].paid, "adBudget");
        break;
      case "keywords":
        value = getStatValue(comp.analytics[0].organic, "organicKeywords");
        break;
      case "webScore":
        value = getStatValue(comp.analytics[0].general, "webScore");
        break;
      case "followers":
        value = getStatValue(comp.analytics[0].organic, "followers");
        break;
      default:
        break;
    }

    values.push({ id: comp.id, value });
  });

  values.sort((a: any, b: any) => {
    if (sorting.direction === "asc") {
      return a.value - b.value;
    }

    return b.value - a.value;
  });

  return values.map((competitor: any) => competitor.id);
};
