import React, { useState } from "react";
import { Modal, Button } from "antd";

import { exportBrand } from "../../../API/api";
import { getFilteredCompetitors, toggleDropdown } from "../../../utils/utils";

interface ExportBrandDropdownProps {
  brand: any;
  filters: any;
}

export const ExportBrandDropdown: React.FC<ExportBrandDropdownProps> = ({
  brand,
  filters,
}: ExportBrandDropdownProps) => {
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [exportLink, setExportLink] = useState("");

  const getExport = (exportType: string) => {
    setIsExportModalVisible(true);
    setExportLoading(true);
    setExportLink("");

    const filterdCompetitors = getFilteredCompetitors(brand, filters);
    const competitorIds = filterdCompetitors.map((competitor: any) => competitor.id);

    const data = {
      location: "overview",
      competitorIds,
      exportType,
    };

    exportBrand(data, brand.id).then((response: any) => {
      if (exportType === "googleDocs") {
        setExportLink(response);
      }

      setExportLoading(false);
    });
  };

  return (
    <>
      <div
        className="category-dropdown_toggle orange w-dropdown-toggle"
        onClick={(e) => toggleDropdown(e.target)}
        onKeyDown={(e) => toggleDropdown(e.target)}
        tabIndex={0}
        role="button"
      >
        <div className="w-icon-dropdown-toggle" style={{ color: "#fff" }} />
        <a style={{ textDecoration: "none", color: "#fff" }} href="#">
          Export
        </a>
      </div>
      <nav className="category-dropdown_list w-dropdown-list">
        <a
          href="#"
          onClick={() => getExport("googleDocs")}
          className="category-dropdown_link w-dropdown-link"
        >
          Google Slides
        </a>
        <a
          href="#"
          onClick={() => getExport("pdf")}
          className="category-dropdown_link w-dropdown-link"
        >
          PDF
        </a>
        <a
          href="#"
          onClick={() => getExport("png")}
          className="category-dropdown_link w-dropdown-link"
        >
          Images
        </a>
      </nav>
      <Modal
        className="exportModal"
        visible={isExportModalVisible}
        confirmLoading={exportLoading}
        closable={false}
        onOk={() => setIsExportModalVisible(false)}
        footer={[
          <Button
            type="primary"
            key="ok"
            onClick={() => setIsExportModalVisible(false)}
            loading={exportLoading}
          >
            Ok
          </Button>,
        ]}
      >
        {exportLink ? (
          <>
            <p>Here is your exported file:</p>
            <p>
              <a href={exportLink} target="_blank" rel="noreferrer">
                {exportLink}
              </a>
            </p>
          </>
        ) : (
          <p>Your export file is being processed, please wait.</p>
        )}
      </Modal>
    </>
  );
};
