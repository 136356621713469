import React, { useState, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AutoSuggest from "react-autosuggest";
import PropTypes from "prop-types";
import { message } from "antd";

import { Location } from "../../../classes/locationClass";
import { Industry } from "../../../classes/industrieClass";
import { anyMmatch } from "../../../utils/utils";
import { getLocationsOrIndustriesAutocomplete } from "../../../API/api";

const AutosuggestHighlightParse = require("autosuggest-highlight/parse");

interface IProps {
  query: ReactNode;
}

interface SearchProps {
  children?: ReactNode;
  id?: string;
  webflowStyle?: string;
  placeholder?: string;
  addItemSearch?: any;
  inputValue?: string;
  disabled?: boolean;
  excludes?: string[];
  type: string;
  style?: any;
}

const Search = ({
  id,
  webflowStyle,
  placeholder,
  excludes,
  disabled,
  addItemSearch,
  inputValue = "",
  type,
  children,
  style,
}: SearchProps) => {
  const [input, setInput] = useState(inputValue);
  const [textHelper, setTextHelper] = useState("");
  const [notFoundLocationText] = useState("Location not found");
  const [notFoundIndustrieText] = useState("Industry not found");
  const [notFound, setNotFound] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null) as any;
  const [suggestionsLocations, setSuggestionsLocations] = useState<Location[]>([]);
  const [suggestionsIndustries, setSuggestionsIndustries] = useState<Industry[]>([]);
  const location = useLocation();

  const searchAutoCompleteCallback = (val: string) => {
    if (type === "locations") {
      return {
        success: (response: []) => {
          const locations = response.map((loc: any) => new Location(loc));
          const apiLocationsFiltered = locations.filter((loc: Location) => loc.hasInput(val));
          const notFoundLocation = new Location({
            name: notFoundLocationText,
          });
          if (!apiLocationsFiltered.length) {
            setSuggestionsLocations([notFoundLocation]);
            setNotFound(true);
          } else {
            setSuggestionsLocations(apiLocationsFiltered);
            setNotFound(false);
          }
        },
        error: (error: any) => {
          message.error(error, 2);
        },
      };
    }
    return {
      success: (response: []) => {
        const industries = response.map((loc: any) => new Industry(loc));
        const apiIndustriesFiltered = industries.filter((loc: Industry) => loc.hasInput(val));
        const notFoundIndustries = new Industry({
          city: notFoundIndustrieText,
        });
        if (!apiIndustriesFiltered.length) {
          setSuggestionsIndustries([notFoundIndustries]);
          setNotFound(true);
        } else {
          setSuggestionsIndustries(apiIndustriesFiltered);
          setNotFound(false);
        }
      },
      error: (error: any) => {
        message.error(error, 2);
      },
    };
  };

  const getSuggestions = (val: string) => {
    if (excludes && excludes.length > 0) {
      getLocationsOrIndustriesAutocomplete(
        searchAutoCompleteCallback(val),
        {
          search: val,
          exclude: excludes.toString(),
        },
        type
      );
    } else {
      getLocationsOrIndustriesAutocomplete(searchAutoCompleteCallback(val), { search: val }, type);
    }
  };

  const renderSuggestion = (suggestion: any, { query }: IProps) => {
    const suggestionText = suggestion.name;
    const matches = anyMmatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
      <div className="search-results_result-box w-inline-block" style={{ zIndex: 1000 }}>
        <div>
          <span>
            {parts.map((part: any, index: number) => {
              const className = part.highlight ? "suggestion-content__highlight" : "";
              const key = `suggestion${index}`;
              return (
                <span className={className} key={key}>
                  {part.text}
                </span>
              );
            })}
            {type === "locations" && !notFound && (
              <span>
                <div style={{ fontSize: "14px" }}>{`${suggestion.fullLocation}`}</div>
              </span>
            )}
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setInput(inputValue);
  }, [inputValue]);

  const onInputBlur = () => {
    if (input === "") {
      addItemSearch({ id: "clear" });
    } else if (selectedSuggestion?.name && selectedSuggestion?.name !== input) {
      setInput(selectedSuggestion.name);
    }
  };

  return (
    <div className="react-autosuggest__container" style={style}>
      <div className="react-autosuggest__wrapper">
        {type === "locations" ? (
          <AutoSuggest
            suggestions={suggestionsLocations}
            onSuggestionsClearRequested={() => {
              setSuggestionsLocations([]);
              setTextHelper("");
            }}
            onSuggestionsFetchRequested={({ value }) => {
              setInput(value);
              getSuggestions(value);
            }}
            onSuggestionSelected={(_e, { suggestion }) => {
              if (!notFound) {
                addItemSearch(suggestion);
                setSelectedSuggestion(suggestion);
              }
            }}
            getSuggestionValue={(suggestion: any) => {
              if (notFound) {
                return "";
              }
              return `${suggestion.name}`;
            }}
            renderSuggestion={renderSuggestion}
            inputProps={{
              placeholder,
              value: input,
              id,
              disabled,
              className: webflowStyle,
              onChange: (_, { newValue }) => setInput(newValue),
              onBlur: onInputBlur,
            }}
            highlightFirstSuggestion
          />
        ) : (
          <AutoSuggest
            suggestions={suggestionsIndustries}
            onSuggestionsClearRequested={() => {
              setSuggestionsLocations([]);
              setTextHelper("");
            }}
            onSuggestionsFetchRequested={({ value }) => {
              setInput(value);
              getSuggestions(value);
            }}
            onSuggestionSelected={(_e, { suggestion }) => {
              addItemSearch(suggestion);
              setSelectedSuggestion(suggestion);
            }}
            getSuggestionValue={(suggestion: any) => {
              if (notFound) {
                return "";
              }
              return `${suggestion.name}`;
            }}
            renderSuggestion={renderSuggestion}
            inputProps={{
              placeholder,
              value: input,
              id,
              className: webflowStyle,
              disabled,
              onChange: (_, { newValue }) => setInput(newValue),
              onBlur: onInputBlur,
            }}
            highlightFirstSuggestion
          />
        )}

        {children}
      </div>
      {textHelper && (
        <div
          style={{
            position: "absolute",
            color: `${location.pathname !== "/search" && "#fff"}`,
          }}
        >
          {textHelper}
        </div>
      )}
    </div>
  );
};

Search.propTypes = {
  id: PropTypes.string,
  webflowStyle: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

Search.defaultProps = {
  id: null,
  webflowStyle: "search-input w-input",
  placeholder: "Enter brand name",
  inputValue: "",
  addItemSearch: null,
  children: undefined,
  excludes: [],
  style: null,
  disabled: false,
};

export default Search;
