import React, { useState } from "react";
import { Table } from "antd";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getBrandBySlug } from "../../API/api";
import { setBrand } from "../../actions/actions";

interface Props {
  selectedBrands: any;
  setSelectedBrands: any;
}

export const DigitalMarketing: React.FC<Props> = ({ selectedBrands, setSelectedBrands }: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const industryBrands = useSelector((state: RootStateOrAny) => state.industryBrands);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToBrand = (brandSlug: string) => {
    getBrandBySlug(brandSlug).then((slugBrand: any) => {
      if (slugBrand === undefined) {
        const notFoundBrand = {
          name: "Not found",
          url: true,
          description: "Search for an specific brand using the search field at the top",
          image: "",
          analytics: {
            webScore: 0,
          },
          competitors: [],
        };
        dispatch(setBrand(notFoundBrand));
      } else {
        dispatch(setBrand(slugBrand));
        navigate(`../brands/${brandSlug}`, { replace: true });
      }
    });
  };

  const adBudgetColumns: any = [
    {
      title: "Brand",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "64px", height: "64px", display: "flex", alignItems: "center" }}>
              {record.logo && (
                <img
                  src={record.logo}
                  alt={record.name}
                  style={{ width: "64px", maxWidth: "64px", maxHeight: "64px" }}
                />
              )}
            </div>
            <button
              type="button"
              onClick={() => goToBrand(record.slug)}
              className="tableNameButton"
            >
              {record.name}
            </button>
          </div>
        );
      },
    },
    {
      title: "Ad budget",
      dataIndex: "name",
      render: () => {
        return (
          <div className="tableChart">
            <div className="value">XXX</div>
            <div className="chart" style={{ backgroundColor: "#15E1D5", width: "75%" }} />
          </div>
        );
      },
    },
  ];

  const backlinksColumns: any = [
    {
      title: "Brand",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "64px", height: "64px", display: "flex", alignItems: "center" }}>
              {record.logo && (
                <img
                  src={record.logo}
                  alt={record.name}
                  style={{ width: "64px", maxWidth: "64px", maxHeight: "64px" }}
                />
              )}
            </div>
            <button
              type="button"
              onClick={() => goToBrand(record.slug)}
              className="tableNameButton"
            >
              {record.name}
            </button>
          </div>
        );
      },
    },
    {
      title: "Number of backlinks",
      dataIndex: "name",
      render: () => {
        return (
          <div className="tableChart">
            <div className="value">XXX</div>
            <div className="chart" style={{ backgroundColor: "#15E1D5", width: "75%" }} />
          </div>
        );
      },
    },
  ];

  const keywordsColumns: any = [
    {
      title: "Brand",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "64px", height: "64px", display: "flex", alignItems: "center" }}>
              {record.logo && (
                <img
                  src={record.logo}
                  alt={record.name}
                  style={{ width: "64px", maxWidth: "64px", maxHeight: "64px" }}
                />
              )}
            </div>
            <button
              type="button"
              onClick={() => goToBrand(record.slug)}
              className="tableNameButton"
            >
              {record.name}
            </button>
          </div>
        );
      },
    },
    {
      title: "Organic keywords",
      dataIndex: "name",
      render: () => {
        return (
          <div className="tableChart">
            <div className="value">XXX</div>
            <div className="chart" style={{ backgroundColor: "#15E1D5", width: "75%" }} />
          </div>
        );
      },
    },
  ];

  const onSelectBrands = (selectedRows: any) => {
    setSelectedBrands(selectedRows);
  };

  const rowSelection: any = {
    selectedBrands,
    hideSelectAll: true,
    onChange: onSelectBrands,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };

  return (
    <>
      <div className="tabs">
        <button
          className={activeTab === 0 ? "tabButton active" : "tabButton"}
          type="button"
          onClick={() => setActiveTab(0)}
        >
          Ad budget
        </button>
        <button
          className={activeTab === 1 ? "tabButton active" : "tabButton"}
          type="button"
          onClick={() => setActiveTab(1)}
        >
          Number of backlinks
        </button>
        <button
          className={activeTab === 2 ? "tabButton active" : "tabButton"}
          type="button"
          onClick={() => setActiveTab(2)}
        >
          Organic keywords
        </button>
      </div>
      <div className="tabContent">
        {activeTab === 0 && (
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            dataSource={industryBrands}
            columns={adBudgetColumns}
            sortDirections={["descend", "ascend"]}
            pagination={{
              pageSize: 100,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
          />
        )}
        {activeTab === 1 && (
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            dataSource={industryBrands}
            columns={backlinksColumns}
            sortDirections={["descend", "ascend"]}
            pagination={{
              pageSize: 100,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
          />
        )}
        {activeTab === 2 && (
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            dataSource={industryBrands}
            columns={keywordsColumns}
            sortDirections={["descend", "ascend"]}
            pagination={{
              pageSize: 100,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
          />
        )}
      </div>
    </>
  );
};
