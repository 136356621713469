import React from "react";

interface DetailsProps {
  title: string;
  text: string;
}

const HelpTipSection: React.FC<DetailsProps> = ({ title, text }: DetailsProps) => {
  return (
    <div className="help-tip-section">
      <div className="help-tip_c-wrapper">
        <div className="icon w-embed">
          <svg
            width="22"
            height="33"
            viewBox="0 0 22 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.3849 23.5254C15.8631 21.9315 16.8195 20.4969 18.0947 19.3811C20.4857 17.3885 22 14.36 22 11.0126C22 4.79612 16.8196 -0.304643 10.4436 0.0141781C4.78494 0.253275 0.162374 5.03522 0.00300054 10.6938C-0.0767234 14.2006 1.43758 17.3089 3.90828 19.3811C5.26313 20.4968 6.21952 21.9314 6.69772 23.5254H15.3849Z"
              fill="#FFD15C"
            />
            <path
              d="M9.96527 23.6848H10.6826L7.49459 11.8097C7.57431 11.8097 7.65396 11.8097 7.73368 11.8097C8.13215 11.8097 8.5307 11.6503 8.84944 11.3315C9.00881 11.1721 9.16826 11.0924 9.40736 11.0924C9.64645 11.0924 9.80583 11.1721 9.96527 11.3315C10.5232 11.969 11.4796 11.969 12.0374 11.3315C12.1968 11.1721 12.3562 11.0924 12.5953 11.0924C12.7547 11.0924 12.9938 11.1721 13.1532 11.3315C13.4721 11.6503 13.7908 11.8097 14.269 11.8097C14.3487 11.8097 14.4284 11.8097 14.5081 11.8097L11.3999 23.6848H12.1171L15.3848 11.2517C15.3848 11.0924 15.3848 10.9329 15.2254 10.8533C15.066 10.7735 14.9066 10.8533 14.8269 10.933C14.6676 11.0924 14.5081 11.1721 14.3487 11.1721C14.1096 11.1721 13.9503 11.0924 13.7112 10.933C13.3923 10.6142 13.0736 10.4548 12.6751 10.4548C12.2765 10.4548 11.9578 10.6142 11.6389 10.933C11.3201 11.2518 10.8419 11.2518 10.5232 10.933C10.2841 10.6142 9.88562 10.4548 9.48708 10.4548C9.08861 10.4548 8.69007 10.6142 8.45097 10.933C8.2916 11.0924 8.0525 11.1721 7.81341 11.1721C7.65403 11.1721 7.41494 11.0924 7.33521 10.933C7.25549 10.8533 7.09612 10.7736 6.93674 10.8533C6.77737 10.933 6.69765 11.0924 6.77737 11.2517L9.96527 23.6848Z"
              fill="white"
            />
            <path
              d="M8.5307 30.8577C8.92925 31.8141 9.88564 32.5314 11.0014 32.5314C12.1172 32.5314 13.0736 31.8141 13.4721 30.8577H8.5307Z"
              fill="#344A5E"
            />
            <path
              d="M13.9503 30.9374H8.13228C7.33526 30.9374 6.69769 30.2998 6.69769 29.5028V23.4457H15.3849V29.5028C15.3849 30.2998 14.7473 30.9374 13.9503 30.9374Z"
              fill="#344A5E"
            />
            <path
              d="M15.3052 26.4742H6.69771C6.21951 26.4742 5.82104 26.0758 5.82104 25.5976C5.82104 25.1194 6.21951 24.7209 6.69771 24.7209H15.3052C15.7834 24.7209 16.1818 25.1194 16.1818 25.5976C16.1818 26.0758 15.7834 26.4742 15.3052 26.4742Z"
              fill="#415A6B"
            />
            <path
              d="M15.3052 29.2637H6.69771C6.21951 29.2637 5.82104 28.8653 5.82104 28.3871C5.82104 27.9089 6.21951 27.5104 6.69771 27.5104H15.3052C15.7834 27.5104 16.1818 27.9089 16.1818 28.3871C16.1818 28.8652 15.7834 29.2637 15.3052 29.2637Z"
              fill="#415A6B"
            />
          </svg>
        </div>
        <div className="form_field-label">{title}</div>
        <p className="help-tip_para">{text}</p>
      </div>
    </div>
  );
};

export default HelpTipSection;
