import React from "react";
import { Bar } from "@ant-design/plots";

import { toggleDropdown } from "../../../utils/utils";
import { Icons } from "../../Icons/Icons";

interface ESGDropdownProps {
  activeESG: any;
  setActiveESG: any;
}

export const ESGAnalytics: React.FC<ESGDropdownProps> = ({
  activeESG,
  setActiveESG,
}: ESGDropdownProps) => {
  const getValue = () => {
    return Math.floor(Math.random() * 50) + 1;
  };

  const contentTrendsData = [
    {
      type: "Regulation/Reporting",
      value: getValue(),
    },
    {
      type: "Inclusive Development",
      value: getValue(),
    },
    {
      type: "Sustainable Investing",
      value: getValue(),
    },
    {
      type: "Ukraine Conflict",
      value: getValue(),
    },
    {
      type: "Climate Change",
      value: getValue(),
    },
    {
      type: "ESG Data",
      value: getValue(),
    },
    {
      type: "Climate Change Investing",
      value: getValue(),
    },
  ];

  const assetClassData = [
    {
      type: "Equities",
      value: getValue(),
    },
    {
      type: "Fixed Income",
      value: getValue(),
    },
    {
      type: "Cash",
      value: getValue(),
    },
    {
      type: "Real Estate",
      value: getValue(),
    },
    {
      type: "Commodities",
      value: getValue(),
    },
    {
      type: "Currencies",
      value: getValue(),
    },
  ];

  const esgInvestingData = [
    {
      type: "Exclusionary Screening",
      value: getValue(),
    },
    {
      type: "Positive Screening",
      value: getValue(),
    },
    {
      type: "ESG Integration",
      value: getValue(),
    },
    {
      type: "Impact Investing",
      value: getValue(),
    },
    {
      type: "Active Ownership",
      value: getValue(),
    },
  ];

  const futureTrends = [
    {
      type: "Biodiversity",
      value: getValue(),
    },
    {
      type: "Greenwashing",
      value: getValue(),
    },
    {
      type: "Sovereign Policy",
      value: getValue(),
    },
    {
      type: "Carbon",
      value: getValue(),
    },
  ];

  const DemoPie = () => {
    let data: any = [];

    if (activeESG === "Content Trends") {
      data = contentTrendsData;
    } else if (activeESG === "Asset Classes") {
      data = assetClassData;
    } else if (activeESG === "ESG Investing") {
      data = esgInvestingData;
    } else if (activeESG === "Future Trends") {
      data = futureTrends;
    }

    const config = {
      data,
      autoFit: true,
      xField: "value",
      yField: "type",
      seriesField: "type",
      legend: false,
    };

    // eslint-disable-next-line
    return <Bar {...config} />;
  };

  const changeAnalyticsTab = (tab: string) => {
    setActiveESG(tab);
  };

  return (
    <div style={{ marginTop: 30, marginBottom: 60 }}>
      <h4>ESG Analytics</h4>
      <section style={{ marginBottom: 30, display: "flex" }}>
        <div
          data-hover=""
          data-delay="0"
          className="category-dropdown w-dropdown"
          style={{ marginLeft: 0 }}
        >
          <div
            className="category-dropdown_toggle orange w-dropdown-toggle"
            onClick={(e) => toggleDropdown(e.target)}
            onKeyDown={(e) => toggleDropdown(e.target)}
            tabIndex={0}
            role="button"
          >
            <div className="w-icon-dropdown-toggle" style={{ color: "#fff" }} />
            <a
              className="tabSelector"
              href="#"
              title={activeESG !== "" ? activeESG : "ESG Overview"}
            >
              {activeESG !== "" ? activeESG : "ESG Overview"}
            </a>
          </div>
          <nav className="category-dropdown_list w-dropdown-list">
            <a
              href="#"
              onClick={() => changeAnalyticsTab("Overview")}
              className="category-dropdown_link w-dropdown-link"
            >
              Overview
            </a>
            <a
              href="#"
              onClick={() => changeAnalyticsTab("Content Trends")}
              className="category-dropdown_link w-dropdown-link"
            >
              Content Trends
            </a>
            <a
              href="#"
              onClick={() => changeAnalyticsTab("Asset Classes")}
              className="category-dropdown_link w-dropdown-link"
            >
              Asset Classes
            </a>
            <a
              href="#"
              onClick={() => changeAnalyticsTab("ESG Investing")}
              className="category-dropdown_link w-dropdown-link"
            >
              ESG Investing
            </a>
            <a
              href="#"
              onClick={() => changeAnalyticsTab("Future Trends")}
              className="category-dropdown_link w-dropdown-link"
            >
              Future Trends
            </a>
          </nav>
        </div>
      </section>

      {activeESG === "ESG Overview" && (
        <div className="brandanalysis-page" style={{ display: "flex" }}>
          <a
            href="#"
            className="card general"
            title="ESG score"
            key="ESG score"
            style={{ margin: "0.25em" }}
          >
            <div className="card-icon">
              <Icons name="ESG score" />
            </div>
            <span className="card-value">{getValue()}</span>
            <span className="card-name">ESG score</span>
          </a>

          <a
            href="#"
            className="card general"
            title="Total ranked ESG Keywords"
            key="Total ranked ESG Keywords"
            style={{ margin: "0.25em" }}
          >
            <div className="card-icon">
              <Icons name="ESG score" />
            </div>
            <span className="card-value">{getValue()}</span>
            <span className="card-name">Total ranked ESG Keywords</span>
          </a>

          <a
            href="#"
            className="card general"
            title="Total ESG Social Engagement"
            key="Total ESG Social Engagement"
            style={{ margin: "0.25em" }}
          >
            <div className="card-icon">
              <Icons name="ESG score" />
            </div>
            <span className="card-value">{getValue()}</span>
            <span className="card-name">Total ESG Social Engagement</span>
          </a>

          <a
            href="#"
            className="card general"
            title="ESG Ad Performance"
            key="ESG Ad Performance"
            style={{ margin: "0.25em" }}
          >
            <div className="card-icon">
              <Icons name="ESG score" />
            </div>
            <span className="card-value">{getValue()}</span>
            <span className="card-name">ESG Ad Performance</span>
          </a>

          <a
            href="#"
            className="card general"
            title="Top performing ESG content"
            key="Top performing ESG content"
            style={{ margin: "0.25em" }}
          >
            <div className="card-icon">
              <Icons name="ESG score" />
            </div>
            <span className="card-value">{getValue()}</span>
            <span className="card-name">Top performing ESG content</span>
          </a>
        </div>
      )}

      {activeESG !== "ESG Overview" && (
        <div className="analytics">
          <DemoPie />
        </div>
      )}
    </div>
  );
};
