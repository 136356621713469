import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { DatePicker, Modal } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";

import Navbar from "../components/Navbar/Navbar";
import { Navigation } from "../components/Navigation/Navigation";
import { getSemrushReport } from "../API/api";
import { shortenNumber } from "../utils/utils";
import { SearchBar } from "../components/SearchBar/SearchBar";

const Semrush: React.FC = () => {
  const user = useSelector((state: RootStateOrAny) => state.user);
  const [reportData, setReportData] = useState([]) as any;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    if (user.token) {
      getSemrushReport(startDate, endDate).then((data: any) => setReportData(data));
    }
  }, [startDate, endDate, user]);

  const getBreakdown = (breakdown: any) => {
    const list: any = [];

    Object.keys(breakdown).forEach(function eachKey(key) {
      list.push({
        name: key,
        usedUnits: shortenNumber(breakdown[key].usedUnits),
        lines: shortenNumber(breakdown[key].lines),
        unitsPerLine: shortenNumber(breakdown[key].unitsPerLine),
      });
    });

    return list;
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days after today and today
    return current && current > moment().endOf("day");
  };

  const onStartDateChange = (date: any, dateString: any) => {
    setStartDate(dateString);
  };

  const onEndDateChange = (date: any, dateString: any) => {
    setEndDate(dateString);
  };

  const viewDetails = (breakdown: any) => {
    Modal.info({
      title: "Breakdown details",
      content: (
        <div>
          {breakdown &&
            getBreakdown(breakdown).map((data: any) => (
              <div key={data.name}>
                <p>{data.name}</p>
                <ul>
                  <li>Used units: {data.usedUnits}</li>
                  <li>Lines: {data.lines}</li>
                  <li>Units per line: {data.unitsPerLine}</li>
                </ul>
              </div>
            ))}
        </div>
      ),
      onOk() {},
    });
  };

  return (
    <React.Fragment>
      <Navbar />
      <div className="section analysis">
        <Navigation />
        <main className="main-panel">
          <SearchBar />
          <section className="card">
            <h2 className="brand-name no-mgn">Semrush report</h2>
          </section>
          <section className="brand-card">
            <div className="brand-card_info">
              <div style={{ marginBottom: 20, width: "400px" }}>
                <DatePicker
                  placeholder="Start Date"
                  disabledDate={disabledDate}
                  defaultValue={startDate ? moment(startDate, dateFormat) : undefined}
                  format={dateFormat}
                  onChange={onStartDateChange}
                  style={{ marginRight: 10 }}
                />
                <DatePicker
                  placeholder="End Date"
                  disabledDate={disabledDate}
                  defaultValue={endDate ? moment(endDate, dateFormat) : undefined}
                  format={dateFormat}
                  onChange={onEndDateChange}
                />
              </div>

              {!startDate && !endDate && (
                <table className="semrush-report">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      {reportData.map((data: any) => (
                        <th key={data.date}>{data.date}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Total units</td>
                      {reportData.map((data: any) => (
                        <td key={data.date}>{shortenNumber(data.totalUnits)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Used units</td>
                      {reportData.map((data: any) => (
                        <td key={data.date}>{shortenNumber(data.usedUnits)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Remaining units</td>
                      {reportData.map((data: any) => (
                        <td key={data.date}>{shortenNumber(data.remainingUnits)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Remaining units percentage</td>
                      {reportData.map((data: any) => (
                        <td key={data.date}>{shortenNumber(data.remainingUnitsPercentage)}%</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Units per single brand</td>
                      {reportData.map((data: any) => (
                        <td key={data.date}>{shortenNumber(data.unitsPerSingleBrand)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Unique brand audits</td>
                      {reportData.map((data: any) => (
                        <td key={data.date}>{shortenNumber(data.uniqueBrandAudits)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Total number of audits</td>
                      {reportData.map((data: any) => (
                        <td key={data.date}>{shortenNumber(data.totalNumberOfAudits)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      {reportData.map((data: any) => (
                        <td key={data.date}>
                          <button type="button" onClick={() => viewDetails(data.breakdown)}>
                            Details
                          </button>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              )}

              {(startDate || endDate) && (
                <>
                  <p className="info-block_desc">
                    <strong>Total units:</strong> {shortenNumber(reportData[0].totalUnits)}
                  </p>
                  <p className="info-block_desc">
                    <strong>Used units:</strong> {shortenNumber(reportData[0].usedUnits)}
                  </p>
                  <p className="info-block_desc">
                    <strong>Remaining units:</strong> {shortenNumber(reportData[0].remainingUnits)}
                  </p>
                  <p className="info-block_desc">
                    <strong>Remaining units percentage:</strong>&nbsp;
                    {shortenNumber(reportData[0].remainingUnitsPercentage)}%
                  </p>
                  <p className="info-block_desc">
                    <strong>Units per single brand:</strong>&nbsp;
                    {shortenNumber(reportData[0].unitsPerSingleBrand)}
                  </p>
                  <p className="info-block_desc">
                    <strong>Unique brand audits:</strong>&nbsp;
                    {shortenNumber(reportData[0].uniqueBrandAudits)}
                  </p>
                  <p className="info-block_desc">
                    <strong>Total number of audits:</strong>&nbsp;
                    {shortenNumber(reportData[0].totalNumberOfAudits)}
                  </p>
                  <p className="info-block_desc">
                    <strong>Breakdown:</strong>
                  </p>

                  {getBreakdown(reportData[0].breakdown).map((data: any) => (
                    <div key={data.name}>
                      <p>{data.name}</p>
                      <ul>
                        <li>Used units: {shortenNumber(data.usedUnits)}</li>
                        <li>Lines: {shortenNumber(data.lines)}</li>
                        <li>Units per line: {shortenNumber(data.unitsPerLine)}</li>
                      </ul>
                    </div>
                  ))}
                </>
              )}
            </div>
          </section>
        </main>
      </div>
    </React.Fragment>
  );
};

export default Semrush;
