import React from "react";

import { shortenNumber } from "../../utils/utils";
import { ReactComponent as ArrowUp } from "../../assets/images/icons/arrow-up-circle.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/icons/arrow-down-circle.svg";

interface Entry {
  id: string;
  value: any;
  title: string;
  name: string;
  unit: string;
}

export const matchesName = (itemsArray: [], name: string) => {
  return itemsArray.find((prod: any) => prod.name === name);
};

const getRowDifference = (difference: any) => {
  if (difference < 0) {
    return (
      <>
        <ArrowDown style={{ marginRight: 5, position: "absolute", left: 3 }} />
        {shortenNumber(difference)} %
      </>
    );
  }

  if (difference === 0) {
    return <>{shortenNumber(difference)} %</>;
  }

  if (difference > 0) {
    return (
      <>
        <ArrowUp style={{ marginRight: 5, position: "absolute", left: 3 }} />
        {shortenNumber(difference)} %
      </>
    );
  }

  if (typeof difference === "string") {
    return (
      <>
        <ArrowUp style={{ marginRight: 5, position: "absolute", left: 3 }} />
        {difference} %
      </>
    );
  }

  return <>N/A</>;
};

export const getColumns = (brand: any, activeTab: string) => {
  const columns = [
    {
      name: "",
      selector: "name",
      sortable: true,
    },
    {
      name: activeTab === "website" ? "Values" : "Actual",
      selector: "actual",
      center: true,
      width: activeTab === "website" ? "auto" : "75px",
    },
    {
      name: "Last",
      selector: "last",
      center: true,
      omit: activeTab === "website",
      width: "75px",
    },
    {
      name: "Change %",
      selector: "difference",
      sortable: true,
      right: true,
      width: "75px",
      omit: activeTab === "website",
      sortFunction: (a: any, b: any) => a.difference - b.difference,
      cell: (row: any) => {
        return (
          <div
            data-tag="allowRowEvents"
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            {getRowDifference(row.difference)}
          </div>
        );
      },
    },
  ];

  const columnsNoLastAndChange = columns
    .filter((col: any) => col.selector !== "last")
    .filter((col: any) => col.selector !== "difference");
  const brandSelectedColumns =
    brand.analytics && brand.analytics.length > 1 ? columns : columnsNoLastAndChange;

  return brandSelectedColumns;
};

export const getAnalyticsData = (brand: any, activeTab: string) => {
  const analyticsData: any = [];
  const analyticsActual: any[] =
    brand.analytics && brand.analytics[0] ? brand.analytics[0][activeTab] : [];
  const analyticsLast: any[] =
    brand.analytics && brand.analytics[1] ? brand.analytics[1][activeTab] : [];

  if (analyticsActual) {
    analyticsActual.forEach((entry: Entry, index) => {
      const actualValue = parseInt(entry.value, 10);
      const pastValue = analyticsLast[index] ? parseInt(analyticsLast[index].value, 10) : 0;
      const difference = actualValue - pastValue;
      const differenceTotal = (difference * 100) / pastValue;
      const dataDifference = pastValue !== 0 ? differenceTotal : 0;

      if (entry.id !== "webScore") {
        let data;
        if (entry.id === "topReferrers" || entry.id === "topCountries") {
          data = {
            id: index,
            name: entry.name,
            actual: `${entry.value.join(", ")}`,
            last: `${shortenNumber(pastValue)} ${
              analyticsLast[index] ? analyticsLast[index].unit : ""
            }`,
            difference: dataDifference,
          };
        } else {
          data = {
            id: index,
            name: entry.name,
            actualValue,
            actual: `${shortenNumber(actualValue)} ${entry.unit}`,
            last: `${shortenNumber(pastValue)} ${
              analyticsLast[index] ? analyticsLast[index].unit : ""
            }`,
            difference: dataDifference,
          };
        }
        analyticsData.push(data);
      } else {
        const data = {
          id: index,
          name: entry.name,
          actual: `${shortenNumber(actualValue)} ${entry.unit}`,
          actualValue,
          last: `${shortenNumber(pastValue)} ${
            analyticsLast[index] ? analyticsLast[index].unit : ""
          }`,
          difference: dataDifference,
        };
        analyticsData.push(data);
      }
    });
  }

  return analyticsData;
};
