import React, { useState, ReactNode, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Alert, message } from "antd";
import AutoSuggest from "react-autosuggest";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Brand, BrandDto } from "../../../classes/brandClass";
import { anyMmatch, getImageURL } from "../../../utils/utils";
import { getBrandsAutocomplete, getBrandById } from "../../../API/api";
import { setBrand, setUserInput, setCompetitor, setIndustry } from "../../../actions/actions";

import notFoundImage from "../../../assets/images/lupa.png";

const AutosuggestHighlightParse = require("autosuggest-highlight/parse");

interface IProps {
  query: ReactNode;
}
interface SearchProps {
  children?: ReactNode;
  id?: string;
  webflowStyle?: string;
  placeholder?: string;
  searchValue?: string;
  addBrandSearch?: any;
  addManualCompetitor?: any;
  mainBrand?: any;
  addCompetitors?: boolean;
  excludes?: string[];
  suggestionSelected?: any;
}

const Search = ({
  id,
  webflowStyle,
  placeholder,
  addBrandSearch,
  addManualCompetitor,
  excludes,
  searchValue = "",
  children,
  mainBrand,
  addCompetitors,
  suggestionSelected,
}: SearchProps) => {
  const [input, setInput] = useState(searchValue);
  const [textHelper, setTextHelper] = useState("");
  const [notFoundText] = useState("Brand not found, click here to add it");
  const [fieldsCompetitorsEmpty, setFieldsCompetitorsEmpty] = useState(false);
  const [suggestions, setSuggestions] = useState<Brand[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (addManualCompetitor) {
      addBrandSearch(addManualCompetitor);
    }
  }, [addManualCompetitor]);

  const searchAutoCompleteCallback = (val: string) => {
    return {
      success: (response: []) => {
        const dataBranded = response.map((brand: BrandDto) => new Brand(brand));
        const apiBrandsFiltered = dataBranded.filter((brand: Brand) => brand.hasInput(val));
        const notFoundBrand = new Brand({ name: notFoundText, logo: notFoundImage });
        if (!apiBrandsFiltered.length) {
          setSuggestions([notFoundBrand]);
        } else {
          setSuggestions(apiBrandsFiltered);
        }
      },
      error: (error: any) => {
        message.error(error, 2);
      },
    };
  };

  const getSuggestions = (val: string) => {
    if (excludes && excludes.length > 0) {
      getBrandsAutocomplete(searchAutoCompleteCallback(val), {
        search: val,
        exclude: excludes.toString(),
      });
    } else {
      getBrandsAutocomplete(searchAutoCompleteCallback(val), { search: val });
    }
  };

  const renderSuggestion = (suggestion: any, { query }: IProps) => {
    const suggestionText = `${suggestion.name}`;
    const matches = anyMmatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
      <div className="search-results_result-box w-inline-block">
        <div className="search-results_brand-logo">
          <img
            src={getImageURL(suggestion.logo, suggestion.image)}
            loading="lazy"
            alt={suggestion.name}
          />
        </div>
        <div style={{ maxWidth: "74%" }}>
          <span>
            {parts.map((part: any, index: number) => {
              const className = part.highlight ? "suggestion-content__highlight" : "";
              const key = `suggestion${index}`;

              return (
                <span className={className} key={key}>
                  {part.text}
                </span>
              );
            })}
          </span>
          {suggestion.url && (
            <div style={{ maxWidth: "100%" }}>
              <span className="url-text">{`${suggestion.url}`.toUpperCase()}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const checkCompetitorsValues = (competitor: any) => {
    const competitorsFields = {} as any;
    competitorsFields[competitor.name] = [];
    const fields = ["products", "services", "locations", "industries"];
    const mainBrandFields = ["productIds", "serviceIds", "locationIds", "industryIds"];

    fields.forEach((field: string, index: number) => {
      if (competitor[field]?.length) {
        competitor[field].forEach((element: any) => {
          if (mainBrand && mainBrand[mainBrandFields[index]]?.indexOf(element.id) >= 0) {
            competitorsFields[competitor.name] = [...competitorsFields[competitor.name], field];
          }
        });
      }
    });

    if (competitorsFields[competitor.name].length === 0) {
      setFieldsCompetitorsEmpty(true);
      setTimeout(() => {
        setFieldsCompetitorsEmpty(false);
      }, 3000);
    }
    return competitorsFields;
  };

  return (
    <div className="react-autosuggest__container">
      <div className="react-autosuggest__wrapper">
        <AutoSuggest
          suggestions={suggestions}
          onSuggestionsClearRequested={() => {
            setSuggestions([]);
            setTextHelper("");
            if (!input && addBrandSearch) {
              addBrandSearch({ brandId: "clear", name: "clear" });
            }
          }}
          onSuggestionsFetchRequested={({ value }) => {
            setInput(value);
            getSuggestions(value);
          }}
          onSuggestionSelected={(_, { suggestion }) => {
            dispatch(
              setCompetitor({
                name: "",
                description: "",
                image: "",
                analytics: {
                  webScore: 0,
                },
                competitors: [],
              })
            );
            if (suggestionSelected) {
              suggestionSelected();
            }
            if (suggestion.name === notFoundText) {
              const emptyBrand = {
                name: "",
                description: "",
                image: "",
                analytics: {
                  webScore: 0,
                },
                competitors: [],
                competitorIds: [],
                locations: [],
                locationIds: [],
                industries: [],
                industryIds: [],
                services: [],
                products: [],
              };

              dispatch(setBrand(emptyBrand));
              dispatch(setCompetitor(emptyBrand));
              localStorage.setItem("brandSlug", "");
              dispatch(setUserInput(input));
              navigate(`../brands/new`, { replace: true });
            } else if (suggestion.id) {
              getBrandById(suggestion.id).then((brand: any) => {
                const brandEdited = { ...brand };
                if (brand?.competitorIds?.length) {
                  brandEdited.competitorIds = brand.competitorIds.sort((a: any, b: any) => {
                    if (a.brandId === b.brandId) {
                      // Price is only important when cities are the same
                      return b.brandId - a.brandId;
                    }
                    return a.brandId > b.brandId ? 1 : -1;
                  });
                }
                if (brand?.competitors?.length) {
                  brandEdited.competitors = brand.competitors.sort((a: any, b: any) => {
                    if (a.brandId === b.brandId) {
                      // Price is only important when cities are the same
                      return b.brandId - a.brandId;
                    }
                    return a.brandId > b.brandId ? 1 : -1;
                  });
                }
                let validation;
                if (addCompetitors) {
                  validation = checkCompetitorsValues(brand);
                }
                if (!addBrandSearch) {
                  dispatch(
                    setIndustry({
                      id: "",
                      displayName: "",
                      slug: "",
                    })
                  );
                  navigate(`../brands/${suggestion.slug}`, { replace: true });
                  dispatch(setBrand(brandEdited));
                  localStorage.setItem("brandSlug", brandEdited.slug);
                } else if (
                  validation &&
                  validation[suggestion.name] &&
                  validation[suggestion.name].length > 0
                ) {
                  addBrandSearch(brandEdited);
                } else if (
                  validation &&
                  validation[suggestion.name] &&
                  validation[suggestion.name].length === 0
                ) {
                  addBrandSearch({ brandId: "no matchs", name: "no matchs" });
                }
              });
            }
          }}
          getSuggestionValue={(suggestion: any) => suggestion.name}
          renderSuggestion={renderSuggestion}
          inputProps={{
            placeholder,
            value: input,
            id,
            className: webflowStyle,
            onChange: (_, { newValue }) => setInput(newValue),
          }}
          highlightFirstSuggestion
        />
        {children}
      </div>
      {textHelper && (
        <div
          style={{
            position: "absolute",
            color: `${location.pathname !== "/search" && "#fff"}`,
          }}
        >
          {textHelper}
        </div>
      )}
      {fieldsCompetitorsEmpty && (
        <Alert
          style={{ marginTop: "15px" }}
          message="Not able to add competitor due to no matching industries, products or services or locations."
          type="error"
        />
      )}
    </div>
  );
};

Search.propTypes = {
  id: PropTypes.string,
  webflowStyle: PropTypes.string,
  placeholder: PropTypes.string,
};

Search.defaultProps = {
  id: null,
  webflowStyle: "search-input w-input",
  placeholder: "Enter brand name",
  searchValue: "",
  addBrandSearch: null,
  children: undefined,
  excludes: [],
  addManualCompetitor: null,
  addCompetitors: false,
  mainBrand: undefined,
  suggestionSelected: null,
};

export default Search;
