import React, { useRef } from "react";
import Turnstone from "turnstone";
import { useNavigate } from "react-router-dom";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";

import { setCompetitor } from "../../actions/actions";

export const SearchCompetitor: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const turnstoneRef: any = useRef();
  const brand = useSelector((state: RootStateOrAny) => state.brand);
  const brands = useSelector((state: RootStateOrAny) => state.industryBrands);

  const listbox = {
    name: "Brands",
    displayField: "name",
    searchType: "startswith",
    data: brands.filter((item: any) => item.name !== brand.name),
  };

  const Item = ({ item }: any) => {
    return (
      <div className="searchResult">
        <img width={35} src={item.logo} alt={item.name} />
        <p>{item.name}</p>
      </div>
    );
  };

  const onBrandSelect = (selectedBrand: any) => {
    if (selectedBrand) {
      dispatch(setCompetitor(selectedBrand));
      navigate(`../brands/${brand.slug}/compare/${selectedBrand.slug}`, { replace: true });
      turnstoneRef.current?.clear();
    }
  };

  return (
    <div className="brandSearch">
      <Turnstone
        ref={turnstoneRef}
        listbox={listbox}
        typeahead
        matchText
        Item={Item}
        noItemsMessage="We couldn't find any brand that matches your search"
        placeholder="Enter brand name..."
        styles={{
          input: "searchInput",
          highlightedItem: "searchHighlightedItem",
          listbox: "searchListbox",
          noItems: "searchNoItems",
        }}
        onSelect={onBrandSelect}
      />
    </div>
  );
};
