import React from "react";
import { NavLink } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";

import { ReactComponent as BrandIcon } from "../../assets/images/icons/brand.svg";
import { ReactComponent as DigitalIcon } from "../../assets/images/icons/digital.svg";
import { ReactComponent as BusinessIcon } from "../../assets/images/icons/business.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/icons/settings.svg";
import { ReactComponent as ElephantIcon } from "../../assets/images/icons/elephant.svg";
import { ReactComponent as TaxonomyIcon } from "../../assets/images/icons/Taxonomiesicon.svg";
import { ReactComponent as SGLogo } from "../../webflow/images/SG-Logo.svg";

export const Navigation: React.FC = () => {
  const brand = useSelector((state: RootStateOrAny) => state.brand || {});
  const user = useSelector((state: RootStateOrAny) => state.user);
  const industry = useSelector((state: RootStateOrAny) => state.industry);
  const isUserAdmin = user.roles.indexOf("ADMIN") > -1;

  return (
    <div
      data-collapse="medium"
      data-animation="over-left"
      data-duration="400"
      role="banner"
      className="dashboard-side-menu w-nav"
    >
      <nav role="navigation" className="nav-menu w-nav-menu">
        <div className="menu-wrap">
          <div>
            <h1 className="main-heading">
              <NavLink to="/search" className="brand-logo w-inline-block dsk-only">
                <SGLogo />
              </NavLink>
              <span className="brand-index">Brand Index</span>
            </h1>
            {industry.slug && (
              <div style={!industry ? { cursor: "not-allowed" } : undefined}>
                <NavLink
                  to={industry ? `/industries/${industry.slug}` : "/search"}
                  className={({ isActive }) =>
                    `side-menu_link w-inline-block${isActive ? " w--current" : ""}`
                  }
                  style={!industry.slug ? { pointerEvents: "none" } : undefined}
                >
                  <div className="side-menu-link_icon w-embed">
                    <DigitalIcon />
                  </div>
                  <div>Sector index</div>
                </NavLink>
              </div>
            )}
            {brand.slug && (
              <>
                <NavLink
                  to={`/brands/${brand.slug}/`}
                  className={({ isActive }) =>
                    `side-menu_link w-inline-block${isActive ? " w--current" : ""}`
                  }
                  style={!brand.slug ? { cursor: "not-allowed" } : undefined}
                >
                  <div className="side-menu-link_icon w-embed">
                    <BrandIcon />
                  </div>
                  <div>Brand analytics</div>
                </NavLink>
                <NavLink
                  to={`/brands/${brand.slug}/digital`}
                  className={({ isActive }) =>
                    `side-menu_link w-inline-block${isActive ? " w--current" : ""}`
                  }
                  style={!brand.slug ? { cursor: "not-allowed" } : undefined}
                >
                  <div className="side-menu-link_icon w-embed">
                    <DigitalIcon />
                  </div>
                  <div>Competitor scorecard</div>
                </NavLink>
              </>
            )}
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              className="side-menu_link w-inline-block"
              style={{ cursor: "not-allowed" }}
            >
              <div className="side-menu-link_icon w-embed">
                <BusinessIcon />
              </div>
              <div>Business</div>
            </a>
            {isUserAdmin && (
              <>
                <NavLink
                  to="/Taxonomies"
                  className={({ isActive }) =>
                    `side-menu_link w-inline-block${isActive ? " w--current" : ""}`
                  }
                >
                  <div className="side-menu-link_icon w-embed">
                    <TaxonomyIcon />
                  </div>
                  <div>Taxonomies</div>
                </NavLink>
                <NavLink
                  to="/settings"
                  className={({ isActive }) =>
                    `side-menu_link w-inline-block${isActive ? " w--current" : ""}`
                  }
                >
                  <div className="side-menu-link_icon w-embed">
                    <SettingsIcon />
                  </div>
                  <div>Settings</div>
                </NavLink>
                <NavLink
                  to="/brands/review"
                  className={({ isActive }) =>
                    `side-menu_link w-inline-block${isActive ? " w--current" : ""}`
                  }
                >
                  <div className="side-menu-link_icon w-embed">
                    <SettingsIcon />
                  </div>
                  <div>Review brands</div>
                </NavLink>
                <NavLink
                  to="/semrush"
                  className={({ isActive }) =>
                    `side-menu_link w-inline-block${isActive ? " w--current" : ""}`
                  }
                >
                  <div className="side-menu-link_icon w-embed">
                    <SettingsIcon />
                  </div>
                  <div>Semrush</div>
                </NavLink>
              </>
            )}
          </div>
          <NavLink to="/ContactUs" className="btn nav w-inline-block">
            <div className="elephant-icon w-embed">
              <ElephantIcon />
            </div>
            <div>Contact us</div>
          </NavLink>
        </div>
      </nav>
      <div className="menu-button w-nav-button">
        <div className="w-icon-nav-menu" />
      </div>
    </div>
  );
};
