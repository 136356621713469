import React from "react";
import { Form, Input, Button } from "antd";

export const SignUpForm: React.FC = () => {
  return (
    <Form id="email-form" name="email-form" className="form">
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: "Please enter an Name",
          },
        ]}
        className="no-margin-bottom"
      >
        <Input className="form_field w-input" placeholder="Name" />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please enter your email address",
          },
        ]}
        className="no-margin-bottom"
      >
        <Input className="form_field w-input" placeholder="Email Address" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please enter your password",
          },
        ]}
        className="no-margin-bottom"
      >
        <Input.Password
          visibilityToggle={false}
          className="form_field w-input"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please re-enter your password",
          },
        ]}
        className="no-margin-bottom"
      >
        <Input.Password
          visibilityToggle={false}
          className="form_field w-input"
          placeholder="Retpe Password"
        />
      </Form.Item>
      <Button className="btn orange form-btn w-button no-margin-top" htmlType="submit">
        Signup
      </Button>
      <div className="sign-up-text left-aligned">
        By clicking the &#x27;Sign up&#x27; button, you agree to our&nbsp;
        <a href="#" className="sign-up-link">
          Terms of Service
        </a>
        &nbsp;and&nbsp;
        <a href="#" className="sign-up-link">
          Privacy Policy
        </a>
      </div>
    </Form>
  );
};
