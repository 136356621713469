import React from "react";
import { Popconfirm, message } from "antd";
import { NavLink } from "react-router-dom";

import { refreshAllKeywords } from "../../API/api";

const RefreshAllKeywords: React.FC = () => {
  const onRefresh = () => {
    refreshAllKeywords().then(() => {
      message.success("Brand keywords are being refreshed");
    });
  };

  return (
    <div className="content">
      <NavLink to="/v2/admin">&lt; Back to admin page</NavLink>
      <h2 style={{ marginTop: 20 }}>Run keyword process</h2>
      <p style={{ color: "red" }}>
        This will refresh all brands keywords with the existing documents in the database and will
        take a while, please proceed with caution.
      </p>
      <Popconfirm
        title="Are you sure you want to run the keyword process?"
        onConfirm={() => onRefresh()}
        okText="Yes"
        cancelText="No"
      >
        <button style={{ marginTop: 20, cursor: "pointer" }} type="button">
          Refresh all keywords
        </button>
      </Popconfirm>
    </div>
  );
};

export default RefreshAllKeywords;
