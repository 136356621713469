import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Input, Button, message } from "antd";

import { authLogin } from "../API/api";
import { setUser } from "../actions/actions";

export const Register: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = (values: any) => {
    authLogin(values)
      .then((result: any) => {
        if (result.error) {
          return setErrorMessage(result.error);
        }
        return result;
      })
      .then((json: any) => {
        if (typeof json === "object") {
          setErrorMessage("");
          localStorage.setItem("user", JSON.stringify(json));
          dispatch(setUser(json));
          navigate("/v2", { replace: true });
        }
      })
      .catch((error) => message.error(error, 2));
  };

  return (
    <div className="content">
      <div className="formWrapper">
        <Form name="login-form" className="form" onFinish={onFinish}>
          <span className="formLabel">Username</span>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please enter your username",
              },
            ]}
            className="no-margin-bottom"
          >
            <Input style={{ width: 300 }} />
          </Form.Item>
          <span className="formLabel">Password</span>
          <Form.Item
            name="password"
            style={{ marginBottom: 20 }}
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
            ]}
          >
            <Input.Password visibilityToggle={false} style={{ width: 300 }} />
          </Form.Item>
          {errorMessage && errorMessage}
          <Button type="primary" htmlType="submit">
            Create account
          </Button>
        </Form>
      </div>
    </div>
  );
};
