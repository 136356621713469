import React, { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Skeleton } from "antd";
import parse from "html-react-parser";

import { SearchBar } from "../../components/SearchBar/SearchBar";
import Navbar from "../../components/Navbar/Navbar";
import { Navigation } from "../../components/Navigation/Navigation";
import { ProgressBar } from "../../components/v1/ProgressBar/ProgressBar";
import { getAnalytics } from "../../API/api";
import { getImageURL, getSocialFollowers, setOptions, toggleDropdown } from "../../utils/utils";
import { ExportBrandDropdown } from "../../components/pages/BrandAnalysis/ExportBrandDropdown";
import { BrandAnalytics } from "../../components/pages/BrandAnalysis/BrandAnalytics";
import { BrandESGAnalytics } from "../../components/pages/BrandAnalysis/BrandESGAnalytics";
// import { BrandCompetitors } from "../../components/pages/BrandAnalysis/BrandCompetitors";
import { getValue } from "./BrandAnalysis.utils";

interface Entry {
  id: string;
  value: any;
  displayValue: string;
  title: string;
  name: string;
  unit: string;
  tooltip?: string;
}

const BrandAnalysis: React.FC = () => {
  const brand = useSelector((state: RootStateOrAny) => state.brand);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const [productsAndServices, setProductsAndServices] = useState([]) as any;
  const [productsAndServicesTitle, setProductsAndServicesTitle] = useState("Products and services");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedProductService, setSelectedProductService] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  // const [typeOfCompetitor, setTypeOfCompetitor] = useState("");
  const [showAnalyticsButton, setShowAnalyticsButton] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const [activeTab, setActiveTab] = useState("general");

  const filters = {
    selectedIndustry,
    selectedProductService,
    selectedLocation,
    // typeOfCompetitor,
    typeOfCompetitor: "",
  };

  const getAnalyticsData = useCallback(() => {
    const analyticsData: any = [];
    let analyticsActual: any[] =
      brand.analytics && brand.analytics[0] ? brand.analytics[0][activeTab] : [];

    if (analyticsActual) {
      if (activeTab === "general") {
        const socialFollowers = {
          id: "Socialfollowers",
          name: "Social followers",
          value: getSocialFollowers(brand.analytics[0]),
          unit: "",
        };

        analyticsActual = [...analyticsActual, ...[socialFollowers]];
      }

      analyticsActual.forEach((entry: Entry, index) => {
        if (entry.id !== "webScore") {
          let data;

          const isArray = Array.isArray(entry.value);

          if (!isArray) {
            data = {
              id: index,
              name: entry.name,
              actual: `${entry.displayValue} ${entry.unit}`,
            };

            analyticsData.push(data);
          } else if (isArray) {
            if (activeTab === "social") {
              entry.value.forEach((stat: any) => {
                data = {
                  id: index,
                  name: `${entry.name} ${Object.keys(stat)}`,
                  actual: `${getValue(stat, entry)} ${entry.unit ? entry.unit : ""}`,
                  tooltip: entry.tooltip,
                };

                analyticsData.push(data);
              });
            } else {
              let actual = `${entry.value[0]}`;

              if (entry.value.length > 1) {
                actual = `<ol><li>${entry.value.join("</li><li>")}</li></ol>`;
              }

              data = {
                id: index,
                name: `${entry.name}`,
                actual,
                tooltip: entry.tooltip,
              };

              analyticsData.push(data);
            }
          }
        }
      });
      let comingSoon: string[] = [""];
      const basicTrafficAPI: string[] = [
        "Bounce rate",
        "Monthly web traffic",
        "Total pages per session",
        "Average time per page",
      ];
      if (activeTab === "general") {
        comingSoon = ["Monthly web traffic"];
      } else if (activeTab === "website") {
        comingSoon = [
          "Monthly web traffic",
          "Bounce rate",
          "Total unique visitors",
          "Total pages per session",
          "Average time per page",
          "Average pages per session",
          "Top visited pages",
        ];
      } else if (activeTab === "organic") {
        comingSoon = [];
      } else if (activeTab === "paid") {
        comingSoon = ["Ranked paid keywords", "Display adverts"];
      } else if (activeTab === "social") {
        comingSoon = [
          "SM channels",
          "Total social following",
          "Followers per channel",
          "Average engagement per channel",
        ];
      }
      if (comingSoon.length) {
        comingSoon.forEach((field: string, idx: number) => {
          const data = {
            id: `${Math.random() + idx}`,
            name: field,
            actual: basicTrafficAPI.includes(field) ? "Basic Traffic API" : `Coming soon...`,
            actualValue: basicTrafficAPI.includes(field) ? "Basic Traffic API" : `Coming soon...`,
            last: basicTrafficAPI.includes(field) ? "Basic Traffic API" : `Coming soon...`,
            difference: null,
          };
          analyticsData.push(data);
        });
      }
    }

    return analyticsData;
  }, [brand, activeTab]);

  useEffect(() => {
    if (brand.products && brand.products.length && brand.services && brand.services.length) {
      setProductsAndServices({
        products: brand.products,
        services: brand.services,
      });
      setProductsAndServicesTitle("Products and services");
    } else if (
      brand.products &&
      brand.products.length &&
      (!brand.services || !brand.services.length)
    ) {
      setProductsAndServices({
        products: brand.products,
        services: [],
      });
      setProductsAndServicesTitle("Products");
    } else if (
      (!brand.products || !brand.products.length) &&
      brand.services &&
      brand.services.length
    ) {
      setProductsAndServices({
        products: [],
        services: brand.services,
      });
      setProductsAndServicesTitle("Services");
    } else {
      setProductsAndServices({
        products: [],
        services: [],
      });
      setProductsAndServicesTitle("Products and services");
    }
  }, [brand]);

  useEffect(() => {
    if (brand.id) {
      const analytics = getAnalyticsData();
      setBrandData(analytics);
    }
  }, [brand, activeTab]);

  useEffect(() => {
    if (
      (brand?.analytics === null || brand?.analytics?.length === 0) &&
      brand?.metadata?.step === "CREATED"
    ) {
      setShowAnalyticsButton(true);
    }
  });

  const columns = [
    {
      name: "",
      selector: "name",
      center: true,
      sortable: true,
    },
    {
      name: "",
      selector: "actual",
      sortable: true,
      center: true,
      cell: (row: any) => <div>{parse(row.actual)}</div>,
    },
  ];

  const columnsNoLast = columns.filter((col: any) => col.selector !== "last");
  const brandSelectedColumns =
    brand.analytics && brand.analytics.length > 1 ? columns : columnsNoLast;

  return (
    <React.Fragment>
      <Navbar />
      <div className="section analysis">
        <Navigation />
        <main className="main-panel brandanalysis-page">
          <SearchBar />
          <div className="card mb-cols">
            {brand.name ? (
              <div className="brand-name-wrapper">
                <h1 className="brand-name brand-title btm-0">Brand</h1>
                <h1 className="brand-name orange btm-0">{brand.name}</h1>
              </div>
            ) : (
              <Skeleton.Input style={{ width: 200 }} active size="default" />
            )}
            <div className="category-dropdown-wrap">
              <div data-hover="" data-delay="0" className="category-dropdown rt-mgn w-dropdown">
                <div
                  className="category-dropdown_toggle w-dropdown-toggle"
                  onClick={(e) => toggleDropdown(e.target)}
                  onKeyDown={(e) => toggleDropdown(e.target)}
                  tabIndex={0}
                  role="button"
                >
                  <div className="w-icon-dropdown-toggle" />
                  <a
                    style={{ textDecoration: "none", color: "#222222" }}
                    href="#"
                    onClick={() => setSelectedIndustry("")}
                    title={selectedIndustry !== "" ? selectedIndustry : "Industries"}
                  >
                    {selectedIndustry !== "" ? selectedIndustry : "Industries"}
                  </a>
                </div>
                <nav className="category-dropdown_list w-dropdown-list">
                  {brand.industries ? (
                    brand.industries.map((industry: any) => (
                      <a
                        href="#"
                        onClick={() => setSelectedIndustry(industry.displayName)}
                        key={industry.displayName}
                        className="category-dropdown_link w-dropdown-link"
                      >
                        {industry.displayName}
                      </a>
                    ))
                  ) : (
                    <a href="#" key="none" className="category-dropdown_link w-dropdown-link">
                      No industries
                    </a>
                  )}
                </nav>
              </div>
              <div data-hover="" data-delay="0" className="category-dropdown rt-mgn w-dropdown">
                <div
                  className="category-dropdown_toggle w-dropdown-toggle"
                  onClick={(e) => toggleDropdown(e.target)}
                  onKeyDown={(e) => toggleDropdown(e.target)}
                  tabIndex={0}
                  role="button"
                >
                  <div className="w-icon-dropdown-toggle" />
                  <a
                    style={{ textDecoration: "none", color: "#222222" }}
                    href="#"
                    onClick={() => setSelectedProductService("")}
                    title={
                      selectedProductService !== ""
                        ? selectedProductService
                        : productsAndServicesTitle
                    }
                  >
                    {selectedProductService !== ""
                      ? selectedProductService
                      : productsAndServicesTitle}
                  </a>
                </div>
                <nav className="category-dropdown_list w-dropdown-list">
                  {productsAndServices?.products?.length > 0 && (
                    <>
                      <div className="dropdown-category">Products</div>
                      {setOptions(productsAndServices.products, setSelectedProductService)}
                    </>
                  )}
                  {productsAndServices?.services?.length > 0 && (
                    <>
                      <div className="dropdown-category">Services</div>
                      {setOptions(productsAndServices.services, setSelectedProductService)}
                    </>
                  )}
                  {productsAndServices.services &&
                    !productsAndServices.services.length &&
                    productsAndServices.products &&
                    !productsAndServices.products.length && (
                      <a href="#" key="none" className="category-dropdown_link w-dropdown-link">
                        No products or services
                      </a>
                    )}
                </nav>
              </div>
              <div data-hover="" data-delay="0" className="category-dropdown w-dropdown">
                <div
                  className="category-dropdown_toggle w-dropdown-toggle"
                  onClick={(e) => toggleDropdown(e.target)}
                  onKeyDown={(e) => toggleDropdown(e.target)}
                  tabIndex={0}
                  role="button"
                >
                  <div className="w-icon-dropdown-toggle" />
                  <a
                    style={{ textDecoration: "none", color: "#222222" }}
                    href="#"
                    onClick={() => setSelectedLocation("")}
                    title={selectedLocation !== "" ? selectedLocation : "Locations"}
                  >
                    {selectedLocation !== "" ? selectedLocation : "Locations"}
                  </a>
                </div>
                <nav className="category-dropdown_list w-dropdown-list">
                  {brand.locations ? (
                    brand.locations.map((brandLocation: any) => (
                      <a
                        href="#"
                        onClick={() => setSelectedLocation(brandLocation.name)}
                        key={brandLocation.name}
                        className="category-dropdown_link w-dropdown-link"
                      >
                        {brandLocation.name}
                      </a>
                    ))
                  ) : (
                    <a href="#" key="none" className="category-dropdown_link w-dropdown-link">
                      No locations
                    </a>
                  )}
                </nav>
              </div>
            </div>
          </div>
          <section className="card mb-cols">
            {brand.image ? (
              <div className="screenshot-block">
                <div className="laptop">
                  <div className="laptop_screen">
                    <img
                      src={getImageURL(brand.image, undefined)}
                      loading="lazy"
                      alt={brand.name}
                      className="screenshot"
                      sizes="(max-width: 479px) 65vw, (max-width: 767px) 71vw, (max-width: 991px) 68vw, 30vw"
                    />
                  </div>
                  <div className="laptop-keyboard">
                    <div className="laptop-keyboard_inner" />
                  </div>
                  <div className="laptop-suface" />
                </div>
              </div>
            ) : (
              <Skeleton.Image style={{ margin: "86px 200px" }} />
            )}
            <div className="brand-card_info">
              <div data-hover="" data-delay="0" className="category-dropdown w-dropdown">
                <ExportBrandDropdown brand={brand} filters={filters} />
              </div>

              {brand?.shortDescription !== "" && brand.name !== "" && (
                <>
                  <h2 className="brand-name mob-hide" style={{ alignSelf: "flex-start" }}>
                    About
                  </h2>
                  <p className="info-block_desc">{brand.shortDescription}</p>
                </>
              )}

              <div>
                <h2 className="brand-name mob-hide">Website</h2>
                {brand.url ? (
                  <a className="orange-text" target="_blank" rel="noreferrer" href={`${brand.url}`}>
                    <span>{brand.url}</span>
                  </a>
                ) : (
                  <Skeleton.Input style={{ width: 100, height: 15 }} active size="small" />
                )}
              </div>

              <div className="brand-footer">
                <div className="website-score">
                  {brand.analytics?.length > 0 &&
                    brand?.analytics[0]?.general?.find((data: any) => data.id === "webScore") && (
                      <ProgressBar
                        percentage={
                          brand.analytics[0].general.find((data: any) => data.id === "webScore")
                            .value
                        }
                      />
                    )}
                </div>
              </div>
            </div>
          </section>

          {brand.name !== "" && user.roles.indexOf("ADMIN") > -1 && (
            <section className="card">
              {showAnalyticsButton && (
                <a
                  href="#"
                  onClick={() => {
                    setShowAnalyticsButton(false);
                    getAnalytics(brand.id);
                  }}
                  className="btn orange mgn-rt-6px w-button"
                >
                  Get analytics
                </a>
              )}
              {brand.metadata?.step === "REFRESHING" && (
                <a
                  href="#"
                  style={{ pointerEvents: "none", background: "grey" }}
                  className="btn orange mgn-rt-6px w-button"
                >
                  Processing analytics...
                </a>
              )}

              <Link to={`/brands/${brand.slug}/edit`} className="btn orange w-button">
                Edit brand
              </Link>
            </section>
          )}

          <div className="separator" />

          <BrandAnalytics
            brand={brand}
            brandData={brandData}
            brandSelectedColumns={brandSelectedColumns}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          <div className="separator" />

          <BrandESGAnalytics />

          {/* <BrandCompetitors
            brand={brand}
            filters={filters}
            setTypeOfCompetitor={setTypeOfCompetitor}
          />
              */}
        </main>
      </div>
    </React.Fragment>
  );
};

export default BrandAnalysis;
