import React from "react";
import { NavLink } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";

import { Brand } from "../components/Brand/Brand";
import { SelectCompetitor } from "../components/SelectCompetitor/SelectCompetitor";
import { SearchCompetitor } from "../components/Search/SearchCompetitor";

export const Brands: React.FC = () => {
  const brand = useSelector((state: RootStateOrAny) => state.brand);
  const competitor = useSelector((state: RootStateOrAny) => state.competitor);

  return (
    <div className="content brands">
      <div className="brandsHeader">
        <NavLink to="/v2" className="backlink">
          &lt; Back to full asset manager list
        </NavLink>

        {competitor.name !== "" && (
          <div className="changeCompetitor">
            <p className="text">Change competitor:</p>
            <SearchCompetitor />
          </div>
        )}
      </div>

      <div className="brandsWrapper">
        <Brand brand={brand} />
        {competitor.name !== "" ? <Brand brand={competitor} /> : <SelectCompetitor />}
      </div>
    </div>
  );
};
