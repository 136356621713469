import React, { useState } from "react";
import { Bar } from "@ant-design/plots";

import { testESGdata } from "./testData";

interface ESGProps {
  esgValues: any;
}

export const ESGAnalytics: React.FC<ESGProps> = ({ esgValues }: ESGProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const getChartData = () => {
    let data: any = [];

    if (esgValues) {
      const entries = Object.values(esgValues.value);

      entries.forEach((entry: any) => {
        const themes = Object.entries(entry);
        const category: any = [];

        themes.forEach((theme) => {
          category.push({
            type: theme[0],
            value: theme[1],
          });
        });

        data.push(category);
      });
    } else {
      data = [
        testESGdata.contentTrendsData,
        testESGdata.assetClassData,
        testESGdata.esgInvestingData,
        testESGdata.futureTrends,
      ];
    }

    return data;
  };

  const Chart = () => {
    const chartData = getChartData();

    const config = {
      data: chartData[activeTab],
      autoFit: true,
      xField: "value",
      yField: "type",
      seriesField: "type",
      legend: false,
    };

    // eslint-disable-next-line
    return <Bar {...config} />;
  };

  return (
    <>
      <div style={{ display: "flex", marginBottom: 10 }}>
        <div className="metric">
          <span className="title">Total ESG Keywords</span>
          <span className="value">XXX</span>
        </div>
        <div className="metric">
          <span className="title">ESG Social Engagement</span>
          <span className="value">XXX</span>
        </div>
        <div className="metric">
          <span className="title">ESG Ad Performance</span>
          <span className="value">XXX</span>
        </div>
        <div className="metric">
          <span className="title">Top ESG Content</span>
          <span className="value">XXX</span>
        </div>
      </div>
      <div className="tabs">
        {!esgValues && (
          <>
            <button
              className={activeTab === 0 ? "tabButton active" : "tabButton"}
              type="button"
              onClick={() => setActiveTab(0)}
            >
              Content trends
            </button>
            <button
              className={activeTab === 1 ? "tabButton active" : "tabButton"}
              type="button"
              onClick={() => setActiveTab(1)}
            >
              Asset class
            </button>
            <button
              className={activeTab === 2 ? "tabButton active" : "tabButton"}
              type="button"
              onClick={() => setActiveTab(2)}
            >
              ESG Investing
            </button>
            <button
              className={activeTab === 3 ? "tabButton active" : "tabButton"}
              type="button"
              onClick={() => setActiveTab(3)}
            >
              Products & Services
            </button>
          </>
        )}
        {esgValues &&
          Object.keys(esgValues.value).map((item, index) => (
            <button
              className={activeTab === index ? "tabButton active" : "tabButton"}
              type="button"
              onClick={() => setActiveTab(index)}
            >
              {item}
            </button>
          ))}
      </div>
      <div className="esgContent">
        <Chart />
      </div>
    </>
  );
};
