import React, { useEffect, useState } from "react";
import { AutoComplete, Input } from "antd";
import type { SelectProps } from "antd/es/select";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getIndustries } from "../../../API/api";
import { setIndustry } from "../../../actions/actions";
import { ReactComponent as SearchIcon } from "../../../webflow/images/search-icon.svg";

const SearchTaxonomy: React.FC = () => {
  const navigate = useNavigate();
  const [industriesList, setIndustriesList] = useState<SelectProps<object>["options"]>([]);
  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();

  const getIndustriesTaxonomy = () => {
    let list: any = [];

    getIndustries().then((response) => {
      if (!response) {
        return;
      }

      if (response.content.length > 0) {
        response.content.forEach((industry: any) => {
          if (industry.sector.industryGroups.length === 0) {
            list.push({
              key: industry.sector.id,
              title: industry.sector.displayName,
              parent: "",
              slug: industry.sector.slug,
            });
          }

          if (industry.sector.industryGroups.length > 0) {
            industry.sector.industryGroups.forEach((group: any) => {
              if (group.industries.length === 0) {
                list.push({
                  key: group.id,
                  title: group.displayName,
                  parent: industry.sector.displayName,
                  slug: group.slug,
                });
              }

              if (group.industries.length > 0) {
                group.industries.forEach((industries: any) => {
                  if (industries.subIndustries.length === 0) {
                    list.push({
                      key: industries.id,
                      title: industries.displayName,
                      parent: industry.sector.displayName,
                      slug: industries.slug,
                    });
                  }

                  if (industries.subIndustries.length > 0) {
                    industries.subIndustries.forEach((subindustry: any) => {
                      list.push({
                        key: subindustry.id,
                        title: subindustry.displayName,
                        parent: industry.sector.displayName,
                        slug: subindustry.slug,
                      });
                    });
                  }
                });
              }
            });
          }
        });
      }

      list = list.filter(
        (value: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.key === value.key && t.title === value.title)
      );
    });

    setIndustriesList(list);
  };

  const searchResult = (query: string) => {
    const results = industriesList?.filter((industry: any) =>
      industry.title.toLowerCase().includes(query.trim().toLowerCase())
    );

    return results?.map((_) => {
      const index = _.title.toLowerCase().indexOf(query.toLowerCase());
      const beforeStr = _.title.substring(0, index);
      const afterStr = _.title.slice(index + query.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span className="site-tree-search-value">
              {_.title.substring(index, index + query.length)}
            </span>
            {afterStr}
          </span>
        ) : (
          <span>{_.title}</span>
        );

      return {
        value: _.slug,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{title}</span>
            <span style={{ color: "#000" }}>{_.parent}</span>
          </div>
        ),
      };
    });
  };

  const handleSearch = (value: string) => {
    setInputValue(value);
    setOptions(value ? searchResult(value) : []);
  };

  const onSelect = (slug: string) => {
    const selectedIndustry: any = industriesList?.filter((industry: any) => industry.slug === slug);
    const industry = { id: selectedIndustry[0].key, displayName: selectedIndustry[0].title, slug };

    localStorage.setItem("industry", JSON.stringify(industry));
    dispatch(setIndustry(industry));
    setInputValue("");

    navigate(`../industries/${slug}`, { replace: true });
  };

  useEffect(() => {
    getIndustriesTaxonomy();
  }, []);

  return (
    <div style={{ position: "relative", flex: 1, backgroundColor: "#fff" }}>
      <SearchIcon className="search-industry-icon" />
      <AutoComplete
        className="search-industry"
        dropdownMatchSelectWidth={252}
        options={options}
        onSelect={onSelect}
        onSearch={handleSearch}
        value={inputValue}
      >
        <Input size="large" placeholder="Search by industry" />
      </AutoComplete>
    </div>
  );
};

export default SearchTaxonomy;
